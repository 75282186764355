import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { modalStyle } from "./BasicModalStyles";
import { BasicModalProps } from "./IBasicModal";

export default function BasicModal({
  children,
  open,
  handleClose,
}: BasicModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...modalStyle, bgcolor: "background.paper" }}>{children}</Box>
    </Modal>
  );
}
