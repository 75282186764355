import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { ReviewStatus } from "src/constants/reviewsConstants";
import { yearMonthDate } from "src/v2/utils/parsers";
import { Tooltip } from "src/v2/components/atoms";
import { PEOPLE_BFF_PERSON } from "../config/constants";
import {
  REVIEW_DASHBOARD_DONE,
  REVIEW_DASHBOARD_IN_PROGRESS,
  REVIEW_DASHBOARD_TO_DO,
} from "../constants/colors";

export const reviewsDashboardTable = () => {
  const columns: GridColDef[] = [
    {
      field: "ioetId",
      headerName: "ioet Id",
      type: "string",
      sortable: true,
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      sortable: true,
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Tooltip title='Open career tab' arrow>
            <Link
              to={`${PEOPLE_BFF_PERSON + params.row.ioetId}/1`}
              rel='noopener noreferrer'
            >
              {params.row.name}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      type: "string",
      sortable: true,
      flex: 0.8,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      sortable: true,
      flex: 0.7,
      renderCell: (params) => {
        const statusColor: Record<ReviewStatus, string> = {
          [ReviewStatus.toDo]: REVIEW_DASHBOARD_TO_DO,
          [ReviewStatus.inProgress]: REVIEW_DASHBOARD_IN_PROGRESS,
          [ReviewStatus.done]: REVIEW_DASHBOARD_DONE,
        };
        return (
          <Stack
            display='flex'
            alignItems='center'
            justifyContent='center'
            spacing={2}
            direction='row'
            padding={2}
            key={params.row.id}
          >
            <div
              className='h-2 w-2 rounded-full'
              style={{
                backgroundColor: statusColor[params.row.status as ReviewStatus],
              }}
            />
            <p>{params.row.status}</p>
          </Stack>
        );
      },
    },
    {
      field: "reviewDate",
      headerName: "Review Date",
      sortable: true,
      type: "string",
      flex: 0.4,
      renderCell: (params) => {
        params.row.reviewDate = yearMonthDate(params.row.reviewDate);
        return params.row.reviewDate;
      },
    },
  ];
  return columns;
};
