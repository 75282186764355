import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { SubmitHandler } from "react-hook-form";
import { getFormattedDate } from "src/v2/utils/parsers";
import {
  useMutateNote,
  useMutateReview,
  useMutateWorkInformation,
} from "src/v2/hooks/useMutations";
import { INote, IReview } from "src/v2/models";
import { IWorkInformationQuery } from "../models/workInformation.model";

export interface IOnSubmitProps {
  onSuccess: () => void;
  onError: (error: Error) => void;
  onSettled: () => void;
  personId?: string;
  newReview?: boolean;
  newNotes?: boolean;
}

export const useCreateOnSubmitNotes = ({
  onSuccess,
  onError,
  onSettled,
  personId,
  newNotes,
}: IOnSubmitProps): {
  mutation: SubmitHandler<INote>;
  isPending: boolean;
} => {
  const queryClient = useQueryClient();
  const { isPending, mutate } = useMutateNote();

  const mutation = async (data: INote) => {
    const noteData: INote = {
      ...data,
      date: dayjs(data.date).format("YYYY-MM-DD"),
    };
    mutate(
      { note: noteData, personId: personId!, newNote: !!newNotes },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["getNotesByID", personId ?? ""],
          });

          onSuccess();
        },
        onError,
        onSettled,
      }
    );
  };
  return { mutation, isPending };
};

const todayDate: string = getFormattedDate();

export const useCreateOnSubmitReviews = ({
  onSuccess,
  onError,
  onSettled,
  personId,
  newReview,
}: IOnSubmitProps): {
  mutation: SubmitHandler<IReview>;
  isPending: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutateReview();

  const mutation = async (data: IReview) => {
    mutate(
      { review: data, personId: personId!, newReview: !!newReview },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["listAllReviewsByPersonId", personId ?? ""],
          });
          queryClient.invalidateQueries({
            queryKey: ["fetchNextReviewDate", personId ?? ""],
          });
          queryClient.invalidateQueries({
            queryKey: ["getReviewsDashboard", todayDate],
          });
          onSuccess();
        },
        onError,
        onSettled,
      }
    );
  };
  return { mutation, isPending };
};

export interface IOnSubmitWorkInformation {
  onSuccess: () => void;
  onError: () => void;
  onSettled: (data: any, error: any, variables: any, context?: any) => void;
}

export const useCreateOnSubmitWorkInformation = ({
  onSuccess,
  onError,
  onSettled,
}: IOnSubmitWorkInformation): {
  mutation: SubmitHandler<IWorkInformationQuery>;
  isPending: boolean;
} => {
  const { mutate, isPending } = useMutateWorkInformation();

  const mutation = async (data: IWorkInformationQuery) => {
    mutate({ workInformation: data }, { onSuccess, onError, onSettled });
  };
  return { mutation, isPending };
};
