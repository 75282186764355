import Typography from "src/v2/components/atoms/Typography/Typography";
import Icon from "src/v2/components/atoms/Icon/Icon";
import { IDataIcon } from "./types";

function DataIcon({ iconName, text, columnGap }: IDataIcon) {
  return (
    <div style={{ display: "flex", alignItems: "center", columnGap }}>
      {iconName && <Icon {...iconName} />}
      <Typography variant={text.variant} sx={text.sx}>
        {text.children}
      </Typography>
    </div>
  );
}

export default DataIcon;
