import { Box } from "@mui/system";
import dayjs from "dayjs";
import DatePicker from "src/v2/components/atoms/DatePicker/DatePicker";
import { TextField } from "src/v2/components/atoms/TextField";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";

export function EducationInformationSection(educationInformation: {
  educationInformation: Dict[];
}) {
  const { educationInformation: personEducationData } = educationInformation;
  const dataIconColumnGap = "1%";

  return (
    <Box
      data-testid='education-information-container'
      sx={{
        width: { lg: "750px", md: "100%" },
        marginBottom: "3%",
      }}
    >
      <div style={{ paddingTop: "2%", paddingBottom: "3%" }}>
        <DataIcon
          iconName={{ icon: "education" }}
          text={{
            children: "Education",
            variant: "h6",
            sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
          }}
          columnGap={dataIconColumnGap}
        />
      </div>
      {personEducationData?.map((item: Dict, index: number) => (
        <div
          key={`educationData-${index}`}
          data-testid='education-information-collegeDegree'
        >
          <div
            style={{ marginBottom: "2%" }}
            data-testid='education-information-college'
          >
            <TextField
              disabled
              id='collegeId'
              variant='outlined'
              label='College/University'
              value={item.institution.name ?? ""}
              size='small'
              sx={{ width: { md: "48%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
          <Box
            sx={{
              marginBottom: { sm: "1%" },
              display: { md: "flex", sm: "block" },
              columnGap: "4%",
            }}
            data-testid='education-information-degree'
          >
            <TextField
              disabled
              id='degreeId'
              variant='outlined'
              label='Degree'
              value={item.degree.name ?? ""}
              size='small'
              sx={{
                marginBottom: { md: "1%", sm: "2%" },
                width: { md: "50%" },
              }}
              onChange={() => {}}
              className='BambooClass'
            />
            <TextField
              disabled
              id='majorId'
              variant='outlined'
              label='Major/Specialization'
              value={item.specialization ?? ""}
              size='small'
              sx={{ marginBottom: "1%", width: { md: "50%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </Box>
          <div
            style={{ marginBottom: "2%" }}
            data-testid='education-information-gpa'
          >
            <TextField
              disabled
              id='gpaId'
              variant='outlined'
              label='GPA'
              value={item.gpa ?? ""}
              size='small'
              sx={{ width: { md: "25%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
          <Box
            sx={{
              width: { md: "80%" },
              marginBottom: { md: "4%", sm: "2%" },
              columnGap: "5%",
              display: { md: "flex", sm: "block" },
            }}
            data-testid='education-information-dates'
          >
            <DatePicker
              label='Start date'
              disabled
              value={dayjs(item.startDate)}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    "width": "100%",
                    "marginBottom": { md: "0", sm: "2%" },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  },
                },
              }}
            />
            <DatePicker
              label='End date'
              disabled
              value={dayjs(item.endDate)}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    "width": "100%",
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  },
                },
              }}
            />
          </Box>
        </div>
      ))}
    </Box>
  );
}
