import { Box, Card, CardContent, Dialog, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { Typography } from "src/v2/components/atoms/Typography";
import { Icon } from "src/v2/components/atoms/Icon";
import { Tooltip } from "src/v2/components/atoms/Tooltip";
import { Button } from "src/v2/components/atoms";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { usefetchPersonData } from "src/v2/hooks/useFetchs";
import { joinNameLastName } from "src/utils/stringJoins";
import { capitalizeToTitleCase } from "src/v2/utils/parsers";
import dayjs from "dayjs";
import { IConfirmSubmit } from "./types";

function ConfirmSubmit({
  open,
  handleClose,
  handleStay,
  currentJobTitle,
  newJobTitle,
  currentSalary,
  newSalary,
  effectiveDate,
  handleStatus,
  handleSubmit,
  isFormValid,
}: IConfirmSubmit) {
  const onSubmit = () => {
    if (isFormValid) {
      handleStatus(true);
    }
    handleClose();
    handleSubmit();
  };
  const { personId } = useParams();
  const isJobTitleChanged = currentJobTitle === newJobTitle;
  const isSalaryChanged = currentSalary === newSalary;
  const { data: personInformation, isPending } = usefetchPersonData(personId!);
  const name = !isPending
    ? joinNameLastName(personInformation.name, personInformation.lastName)
    : "Person Name";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "3%" } }}
    >
      <Card sx={{ width: 600 }}>
        <Box
          bgcolor={PRIMARY_COLOR}
          display='flex'
          paddingY={3}
          paddingX={1}
          justifyContent='flex-start'
          sx={{ width: "100%" }}
        >
          <Typography
            variant='h5'
            align='center'
            color='common.white'
            sx={{ fontWeight: "bold", marginLeft: 4 }}
            gutterBottom
          >
            Review: {capitalizeToTitleCase(name)}
          </Typography>
        </Box>
        <CardContent
          sx={{ flex: "flex", justifyContent: "center", paddingX: 5 }}
        >
          <Box>
            <Typography variant='h6' color='textPrimary' gutterBottom bold>
              The final results of the review are:
            </Typography>
            <Stack
              direction='row'
              mt={2}
              justifyContent='space-between'
              maxWidth='80%'
            >
              <Box>
                <Stack direction='column' spacing={2}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Icon icon='briefcase' />
                    <Typography variant='h6' bold>
                      Job Title
                    </Typography>
                    {isJobTitleChanged && (
                      <Tooltip title='No changes in job title' arrow>
                        <Icon icon='alert' size={20} />
                      </Tooltip>
                    )}
                  </Stack>
                  {isJobTitleChanged ? (
                    <Typography variant='body1'>
                      Current: {currentJobTitle}
                    </Typography>
                  ) : (
                    <Stack direction='row' spacing={1}>
                      <Stack direction='column'>
                        <Typography variant='body1'>From:</Typography>
                        <Typography variant='body1'>To:</Typography>
                      </Stack>
                      <Stack direction='column'>
                        <Typography variant='body1'>
                          {currentJobTitle}
                        </Typography>
                        <Typography variant='body1'>
                          <b>{newJobTitle}</b>
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Box>
              <Box>
                <Stack direction='column' spacing={2}>
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Icon icon='money' />
                    <Typography variant='h6' bold>
                      Salary
                    </Typography>
                    {isSalaryChanged && (
                      <Tooltip title='No changes in salary' arrow>
                        <Icon icon='alert' size={20} />
                      </Tooltip>
                    )}
                  </Stack>
                  {isSalaryChanged ? (
                    <Stack direction='row'>
                      <Typography variant='body1'>Current: </Typography>
                      <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Icon icon='dollarSign' size={15} /> {currentSalary}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction='row' spacing={2}>
                      <Stack direction='column'>
                        <Typography variant='body1'>From:</Typography>
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          To:
                        </Typography>
                      </Stack>
                      <Stack direction='column'>
                        <Typography
                          variant='body1'
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Icon icon='dollarSign' size={15} /> {currentSalary}
                        </Typography>
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Icon icon='dollarSign' size={15} />
                          <b>{newSalary}</b>
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box paddingTop={4}>
            <Stack direction='row' spacing={2}>
              <Typography variant='h6' bold>
                Effective Date:
              </Typography>
              <Box
                width='10rem'
                display='flex'
                justifyContent='center'
                sx={{ border: "2px solid grey" }}
              >
                <Typography variant='body1'>
                  {dayjs(effectiveDate).format("DD-MM-YYYY")}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box mt={3} textAlign='left'>
            <Typography variant='h6' color='textPrimary' gutterBottom bold>
              Are you sure you want to approve these changes?
            </Typography>
          </Box>
          <Box mt={3} display='flex' justifyContent='flex-end' gap={3}>
            <Button
              onClick={handleStay}
              text='Cancel'
              color='inherit'
              size='large'
              sx={{ width: "5rem" }}
            />
            <Button
              onClick={onSubmit}
              text='Approve'
              color='primary'
              variant='contained'
              sx={{ width: "5rem" }}
            />
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  );
}

export default ConfirmSubmit;
