import Icon from "src/v2/components/atoms/Icon/Icon";
import { Stack } from "@mui/system";
import { IIconGroup } from "./types";

function IconGroup({ icons }: IIconGroup) {
  return (
    <Stack direction='row' spacing={3} alignItems='center'>
      {icons.map((item, index) => (
        <a
          key={`key-${index}`}
          href={item.url}
          target='_blank'
          rel='noreferrer'
        >
          <Icon {...item} />
        </a>
      ))}
    </Stack>
  );
}

export default IconGroup;
