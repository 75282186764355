import { DynamicModel } from "src/constants/types";
import { Dict, INote, IReview } from "src/v2/models";
import { updateReadNotification } from "src/v2/services/notificationData.service";
import {
  createNote,
  fetchAllNotifications,
  fetchAllPeopleData,
  getNotesByPersonId,
  getPersonInformation,
  updateNote,
} from "src/v2/services/peopleData.service";
import {
  createReview,
  getNextReviewDateByPersonId,
  getReviewsByPersonId,
  getReviewsDashboard,
  updateNextReviewDateByPersonId,
  updateReview,
} from "src/v2/services/reviewData.service";
import { fetchUserData } from "src/v2/services/userData.service";
import { NoteRequest } from "../models/note.model";

export async function getNotesByID(personId: string) {
  const notes: INote[] = await getNotesByPersonId(personId);
  return notes;
}

export async function fetchPersonData(personId: string) {
  const response = await getPersonInformation(personId);
  const contractType = response?.contractType
    ? response.contractType.name
    : null;
  const office = response.office ? response.office.name : null;
  return { ...response, contractType, office };
}

export async function listAllPeople(): Promise<DynamicModel[]> {
  const response = await fetchAllPeopleData();
  const people: any = response.map((item: any) => {
    const { name, lastName, ioetId } = item.values;
    return {
      name,
      lastName,
      ioetId,
    };
  });
  return people;
}

export async function listAllReviewsByPersonId(
  personId: string
): Promise<IReview[]> {
  const response = await getReviewsByPersonId(personId);
  return response;
}

export async function mutateReview(
  data: IReview,
  personId: string,
  newReview: boolean
) {
  const response = newReview
    ? await createReview(data, personId)
    : await updateReview(data, personId);

  return response;
}

export async function mutateNote(
  data: INote,
  newNote: boolean,
  personId: string
) {
  const dataNote: NoteRequest = {
    person_id: personId,
    note: data,
  };

  const response = newNote
    ? await createNote(dataNote)
    : await updateNote(dataNote);

  return response;
}

export async function fetchNextReviewDate(personId: string) {
  const response = await getNextReviewDateByPersonId(personId);
  return response;
}

export async function listAllReviewsPerDateDashboard(date: string) {
  const response = await getReviewsDashboard(date);
  return response;
}

export async function getAllPeopleData(): Promise<DynamicModel[] | null> {
  const response = await fetchAllPeopleData();
  return response;
}

export async function getAllNotificationsByUser() {
  const response = await fetchAllNotifications();
  return response;
}

export async function getUserData() {
  const response = await fetchUserData();
  return response;
}

export async function updateReadNotificationData(notificationId: string) {
  const response = await updateReadNotification(notificationId);
  return response;
}

export async function updateNextReviewDateData(
  personId: string,
  nextReviewDate: Date
) {
  const response = await updateNextReviewDateByPersonId(
    personId,
    nextReviewDate
  );
  return response;
}
