import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import * as React from "react";
import Icon from "src/v2/components/atoms/Icon/Icon";
import { Typography } from "src/v2/components/atoms/Typography";
import { INavTabs } from "./types";

export default function NavTabs({
  labels,
  tabs,
  icons,
  initialTab,
}: Readonly<INavTabs>) {
  const [value, setValue] = React.useState(initialTab ?? "0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ background: "linear-gradient(to right, #FC5138, #FE395E)" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "80%",
            }}
          >
            <TabList
              onChange={handleChange}
              variant='fullWidth'
              sx={{
                height: "2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              {labels.map((item, index) => (
                <Tab
                  label={
                    <Typography variant='h5' bold>
                      {item}
                    </Typography>
                  }
                  value={index.toString()}
                  key={`tab-${index}`}
                  icon={
                    index.toString() === value ? (
                      <Icon icon={icons![index].icon} />
                    ) : (
                      <div />
                    )
                  }
                  iconPosition='start'
                  style={{
                    display: "flex",
                    columnGap: "5%",
                    background: index.toString() === value ? "white" : "none",
                    color: index.toString() === value ? "#FF5E0A" : "white",
                    height: "100%",
                    textTransform: "none",
                  }}
                />
              ))}
            </TabList>
          </Box>
        </Box>

        {tabs.map((item, index) => (
          <div key={`tabPanel-${index}`}>
            <TabPanel value={index.toString()}>{item}</TabPanel>
          </div>
        ))}
      </TabContext>
    </Box>
  );
}
