import { Popover, Stack } from "@mui/material";
import { Select, TextField } from "src/v2/components/atoms";
import { OPERATOR_OPTIONS } from "../../../utils/filterUtils";
import { IPopoverFilterProps } from "../../organisms/Filter/types";

export default function PopoverFilter({
  filter,
  filterAttributes,
  anchorEl,
  handleClose,
  handleOperator,
  handleInput,
  operator,
}: IPopoverFilterProps) {
  return (
    <Popover
      id={`simple-popover-${filter}`}
      open={filterAttributes[filter].openPopover}
      anchorEl={anchorEl}
      onClose={() => handleClose(filter)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack
        spacing={2}
        padding={1}
        maxWidth='10rem'
        sx={{ borderRadius: 1.5, border: "1.5px solid #8B7F78" }}
      >
        <Select
          value={operator}
          onChange={handleOperator}
          options={OPERATOR_OPTIONS}
          label=''
          fullWidth
          size='small'
          sx={{ backgroundColor: "#E8E8E8", color: "#8B7F78" }}
        />
        <TextField
          variant='outlined'
          name={filter}
          onChange={handleInput}
          value={filterAttributes[filter].value}
          read={false}
          size='small'
          sx={{ backgroundColor: "#E8E8E8", color: "#8B7F78" }}
        />
      </Stack>
    </Popover>
  );
}
