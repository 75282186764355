import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { NotesDataTable } from "src/v2/utils/personProfileDataTable";
import CustomAlert from "src/v2/components/molecules/Alert/CustomAlert";
import NoteForm from "src/v2/components/molecules/NoteForm/NoteForm";
import { UserInformation } from "src/v2/components/organisms/UserInformationTables/UserInformation";
import { INote, NoteSection } from "src/v2/models";

export function ClientProjectSection({
  personInformationNoteClient,
  isLoading,
  user,
}: {
  personInformationNoteClient?: INote[];
  user: string | undefined;
  isLoading?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);
  const [actionTitle, setActionTitle] = useState("");

  const [noteBeingEdited, setNoteBeingEdited] = useState<INote | undefined>(
    undefined
  );
  const [informationNoteClient, setInformationNoteClient] = useState<INote[]>(
    []
  );

  useEffect(() => {
    setInformationNoteClient(
      personInformationNoteClient
        ? Object.values(personInformationNoteClient).filter(
          (object) => object.section === NoteSection.Client
        )
        : []
    );
  }, [personInformationNoteClient]);

  const [alertInformation, setAlertInformation] = useState({
    severity: "success",
    message: "Data saved correctly",
  });
  const [savedData, setSavedData] = useState(false);

  const properties = {
    displayCheckbox: false,
    hidePagination: true,
    hideFooter: true,
    customToolBar: false,
    tableContainer: { maxWidth: "100%" },
  };

  const handleEditClick = (noteId: string) => {
    setNoteBeingEdited(
      informationNoteClient.filter((note) => noteId === note.id)[0]
    );
    handleToggle();
    setActionTitle("Editing Client/Project Note");
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSavedData(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <UserInformation
        isPending={isLoading}
        title='Client/Project'
        onClick={() => {
          setNoteBeingEdited(undefined);
          handleToggle();
          setActionTitle("Client/Project");
        }}
        columns={NotesDataTable(handleEditClick, user)}
        rows={informationNoteClient}
        properties={properties}
      />
      <Box data-testid='modal-dialog'>
        <NoteForm
          noteBeingEdited={noteBeingEdited}
          user={user}
          noteSection={NoteSection.Client}
          setAlertInformation={setAlertInformation}
          handleToggle={handleToggle}
          setSavedData={setSavedData}
          actionButton='SAVE'
          actionTitle={actionTitle}
          openDialog={open}
        />
      </Box>
      <CustomAlert
        sx={{ marginLeft: "50px" }}
        text={alertInformation.message}
        severity={
          alertInformation.severity as "error" | "success" | "info" | "warning"
        }
        open={savedData}
        onClose={handleClose}
      />
    </Box>
  );
}
