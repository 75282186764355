import {
  ActionViewTagComponent,
  arrayViewTagComponent,
  checkboxViewTagComponent,
  dateViewTagComponent,
  detailViewTagComponent,
  objectArrayViewTagComponent,
} from "../components/viewTagsComponents/simpleViewTags";

import { ViewTagComponentMapping } from "../models/dynamicViewTag.model";

export const fetchViewTagComponentMappingFor = (
  // eslint-disable-next-line no-unused-vars,  @typescript-eslint/no-unused-vars
  _page_id: string
): ViewTagComponentMapping => {
  return {
    simple_string: detailViewTagComponent,
    email: detailViewTagComponent,
    date: dateViewTagComponent,
    checkbox: checkboxViewTagComponent,
    details_button: ActionViewTagComponent,
    ACTION: ActionViewTagComponent,
    array: arrayViewTagComponent,
    object_modal: objectArrayViewTagComponent,
    select: detailViewTagComponent,
    chip: objectArrayViewTagComponent,
  };
};
