import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ReviewStatus } from "src/constants/reviewsConstants";
import { reviewInformationSchema } from "src/schemas/reviewInformationSchema";
import {
  Button,
  DatePicker,
  LoadingButton,
  Select,
  TextField,
  Typography,
} from "src/v2/components/atoms";
import { TEXTFIELD_BACKGROUND_COLOR } from "src/v2/constants/colors";
import { useCreateOnSubmitReviews } from "src/v2/hooks/useCreateOnSubmit";
import { IReview } from "src/v2/models";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { ConfirmSubmit } from "../ConfirmSubmit";
import {
  ReviewFormProps,
  SelectOptions,
  textFieldConfig,
  typographyStyles,
} from "./types";

function ReviewForm({
  reviewData,
  handleAccordionClose,
  setAlertInformation,
  setSavedData,
  currentValues,
  nextReviewDate,
  isPastReview,
}: Readonly<ReviewFormProps>) {
  const dateSubtractMonth = dayjs().subtract(1, "month");
  const defaultJobTitle =
    currentValues?.currentJobTitle ??
    (!isPastReview ? "Job title not found" : "");
  const getNextMonthFirstDay = (date: dayjs.Dayjs) => {
    return date.add(1, "months").startOf("month");
  };
  const defaultReviewDate =
    nextReviewDate ?? (isPastReview ? dateSubtractMonth : dayjs());
  const defaultReviewData: IReview = {
    id: "",
    proposedJob: defaultJobTitle,
    currentJob: defaultJobTitle,
    currentSalary: currentValues?.currentSalary ?? 0,
    proposedSalary: reviewData?.proposedSalary ?? 0,
    status: !isPastReview ? ReviewStatus.inProgress : ReviewStatus.done,
    result: "",
    reviewDate: defaultReviewDate.toDate(),
    comments: { comment: "" },
    effectiveDate:
      reviewData?.effectiveDate ??
      getNextMonthFirstDay(dayjs(nextReviewDate)).toDate(),
  };
  const {
    reset,
    resetField,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<IReview>({
    defaultValues: reviewData ?? defaultReviewData,
    resolver: yupResolver(reviewInformationSchema),
  });
  const titles = isPastReview
    ? { recentFieldTitle: "Previous", upcomingFieldTitle: "New" }
    : { recentFieldTitle: "Current", upcomingFieldTitle: "Proposed" };

  const selectMenuProps = { sx: { maxHeight: "30rem" } };
  const { personId } = useParams();
  let rate = 0;
  if (getValues("currentSalary") && getValues("proposedSalary")) {
    rate = Math.round(
      (getValues("proposedSalary") / getValues("currentSalary") - 1) * 100
    );
  }
  const [openCancelButton, setOpenCancelButton] = useState(false);
  const [openConfirmationButton, setOpenConfirmationButton] = useState(false);
  const [raiseChange, setRaiseChange] = useState(rate);
  const calculateSalary = (
    updatedSalary: number,
    updatedPercentage: number
  ) => {
    updatedSalary = Number(updatedSalary);
    const newSalaryValue =
      updatedSalary + (updatedSalary * updatedPercentage) / 100;
    return Number(newSalaryValue.toFixed(2));
  };
  const calculateRaise = (updatedSalary: number) => {
    const newRaiseValue = Math.round(
      (updatedSalary / (getValues("currentSalary") ?? 1) - 1) * 100
    );
    return newRaiseValue;
  };
  const handleStay = () => {
    setOpenConfirmationButton(false);
    setOpenCancelButton(false);
  };
  const handleLeave = () => {
    setOpenCancelButton(false);
    setOpenConfirmationButton(false);
    resetField("status");
    if (!openConfirmationButton && handleAccordionClose) handleAccordionClose();
  };
  const handleSalary = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSalary = Number.isNaN(parseFloat(e.target.value))
      ? 0
      : parseFloat(e.target.value);
    if (newSalary < 0) return;
    const newRaise = calculateRaise(newSalary);
    setValue("proposedSalary", newSalary, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (newRaise < 0) {
      setRaiseChange(0);
      return;
    }
    setRaiseChange(newRaise);
  };
  const handleRaise = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raise = parseInt(e.target.value, 10);
    const newRaise = Number.isNaN(raise) ? 0 : Math.round(raise);
    const newSalary = calculateSalary(
      getValues("currentSalary") ?? 1,
      newRaise
    );
    if (newRaise < 0) return;
    setRaiseChange(newRaise);
    if (newSalary <= getValues("currentSalary")) setValue("proposedSalary", 0);
    else {
      setValue("proposedSalary", newSalary, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };
  const handleStatus = (isSubmit: boolean) => {
    if (isSubmit) {
      setValue("status", ReviewStatus.done);
      setOpenConfirmationButton(true);
    } else {
      resetField("status");
    }
  };

  const { mutation: onSubmit, isPending } = useCreateOnSubmitReviews({
    onSuccess: () => {
      setAlertInformation({
        severity: "success",
        message: "Data saved correctly",
      });
      setSavedData(true);
      if (handleAccordionClose) handleAccordionClose();
    },
    onError: (error) => {
      setAlertInformation({
        severity: "error",
        message: `Error: ${error.message}`,
      });
      setSavedData(true);
    },
    onSettled: () => {
      reset();
    },
    personId,
    newReview: !reviewData,
  });
  const handleSave = handleSubmit(async (formData) => {
    if (isPastReview) {
      formData.effectiveDate = formData.reviewDate;
    }
    if (getValues("status") !== ReviewStatus.done) {
      formData.status = ReviewStatus.inProgress;
    }

    if (formData.proposedSalary === 0)
      formData.proposedSalary = formData.currentSalary;
    await onSubmit(formData);
  });
  const [minDate, setMinDate] = useState(nextReviewDate ?? dayjs());
  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 0,
        marginY: 3,
        paddingX: { xs: "0rem", sm: "1.5rem" },
      }}
      maxWidth={false}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={6}
        display='flex'
        alignItems='baseline'
        columns={{ xs: 3, sm: 6, md: 8, lg: 12 }}
      >
        <Grid xs={3} sm={8} md='auto' lg={12}>
          <Typography sx={typographyStyles}>Review Date</Typography>
        </Grid>
        <Grid xs={3} sm={8} md='auto' lg={12}>
          <Controller
            control={control}
            name='reviewDate'
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label=''
                slotProps={{
                  textField: { helperText: errors.reviewDate?.message },
                }}
                value={dayjs(value)}
                sx={[{ width: "21rem" }, textFieldConfig.sx]}
                disabled={
                  getValues("status") === ReviewStatus.done && !isPastReview
                }
                views={["month", "year"]}
                onChange={(e) => {
                  if (e) {
                    onChange(e.toDate());
                    setValue("effectiveDate", getNextMonthFirstDay(e).toDate());
                    setMinDate(e);
                  }
                }}
                {...(isPastReview
                  ? { maxDate: dateSubtractMonth }
                  : { minDate: dayjs() })}
              />
            )}
          />
        </Grid>
        <Grid
          xs={3}
          sm={6}
          md={5}
          lg={!isPastReview ? 4 : 4.6}
          xl={!isPastReview ? 3 : 4.6}
        >
          <Typography sx={typographyStyles}>
            {titles.upcomingFieldTitle} Salary
          </Typography>
          <Controller
            control={control}
            name='proposedSalary'
            render={({ field: { value } }) => (
              <TextField
                {...textFieldConfig}
                value={
                  value === 0 ||
                  Number(getValues("proposedSalary")) ===
                    Number(getValues("currentSalary"))
                    ? ""
                    : value.toString()
                }
                onKeyPress={(e) => {
                  if (e.key === "-" || e.key === "+" || e.key === "e")
                    e.preventDefault();
                }}
                onChange={(e) => {
                  handleSalary(e);
                }}
                sx={[
                  { marginBottom: "1.5rem", width: "100%" },
                  textFieldConfig.sx,
                ]}
                disabled={
                  getValues("status") === ReviewStatus.done && !isPastReview
                }
                helperText={errors.proposedSalary?.message}
                hug='dollarSign'
                type='number'
              />
            )}
          />
        </Grid>
        {!isPastReview && (
          <Grid xs={3} sm={6} md={3} lg={3} lgOffset={0.5} xl={1.6}>
            <Typography sx={typographyStyles}>Raise %</Typography>
            <TextField
              {...textFieldConfig}
              value={raiseChange.toString()}
              onChange={handleRaise}
              disabled={
                getValues("status") === ReviewStatus.done && !isPastReview
              }
              sx={[{ marginBottom: "1.5rem" }, textFieldConfig.sx]}
              type='number'
            />
          </Grid>
        )}
        <Grid xs={3} sm={6} md={6} lg={4} lgOffset={0.5} xl={3}>
          <Typography sx={typographyStyles}>
            {titles.recentFieldTitle} Salary
          </Typography>
          <Controller
            control={control}
            name='currentSalary'
            render={({ field: { value, onChange } }) => (
              <TextField
                {...textFieldConfig}
                value={value.toString()}
                sx={[
                  {
                    marginBottom: "1.5rem",
                  },
                  textFieldConfig.sx,
                ]}
                helperText={errors.currentSalary?.message}
                disabled={!isPastReview}
                hug='dollarSign'
                type='number'
                onKeyPress={(e) => {
                  if (e.key === "+") e.preventDefault();
                }}
                onChange={(e) => {
                  const salary = e.target.value;
                  const newSalary = Number.isNaN(parseFloat(salary))
                    ? 0
                    : parseFloat(salary);
                  if (newSalary < 0) return;
                  onChange(newSalary);
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={8} lg={6} xl={4.6}>
          <Typography sx={typographyStyles}>
            {titles.upcomingFieldTitle} Job Title
          </Typography>
          <Controller
            control={control}
            name='proposedJob'
            render={({ field: { onChange, value } }) => (
              <Select
                label=''
                value={value}
                options={SelectOptions(!!isPastReview)}
                error={errors.proposedJob?.message}
                onChange={onChange}
                disabled={
                  getValues("status") === ReviewStatus.done && !isPastReview
                }
                fullWidth
                size='small'
                sx={[{ marginBottom: "1.5rem" }, textFieldConfig.sx]}
                menuProps={selectMenuProps}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={8} lg={6} xl={4}>
          <Typography sx={typographyStyles}>
            {titles.recentFieldTitle} Job Title
          </Typography>
          <Controller
            control={control}
            name='currentJob'
            render={({ field: { onChange, value } }) => (
              <Select
                label=''
                value={value}
                fullWidth
                size='small'
                onChange={onChange}
                options={SelectOptions(!!isPastReview)}
                error={errors.currentJob?.message}
                sx={[{ marginBottom: "1.5rem" }, textFieldConfig.sx]}
                disabled={!isPastReview}
                menuProps={selectMenuProps}
              />
            )}
          />
        </Grid>
        {!isPastReview && (
          <>
            <Grid xs={3} sm={8} md='auto' lg={12}>
              <Typography sx={typographyStyles}>Effective Date</Typography>
            </Grid>
            <Grid xs={3} sm={8} md='auto' lg={12}>
              <Controller
                control={control}
                name='effectiveDate'
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label=''
                    slotProps={{
                      textField: { helperText: errors.effectiveDate?.message },
                    }}
                    value={dayjs(value)}
                    disabled={getValues("status") === ReviewStatus.done}
                    sx={[{ width: "21rem" }, textFieldConfig.sx]}
                    views={["day", "month", "year"]}
                    format='DD-MM-YYYY'
                    onChange={(e) => {
                      if (e) setValue("effectiveDate", e.toDate());
                      onChange(e?.format("YYYY-MM-DD"));
                    }}
                    minDate={minDate}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid xs={3} sm={6} md={12}>
          <Typography sx={typographyStyles}>Comments</Typography>
        </Grid>
        <Grid xs={3} sm={6} md={12}>
          <Controller
            name='comments'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...textFieldConfig}
                value={value.comment}
                onChange={(e) => {
                  onChange({ comment: e.target.value });
                }}
                helperText={errors.comments?.comment?.message}
                maxRows={5}
                minRows={20}
                multiline
                sx={{ backgroundColor: TEXTFIELD_BACKGROUND_COLOR }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={12}>
          <Typography sx={typographyStyles}>Final Review Results</Typography>
        </Grid>
        <Grid xs={3} sm={6} md={12}>
          <Controller
            name='result'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...textFieldConfig}
                value={value}
                helperText={errors.result?.message}
                onChange={onChange}
                multiline
                maxRows={10}
                minRows={10}
                sx={{ backgroundColor: TEXTFIELD_BACKGROUND_COLOR }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={6}
        display='flex'
        alignItems='center'
        columns={{ xs: 3, sm: 12 }}
      >
        <Grid sm={3} sx={{ marginTop: 5 }}>
          {getValues("status") !== ReviewStatus.done && (
            <Button
              text='Approve'
              color='primary'
              size='large'
              onClick={() => setOpenConfirmationButton(true)}
              disabled={isPending}
              sx={{ width: "5rem" }}
            />
          )}
        </Grid>
        <Grid sm={9} justifyContent='flex-end'>
          <Stack
            direction='row'
            marginTop={5}
            spacing={3}
            justifyContent='flex-end'
          >
            <Button
              text='Cancel'
              color='inherit'
              size='large'
              onClick={() => {
                if (isDirty) {
                  setOpenCancelButton(true);
                } else {
                  handleLeave();
                }
              }}
              disabled={isPending}
              sx={{ width: "5rem", color: "#5F5C5C" }}
            />
            <LoadingButton
              variant='outlined'
              color='primary'
              loading={isPending}
              onClick={handleSave}
              text='Save'
            />
          </Stack>
        </Grid>
      </Grid>
      <ConfirmSubmit
        open={openConfirmationButton}
        currentJobTitle={getValues("currentJob")}
        newJobTitle={getValues("proposedJob")}
        currentSalary={getValues("currentSalary").toString()}
        newSalary={
          getValues("proposedSalary") === 0
            ? getValues("currentSalary").toString()
            : getValues("proposedSalary").toString()
        }
        handleSubmit={handleSave}
        handleClose={handleLeave}
        handleStay={handleStay}
        handleStatus={handleStatus}
        isFormValid={isValid}
        effectiveDate={getValues("effectiveDate").toString()}
      />
      <ConfirmDialog
        confirmType={false}
        dialogTitle='Are you sure you want to leave without saving?'
        openCancelButton={openCancelButton}
        handleStay={handleStay}
        handleLeave={handleLeave}
      />
    </Container>
  );
}

export default ReviewForm;
