import dayjs from "dayjs";
import { sanitizeArray } from "src/v2/utils/parsers";
import { generateIncrementingNumbers } from "../../data/utils/generators";
import { IDataDefinitionSchema } from "../models/dataDefinition.model";
import { DynamicModel } from "../models/dynamicModel.model";
import { ViewTag } from "../models/dynamicViewTag.model";
import { capitalizeOnlyFirstLetterOfEveryWord } from "../utils/stringUtils";

export const dateToColumnLabelPresenter = (value: Date): string => {
  const getDateValues = value ? dayjs(value).format("DD-MM-YYYY") : undefined;

  return getDateValues!;
};

const getDynamicValue = (viewTag: ViewTag, value: any, label: string) => {
  let formatedValue = value ?? null;
  if (viewTag === ViewTag.date) {
    formatedValue = value ? new Date(`${value}T00:00:00`) : undefined;
  }
  return [label, formatedValue];
};

export const transformDataToTableData = (
  data: any[],
  dataDefinition: IDataDefinitionSchema
) => {
  const numberGenerator = generateIncrementingNumbers();
  const filteredData = sanitizeArray(data).filter(
    (dynamicModel: DynamicModel) => {
      return dynamicModel.values.customFields?.ioetId !== null;
    }
  );

  const tableData = filteredData.map((dynamicModel: DynamicModel) => {
    const peopleData = dynamicModel.values;
    const name = dynamicModel.values.name
      ? capitalizeOnlyFirstLetterOfEveryWord(dynamicModel.values.name)
      : null;
    const lastName = peopleData.lastName
      ? capitalizeOnlyFirstLetterOfEveryWord(peopleData.lastName)
      : null;

    const item: any = {
      ...peopleData,
      name,
      lastName,
    };
    const transformedItem: any = Object.fromEntries(
      Object.entries(dataDefinition.fields)
        .filter(([field]) => field in item)
        .map(([field, { label, view_tag }]) =>
          getDynamicValue(view_tag, item[field], label)
        )
    );
    transformedItem.id = numberGenerator.next().value;
    return transformedItem;
  });
  return tableData;
};
