import axios from "axios";
import { statusMessages } from "src/constants/types";
import { GeneralErrorResponse } from "src/domain/model/GeneralErrorResponse";
import { BACKEND_URL_ENV } from "src/v2/config/constants";

const peopleBff = axios.create({
  headers: { "Content-Type": "application/json" },
  baseURL: BACKEND_URL_ENV,
  withCredentials: true,
});

peopleBff.interceptors.response.use(
  (response) => response,
  (error) => {
    let message: string = "Something was wrong. Please, try again later";
    const statusCode = Number(error.response?.status);

    if (statusMessages[statusCode]) {
      message = statusMessages[statusCode] as string;
    }
    const errorResponse: GeneralErrorResponse = new GeneralErrorResponse(
      error.response.data.message ?? message,
      error.response.status
    );

    return Promise.reject(errorResponse);
  }
);

const peopleSync = axios.create({
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

peopleSync.interceptors.response.use(
  (response) => response,
  (error) => {
    let message: string = "Something was wrong. Please, try again later";
    const statusCode = Number(error.response?.status);

    if (statusMessages[statusCode]) {
      message = statusMessages[statusCode] as string;
    }
    const errorResponse: GeneralErrorResponse = new GeneralErrorResponse(
      message,
      error.response.status
    );
    return Promise.reject(errorResponse);
  }
);
export default { peopleBff, peopleSync };
