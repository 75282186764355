import {
  PEOPLE_BFF_NEXT_REVIEW_DATE,
  PEOPLE_BFF_PERSON,
  PEOPLE_BFF_REVIEW,
} from "../config/constants";
import httpClient from "../config/httpClient";
import { IReview } from "../models";
import { IReviewDashboard } from "../models/review.model";

export async function getReviewsDashboard(
  date: string
): Promise<IReviewDashboard[]> {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON}last_reviews/${date}`
  );
  const transformedReviews: IReviewDashboard[] = response.data.map(
    (review: any) => {
      return {
        id: review.lastReview.id,
        ioetId: review.personId,
        name: review.name,
        reviewDate: review.nextReviewDate,
        status: review.lastReview.status,
        jobTitle: review.jobTitle,
      };
    }
  );
  return transformedReviews as unknown as IReviewDashboard[];
}

export async function getReviewsByPersonId(personId: string | undefined) {
  const response = await httpClient.peopleBff.get<IReview[]>(
    `${PEOPLE_BFF_PERSON}review/${personId}`
  );
  return response.data;
}

export async function createReview(
  newReview: IReview,
  personId: string | undefined
) {
  const parsedReview = {
    person_id: personId,
    review: newReview,
  };

  const response = await httpClient.peopleBff.post(
    PEOPLE_BFF_REVIEW,
    parsedReview
  );
  return response;
}

export async function updateReview(
  updatedReview: IReview,
  personId: string | undefined
) {
  const parsedReview = {
    person_id: personId,
    review: updatedReview,
  };
  const response = await httpClient.peopleBff.put(
    PEOPLE_BFF_REVIEW,
    parsedReview
  );
  return response;
}

export async function getNextReviewDateByPersonId(personId: string) {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON + PEOPLE_BFF_NEXT_REVIEW_DATE + personId}/`
  );
  return response.data;
}

export async function updateNextReviewDateByPersonId(
  personId: string,
  nextReviewDate: Date
): Promise<void> {
  await httpClient.peopleBff.put(
    `${PEOPLE_BFF_PERSON + PEOPLE_BFF_NEXT_REVIEW_DATE}`,
    { person_id: personId, next_review_date: nextReviewDate }
  );
}
