/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React from "react";
import { filterColumns } from "src/v2/utils/filterColumns";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { Table } from "src/v2/components/organisms/Table/Table";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";
import { Compensation } from "src/v2/models/compensation.model";

export function CompensationHistorySection(compensationInformation: {
  compensationInformation: Compensation[];
}) {
  const { compensationInformation: personCompensationData } =
    compensationInformation;
  const dataIconColumnGap = "0.5rem";
  const headerCompensationMap: { [key: string]: string } = {
    effectiveDate: "Effective Date",
    payFrequency: "Payment Frequency",
    payRate: "Salary",
    changeReason: "Change Reason",
    comment: "Comment",
  };
  const personCompensationFilter = filterColumns(
    personCompensationData as Dict[],
    headerCompensationMap
  );
  const columns = React.useMemo(() => {
    const keys =
      personCompensationFilter.length > 0
        ? Object.keys(personCompensationFilter[0])
        : Object.keys(headerCompensationMap);

    const existingColumns = keys.map((key) => ({
      field: key,
      headerName: headerCompensationMap[key],
      minWidth: key === "payRate" ? 140 : 180,
    }));
    return existingColumns;
  }, [personCompensationFilter]);

  const rows = React.useMemo(
    () =>
      personCompensationData.map((item, index) => ({
        id: index,
        ...item,
        payFrequency: item.payFrequency
          ? personCompensationFilter[index].payFrequency.frequency
          : "",
      })),
    [personCompensationFilter]
  );
  return (
    <Box>
      <Box sx={{ paddingTop: "1rem", paddingBottom: "1.5rem", width: "100%" }}>
        <DataIcon
          iconName={{ icon: "compensation" }}
          text={{
            children: "Compensation History",
            variant: "h6",
            sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
          }}
          columnGap={dataIconColumnGap}
        />
      </Box>
      <Box role='table' sx={{ paddingBottom: "1.5rem", width: "100%" }}>
        <Table
          columns={columns}
          rowData={rows}
          isCustomToolBar={false}
          hideFooter
          sxTableContainer={{
            maxWidth: "fit-content",
          }}
        />
      </Box>
    </Box>
  );
}
