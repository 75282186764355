import { DialogActions } from "@mui/material";
import { useLocaleText } from "@mui/x-date-pickers/internals";
import { Button } from "src/v2/components/atoms/Button";
import { IPickersActionBarProps } from "./types";

function DatePickerCustomButtons(props: IPickersActionBarProps) {
  const {
    onAccept,
    onClear,
    onCancel,
    onSetToday,
    setOpenDatePicker,
    actions,
    className,
    customAcceptLabel,
    customCancelLabel,
    customClearLabel,
    customTodayLabel,
    handleNextReviewDateUpdate,
  } = props;
  const localeText = useLocaleText();

  if (actions == null || actions.length === 0) {
    return null;
  }

  const menuItems = actions?.map((actionType) => {
    switch (actionType) {
    case "clear":
      return (
        <div key={actionType}>
          <Button
            text={customClearLabel || localeText.clearButtonLabel}
            size='small'
            onClick={() => {
              onClear();
              setOpenDatePicker(false);
            }}
          />
        </div>
      );
    case "cancel":
      return (
        <div key={actionType}>
          <Button
            text={customCancelLabel || localeText.cancelButtonLabel}
            size='small'
            color='inherit'
            onClick={() => {
              onCancel();
              setOpenDatePicker(false);
            }}
          />
        </div>
      );
    case "accept":
      return (
        <div key={actionType}>
          <Button
            text={customAcceptLabel || localeText.okButtonLabel}
            size='small'
            color='primary'
            onClick={() => {
              onAccept();
              setOpenDatePicker(false);
              if (handleNextReviewDateUpdate) {
                handleNextReviewDateUpdate();
              }
            }}
          />
        </div>
      );
    case "today":
      return (
        <div key={actionType}>
          <Button
            text={customTodayLabel || localeText.todayButtonLabel}
            size='small'
            onClick={() => {
              onSetToday();
              setOpenDatePicker(false);
            }}
          />
        </div>
      );
    default:
      return null;
    }
  });

  return <DialogActions className={className}>{menuItems}</DialogActions>;
}
export default DatePickerCustomButtons;
