import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import { SelectChangeEvent } from "@mui/material";
import {
  IMultiSelectData,
  MultipleSelect,
} from "../atoms/MultipleSelect/MultipleSelect";

interface ChipEditViewTagComponentProps {
  params: GridRenderCellParams;
  multiSelectOptions: IMultiSelectData[];
}

export function ChipEditViewTagComponent({
  params,
  multiSelectOptions,
}: ChipEditViewTagComponentProps) {
  const { id, field } = params;
  const apiRef = useGridApiContext();
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const formattedValue =
      Array.isArray(value) && value.map((item: any) => ({ name: item }));
    apiRef.current.setEditCellValue({ id, field, value: formattedValue });
  };

  return (
    <MultipleSelect
      handleChange={handleChange}
      defaultValues={params.value}
      multiSelectOptions={multiSelectOptions}
    />
  );
}
