import checkFeatureFlag from "src/v2/utils/checkFeatureFlag";
import { IFeatureFlag } from "./types";

function FeatureFlag({ featureFlagName, children }: IFeatureFlag) {
  const isEnabled = checkFeatureFlag(featureFlagName);
  if (!isEnabled) {
    return null;
  }
  return children;
}
export default FeatureFlag;
