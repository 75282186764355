import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import { ISelectProps } from "./types";

function Select({
  id,
  variant = "outlined",
  label,
  disabled,
  value,
  options,
  sx,
  size,
  fullWidth,
  error,
  onChange,
  menuProps,
}: ISelectProps) {
  return (
    <FormControl variant={variant} fullWidth={fullWidth} error={Boolean(error)}>
      <InputLabel
        id={id ?? "select-label"}
        sx={{
          fontSize: size === "small" ? "1rem" : "1.1rem",
          top: size === "small" ? "-6px" : "-2px",
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        labelId='select-label'
        value={value}
        label={label}
        disabled={disabled}
        sx={sx}
        size={size}
        onChange={onChange}
        MenuProps={menuProps}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default Select;
