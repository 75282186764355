import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
  Avatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { PEOPLE_REVIEWS_DASHBOARD } from "src/constants/urlConstants";
import { Typography } from "src/v2/components/atoms/Typography";
import { useMutateNotification } from "src/v2/hooks/useMutations";
import { UserNotification } from "src/v2/models/";

dayjs.extend(utc);
function UserTimeNotification({
  notification,
}: {
  notification: UserNotification;
}) {
  const { createdAt, message, title, read } = notification;
  const theme = useTheme();
  const navigate = useNavigate();
  const { mutate } = useMutateNotification();
  const location = useLocation();

  const handleNotification = async (notificationId: string) => {
    if (!read) {
      await mutate(notificationId);
    }
    if (location.pathname !== PEOPLE_REVIEWS_DASHBOARD) {
      navigate(PEOPLE_REVIEWS_DASHBOARD);
    }
  };

  return (
    <ListItemButton
      sx={{
        backgroundColor: read ? "transparent" : theme.palette.grey[200],
      }}
      alignItems='flex-start'
      onClick={() => {
        handleNotification(notification.id);
      }}
    >
      <Stack width='100%' flexDirection='column'>
        <Stack width='100%' flexDirection='row' gap={1}>
          <ListItemIcon>
            <Avatar
              sx={{
                bgcolor: !read
                  ? theme.palette.primary.main
                  : theme.palette.grey[400],
                height: "3.2rem",
                width: "3.2rem",
                boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
              }}
            >
              <CalendarTodayOutlinedIcon fontSize='large' />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant='body1' color='textPrimary' bold={!read}>
                {title}
              </Typography>
            }
            secondary={
              <Typography variant='body2' color='textSecondary'>
                {message}
              </Typography>
            }
          />
        </Stack>
        <Stack justifyContent='flex-end' display='flex' flexDirection='row'>
          <Typography variant='body2' color='textSecondary'>
            <ReactTimeAgo
              date={dayjs.utc(createdAt).local().toDate()}
              timeStyle='round-minute'
            />
          </Typography>
        </Stack>
      </Stack>
    </ListItemButton>
  );
}
export default UserTimeNotification;
