import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IDatePickerProps } from "./types";

function DatePicker({
  label,
  value,
  disabled,
  closeOnSelect,
  defaultValue,
  onChange,
  onAccept,
  open,
  minDate,
  maxDate,
  onOpen,
  onClose,
  sx,
  views,
  slotProps = {},
  disableFuture = false,
  format,
  slots = {},
}: IDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        label={label}
        value={value}
        onAccept={onAccept}
        onChange={onChange}
        disabled={disabled}
        closeOnSelect={closeOnSelect}
        defaultValue={defaultValue}
        minDate={minDate}
        maxDate={maxDate}
        onOpen={onOpen}
        onClose={onClose}
        open={open}
        sx={sx}
        yearsPerRow={3}
        slotProps={slotProps}
        views={views}
        disableFuture={disableFuture}
        slots={slots}
        format={format}
      />
    </LocalizationProvider>
  );
}
export default DatePicker;
