import { DataGridProps } from "@mui/x-data-grid";
import {
  TABLE_COLUMN_HEADER_COLOR,
  TABLE_ROW_COLOR,
} from "src/v2/constants/colors";
import tw from "twin.macro";

export const defaultStyles: DataGridProps["sx"] = {
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    background: TABLE_COLUMN_HEADER_COLOR,
  },
  ".MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
  "& .MuiDataGrid-row": {
    background: TABLE_ROW_COLOR,
  },
  "& .MuiDataGrid-toolbarContainer": {
    padding: "10px",
  },
};

export const TableToolBoxContainer = tw.div`
    flex
    justify-around
    items-center
    mb-4
    px-4
    rounded-t-md
    bg-gray-50
    flex-wrap
    `;

export const TableToolBoxContainerGroup = tw.div`
    sm:(flex
    space-x-3
    flex-wrap)
    `;
