import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Stack } from "@mui/system";
import { ReviewStatus } from "src/constants/reviewsConstants";
import { Icon } from "src/v2/components/atoms/Icon";
import { Typography } from "src/v2/components/atoms/Typography";
import { TABLE_COLUMN_HEADER_COLOR } from "src/v2/constants/colors";
import { IReviewPersonProps } from "./types";

const squareIconStyle = {
  padding: "0.5rem",
  background: "none",
  borderRadius: "16px",
  minWidth: "9rem",
};

function ReviewPerson({
  reviewDate,
  status,
  children,
  isAccordionOpen,
  handleAccordionOpen,
}: IReviewPersonProps) {
  const statusIcon: Record<ReviewStatus, JSX.Element> = {
    [ReviewStatus.done]: (
      <TaskAltIcon sx={{ fontSize: "30px" }} color='success' />
    ),
    [ReviewStatus.inProgress]: (
      <HourglassEmptyIcon sx={{ fontSize: "30px", color: "gray" }} />
    ),
    [ReviewStatus.toDo]: (
      <PendingActionsIcon sx={{ fontSize: "30px", color: "gray" }} />
    ),
  };

  const statusValue = status || ReviewStatus.toDo;
  const message = statusValue;
  const icon = statusIcon[statusValue as ReviewStatus];
  return (
    <Accordion expanded={isAccordionOpen} onChange={handleAccordionOpen}>
      <AccordionSummary
        sx={{ maxWidth: "100%", backgroundColor: TABLE_COLUMN_HEADER_COLOR }}
        expandIcon={<ExpandMoreIcon fontSize='large' />}
      >
        <Stack
          direction='row'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          spacing={4}
          marginRight={8}
          style={{ width: "100%" }}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            spacing={2}
          >
            <Icon icon='calendar' />

            <Typography align='center' variant='h6'>
              {reviewDate}
            </Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            spacing={1}
            sx={squareIconStyle}
          >
            {icon}
            <Typography variant='h6'> {message as string}</Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <div>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
}

export default ReviewPerson;
