import { Route, Routes } from "react-router-dom";
import RequiredAdmin from "src/components/Auth/requireAdmin";
import RequiredUser from "src/components/Auth/requireUser";
import LoginStatusContainer from "src/components/LoginStatusContainer/LoginStatusContainer";
import {
  PEOPLE_ADD_CANDIDATE_URL,
  PEOPLE_BASE_URL,
  PEOPLE_CANDIDATE_TRANSFORMATION,
  PEOPLE_EMPLOYEE_PROFILE_ID_URL,
  PEOPLE_HOME_URL,
  PEOPLE_LOGIN_URL,
  PEOPLE_PERSON_TAB_URL,
  PEOPLE_PERSON_URL,
  PEOPLE_REVIEWS_DASHBOARD,
} from "src/constants/urlConstants";
import { CandidatePage } from "src/pages/CandidatePage/CandidatePage";
import { EmployeeProfile } from "src/pages/EmployeeProfile/EmployeeProfile";
import { HomeScreen } from "src/pages/HomeScreen/HomeScreen";
import Layout from "src/v2/components/templates/Layout/Layout";
import { CandidateToEmployee } from "src/v2/pages/CandidateToEmployee/CandidateToEmployee";
import { PersonProfile } from "src/v2/pages/PersonProfile/PersonProfile";
import { ListPeoplePage } from "src/v2/pages/listPeoplePage";
import { ReviewsDashboardPage } from "../pages/ReviewsDashboardPage/ReviewsDashboardPage";

export function Navigation() {
  return (
    <Routes>
      <Route path={PEOPLE_LOGIN_URL} element={<LoginStatusContainer />} />

      <Route element={<RequiredUser />}>
        <Route path='/' element={<Layout />}>
          <Route path={PEOPLE_HOME_URL} element={<HomeScreen />} />
          <Route index path={PEOPLE_BASE_URL} element={<ListPeoplePage />} />
        </Route>
      </Route>

      <Route element={<RequiredAdmin />}>
        <Route path='/' element={<Layout />}>
          <Route path={PEOPLE_ADD_CANDIDATE_URL} element={<CandidatePage />} />
          <Route
            path={PEOPLE_EMPLOYEE_PROFILE_ID_URL}
            element={<EmployeeProfile />}
          />
          <Route
            path={PEOPLE_CANDIDATE_TRANSFORMATION}
            element={<CandidateToEmployee />}
          />
          <Route path={PEOPLE_PERSON_URL} element={<PersonProfile />} />
          <Route path={PEOPLE_PERSON_TAB_URL} element={<PersonProfile />} />
          <Route
            path={PEOPLE_REVIEWS_DASHBOARD}
            element={<ReviewsDashboardPage />}
          />
        </Route>
      </Route>
      <Route path='*' element={<h1>Page Not Found </h1>} />
    </Routes>
  );
}
