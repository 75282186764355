import { TabButton } from "../TabButton/TabButton";
import {
  HeaderContainer,
  ProfileImage,
  ProfileMainInfo,
  TabsContainer,
  ProfileMainInfoContainer,
} from "./HeaderFormsComponentStyle";
import { IHeaderForms } from "./IHeaderForms";

export function HeaderForms({
  setActivePage,
  ioetId,
  job_title,
  userName,
}: IHeaderForms) {
  return (
    <HeaderContainer>
      <ProfileImage src='' alt='' />
      <ProfileMainInfoContainer>
        <ProfileMainInfo>
          <h1 className='font-bold text-white text-2xl lg:text-4xl '>
            {userName}
          </h1>
          <h4 className='text-white text-sm lg:text-xl'>{job_title}</h4>
          <h4 className='text-white text-sm  lg:text-xl'>{ioetId}</h4>
        </ProfileMainInfo>
        <TabsContainer data-testid='employee-info-navbar'>
          <TabButton
            text='Personal'
            callback={() => setActivePage("personalInfo")}
          />
          <TabButton
            text='Education'
            callback={() => setActivePage("education")}
          />
          <TabButton
            text='Emergency'
            callback={() => setActivePage("emergency")}
          />
        </TabsContainer>
      </ProfileMainInfoContainer>
    </HeaderContainer>
  );
}
