const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const AUTH_APP_NAME = process.env.REACT_APP_AUTH_APP_NAME;

const LOCAL_BACKEND_URL = process.env.REACT_APP_LOCAL_BACKEND_URL;

const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
const SYNC_URL = process.env.REACT_APP_SYNC_URL;
const REACT_TYPE_ENV = process.env.REACT_APP_REACT_TYPE_ENV;

export {
  AUTH_APP_NAME,
  AUTH_URL,
  LOCAL_BACKEND_URL,
  REACT_TYPE_ENV,
  SERVICE_URL,
  SYNC_URL,
};
