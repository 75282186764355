import dayjs from "dayjs";
import { StringOrEmpty } from "src/types/types";
import { DEFAULT_FEATURE_FLAG_ACTIVATED_VALUE as IS_FEATURE_FLAG_ACTIVATED } from "../../constants/modelConstants";
import { IOptions } from "../../pages/FormEmployee/IEmployee";

export const formatStatus = (ioet_status: string[]) =>
  `${ioet_status.join("<br>")}`;

export const parseOptions = (options: (string | IOptions)[] | null) => {
  if (!options) return options;
  return options.map((item: string | IOptions) => {
    return {
      value: item,
      label: item,
    };
  });
};
export const parseCammelCaseToText = (text: string) => {
  if (!text) return "";
  const splitText = text.replace(/([A-Z])/g, " $1").trim();
  return splitText.toLowerCase();
};
export const convertToUppercase = (column_label: string) =>
  `${column_label.toUpperCase().replace(/_/g, " ")}`;

export const convertToLowerCase = (column_label: string) => {
  if (typeof column_label !== "string") {
    return "";
  }
  return `${column_label?.toLowerCase().replace(/_/g, " ")}`;
};

export const capitalizeFirstLetter = (str: string) => {
  if (typeof str !== "string") {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeToTitleCase = (str: string) => {
  if (typeof str !== "string") {
    return "";
  }
  return str.replace(/\b\w/g, (l) => l.toUpperCase());
};

export const stringToTitleCase = (str: string) =>
  str.toLowerCase().replace(/\b\w/g, (string) => string.toUpperCase());

export const parseDate = (value: Date): string => {
  const date = value ? new Date(value) : null;

  return date
    ? `${(date.getDate() + 1).toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`
    : "";
};

export const getDateToString = (date: Date | StringOrEmpty | number) => {
  if (!date) return undefined;
  if (typeof date === "string") {
    const dateParts = date.split("-");
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  }
  if (typeof date === "number") {
    const dateTimestamp = new Date(date);
    return `${dateTimestamp.getFullYear()}-${
      dateTimestamp.getMonth() + 1
    }-${dateTimestamp.getDate()}`;
  }
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const parseFeatureFlag = (feature_flag_name: string): boolean => {
  if (!feature_flag_name || !process.env[feature_flag_name]) return false;

  const featureValue = process.env[feature_flag_name]?.toUpperCase();

  return featureValue === IS_FEATURE_FLAG_ACTIVATED;
};

export const sanitizeArray = (data: any): any[] => {
  if (!data || !Array.isArray(data)) return [];
  return data;
};

export const yearMonthDate = (date: string) => {
  return dayjs(date).startOf("month").format("MMMM YYYY");
};

export const getFormattedDate = () => {
  const today: Date = new Date();
  let formattedDate: string;

  if (today.getDate() === 1) {
    formattedDate = `${today.getFullYear()}-${`0${today.getMonth() + 1}`.slice(
      -2
    )}-02`;
  } else {
    formattedDate = `${today.getFullYear()}-${`0${today.getMonth() + 1}`.slice(
      -2
    )}-${`0${today.getDate()}`.slice(-2)}`;
  }
  return formattedDate;
};
