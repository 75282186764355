import { Tooltip as MuiTooltip } from "@mui/material";
import { ITooltip } from "./types";

function Tooltip({ title, children, placement = "top", arrow, sx }: ITooltip) {
  return (
    <MuiTooltip title={title} placement={placement} arrow={arrow} sx={sx}>
      <div>{children}</div>
    </MuiTooltip>
  );
}
export default Tooltip;
