import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { uselistAllPeople } from "src/v2/hooks/useFetchs";
import { ALERT_STATE } from "../components/Alert/IAlert";
import { useAlert } from "./alert/useAlert";
import { GeneralErrorResponse } from "../domain/model/GeneralErrorResponse";
import { triggerHydration } from "../services/RefreshDataService";
import LoadingContext from "../contexts/LoadingStateContext";
import { ILoadingContextState } from "../contexts/IContexts";

export const useListPeople = () => {
  const navigate = useNavigate();
  const { alertState, updateAlertState } = useAlert();
  const { setLoading } = useContext(LoadingContext) as ILoadingContextState;
  const { data, error, isPending } = uselistAllPeople();

  if (error) {
    updateAlertState({
      show: true,
      data: {
        body: error.message,
        color: ALERT_STATE.error,
        title: "Person list error",
      },
    });
  }

  useEffect(() => {
    if (!isPending) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [isPending, setLoading]);

  const refreshData = async () => {
    try {
      setLoading(true);
      await triggerHydration();
      navigate("/");
    } catch (e) {
      setLoading(false);
      if (e instanceof GeneralErrorResponse) {
        updateAlertState({
          show: true,
          data: {
            body: e.message,
            color: ALERT_STATE.error,
            title: "Refresh data error",
          },
        });
      }
    }
  };

  const handleCreateEmployee = async () => navigate("employee_form");
  const handleCreateCandidate = async () => navigate("add_candidate");
  const handleRefreshData = async () => refreshData();

  return {
    alertState,
    data,
    handleCreateEmployee,
    handleRefreshData,
    handleCreateCandidate,
  };
};
