import { useNavigate } from "react-router-dom";
import { Button, Checkbox } from "@mui/material";
import { IDynamicViewTagProps } from "src/v2/models/dynamicViewTag.model";
import { PEOPLE_EMPLOYEE_PROFILE_URL } from "src/v2/config/constants";
import { dateToColumnLabelPresenter } from "src/v2/adapters/table.adapter";
import Detail from "src/v2/components/Typography/Detail";
import BasicSelect from "../Select/Select";
import { Sidebar } from "../Sidebar";

export const detailViewTagComponent = ({
  value,
  frozen,
}: IDynamicViewTagProps) => {
  if (typeof value === "object" && value !== null) {
    const keys = Object.keys(value);
    const values = Object.values(value);
    return (
      <div>
        {keys.map((key, index) => (
          <div key={key}>
            <Detail bold={frozen}>{(values as any)[index]}</Detail>
          </div>
        ))}
      </div>
    );
  }
  return <Detail bold={frozen}>{value}</Detail>;
};

export const checkboxViewTagComponent = () => {
  return <Checkbox />;
};

export const dateViewTagComponent = ({ value }: IDynamicViewTagProps) => {
  return <span>{dateToColumnLabelPresenter(value)}</span>;
};

export const arrayViewTagComponent = ({ value }: IDynamicViewTagProps) => (
  <BasicSelect values={value} />
);

export const objectArrayViewTagComponent = (props: IDynamicViewTagProps) => (
  <Sidebar anchorPosition={["right"]} propsColumnsDependency={props} />
);

export function ActionViewTagComponent({ value }: IDynamicViewTagProps) {
  const navigate = useNavigate();
  return (
    <Button // TODO: WRAP BUTTON IN AN ATOM
      variant='text'
      color='secondary'
      onClick={() => {
        navigate(`${PEOPLE_EMPLOYEE_PROFILE_URL}${value}`, {
          state: value,
        });
      }}
      tabIndex={0}
    />
  );
}
