import { useContext } from "react";
import { DynamicViewTagContext } from "../contexts";

const useDynamicViewTagContext = () => {
  const context = useContext(DynamicViewTagContext);
  if (!context) {
    throw new Error(
      "useDynamicViewTagContext must be used within a dynamicViewTagContextProvider"
    );
  }
  return context;
};

export default useDynamicViewTagContext;
