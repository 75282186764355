import { useFormContext, Controller } from "react-hook-form";
import Select, { MultiValue, SingleValue, ActionMeta } from "react-select";
import { IOptions } from "../../../pages/FormEmployee/IEmployee";
import { ISelectInput } from "../IInputs";
import { selectStyle } from "../InputStyleComponents";

interface ISelectWrapper {
  children: React.ReactNode;
  dataTestId?: string;
}

function SelectWrapper({ children, dataTestId }: ISelectWrapper) {
  return <div data-testid={dataTestId}>{children}</div>;
}

export function SelectInput({
  id,
  label,
  isMulti,
  options,
  disabled,
  dataTestId,
  onFocusSelect,
}: ISelectInput) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value } }) => (
        <SelectWrapper dataTestId={dataTestId}>
          <Select
            styles={selectStyle}
            menuPortalTarget={document.body}
            value={options.find((c) => c.value === value)}
            onChange={(
              newValue:
                | MultiValue<IOptions | MultiValue<IOptions>>
                | SingleValue<IOptions | MultiValue<IOptions>>,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              _actionMeta: ActionMeta<IOptions>
            ) => {
              onChange(newValue, _actionMeta);
              onFocusSelect();
            }}
            placeholder={`Insert ${label}`}
            options={options}
            isMulti={isMulti}
            isDisabled={disabled}
            menuPlacement='auto'
          />
        </SelectWrapper>
      )}
    />
  );
}
