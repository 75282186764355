import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ReviewForm } from "src/v2/components/molecules/ReviewForm";
import { ReviewPerson } from "src/v2/components/molecules/ReviewPerson";
import { IReviewItemProps } from "./types";

function ReviewItem({
  review,
  setAlertInformation,
  handlerCloseNewReview,
  setSavedData,
  currentValues,
  nextReviewDate,
}: IReviewItemProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(!review);
  const handleAccordionOpen = () => {
    setIsAccordionOpen(true);
  };
  const handleAccordionClose = () => {
    setIsAccordionOpen(false);
    if (isAccordionOpen && handlerCloseNewReview) {
      handlerCloseNewReview();
    }
  };
  const dayjsDate = review ? dayjs(review.reviewDate) : dayjs(new Date());
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isAccordionOpen) {
        e.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isAccordionOpen]);
  return (
    <ReviewPerson
      reviewDate={
        nextReviewDate?.format("MMMM").toString() ??
        dayjsDate.startOf("month").format("MMMM")
      }
      status={review?.status}
      isAccordionOpen={isAccordionOpen}
      handleAccordionOpen={handleAccordionOpen}
    >
      {isAccordionOpen && (
        <ReviewForm
          currentValues={currentValues}
          handleAccordionClose={handleAccordionClose}
          reviewData={review}
          setAlertInformation={setAlertInformation}
          setSavedData={setSavedData}
          nextReviewDate={nextReviewDate}
        />
      )}
    </ReviewPerson>
  );
}
export default ReviewItem;
