import Typography from "src/v2/components/atoms/Typography/Typography";
import { IPersonHeaderData } from "./types";

export default function PersonHeaderData({ name, jobData }: IPersonHeaderData) {
  return (
    <div>
      <Typography variant='h3' color='common.white' bold>
        {name}
      </Typography>
      <Typography variant='h6' color='common.white'>
        {jobData}
      </Typography>
    </div>
  );
}
