/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormContext } from "react-hook-form";
import { GeneralInputInput } from "src/components/Inputs/InputStyleComponents";
import {
  FormInputsSectionContainer,
  SingleInputContainer,
  DoubleInputContainer,
} from "src/components/forms/FormComponents";
import { Education } from "src/domain/model/Employee";
import { SelectInput } from "src/components/Inputs/SelectInput/SelectInput";
import { DEGREE_CHOICES } from "src/components/forms/PersonalInfoForm/utils/InputOptions";
import { getDateToString } from "src/v2/utils/parsers";

interface IEducationRegistryProps {
  education: Education;
  index: number;
  onFocusInput: () => void;
  key: string;
}

export function EducationRegistry({
  education,
  index,
  onFocusInput,
}: IEducationRegistryProps) {
  const { register } = useFormContext();
  return (
    <FormInputsSectionContainer>
      <div className='w-full lg:w-full'>
        <SingleInputContainer>
          <label htmlFor='institution' className='text-gray-500'>
            College/Institution
          </label>
          <GeneralInputInput
            {...register(`items.${index}.institution`)}
            defaultValue={education.institution ?? ""}
            id={`institution-${index}`}
            data-testid={`institution-input-${index}`}
            type='text'
            onClick={onFocusInput}
          />
        </SingleInputContainer>

        <DoubleInputContainer>
          <div className='flex flex-col w-full'>
            <label htmlFor='degree' className='text-gray-500'>
              Degree
            </label>
            <SelectInput
              id={`items.${index}.degree`}
              label='degree'
              isMulti={false}
              options={DEGREE_CHOICES}
              disabled={false}
              dataTestId={`degree-input-${index}`}
              defaultValue={
                education.degree
                  ? {
                    label: education.degree ?? "",
                    value: education.degree ?? "",
                  }
                  : undefined
              }
              onFocusSelect={onFocusInput}
            />
          </div>
          <div className='flex flex-col w-full'>
            <label htmlFor='major' className='text-gray-500'>
              Major/Specialization
            </label>
            <GeneralInputInput
              id={`major-${index}`}
              data-testid={`major-input-${index}`}
              type='text'
              className='max-w-xl'
              {...register(`items.${index}.specialization`)}
              defaultValue={education.specialization ?? ""}
              onClick={onFocusInput}
            />
          </div>
        </DoubleInputContainer>

        <DoubleInputContainer>
          <div className='flex flex-col w-full'>
            <label htmlFor='start_date' className='text-gray-500'>
              Start date
            </label>
            <GeneralInputInput
              {...register(`${index}.start_date`)}
              defaultValue={getDateToString(education?.start_date)}
              id={`start_date-${index}`}
              data-testid={`start-date-input-${index}`}
              type='date'
              onClick={onFocusInput}
            />
          </div>
          <div className='flex flex-col w-full'>
            <label htmlFor='end_date' className='text-gray-500'>
              End date
            </label>
            <GeneralInputInput
              {...register(`${index}.end_date`)}
              defaultValue={getDateToString(education?.end_date)}
              className='max-w-xl'
              type='date'
              data-testid={`end-date-input-${index}`}
              id={`end_date-${index}`}
              onClick={onFocusInput}
            />
          </div>
        </DoubleInputContainer>
      </div>
    </FormInputsSectionContainer>
  );
}
