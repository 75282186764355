/**
 * Flattens a list of React Providers in a recursive way
 *
 */
const ProviderTree = (providers: any[]): any => {
  if (providers.length === 1) {
    return providers[0][0];
  }
  const [A, paramsA] = providers.shift();
  const [B, paramsB] = providers.shift();

  return ProviderTree([
    [
      ({ children }: { children: React.ReactNode }) => (
        <A {...(paramsA || {})}>
          <B {...(paramsB || {})}>{children}</B>
        </A>
      ),
    ],
    ...providers,
  ]);
};

export default ProviderTree;
