import { useCallback, useState } from "react";

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    setIsCopied(false);
    if (error) setError(null);
  };

  const copyToClipboard = useCallback(
    async (text: string): Promise<boolean> => {
      if (!navigator?.clipboard) {
        setError("Clipboard not supported");
        return false;
      }

      try {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        setError(null);
        return true;
      } catch (err: any) {
        setError(err.message);
        return false;
      }
    },
    []
  );

  return { handleClose, isCopied, error, copyToClipboard };
};

export default useCopyToClipboard;
