import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface CalendarProps {
  isDisabled?: boolean;
  dateValue?: string | null;
  children?: React.ReactNode;
  id: string;
}

const CalendarTransform = memo(({ dateValue, id }: CalendarProps) => {
  const getDateValues = dateValue ? dayjs(dateValue, "DD-MM-YYYY") : null;
  const { control } = useFormContext();
  const handleDatePickerChange = (
    onChange: (value: unknown) => void,
    date: Dayjs | null
  ) => {
    const dateFormatted = date?.format("DD-MM-YYYY").toString();
    onChange(dateFormatted);
  };

  return (
    <Controller
      name={id}
      data-testid='calendar-datepicker'
      control={control}
      render={({ field: { onChange } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className='w-full max-w-sm z-0'
            format='DD-MM-YYYY'
            defaultValue={getDateValues}
            label='Select a Date'
            onChange={(date: Dayjs | null) =>
              handleDatePickerChange(onChange, date)
            }
          />
        </LocalizationProvider>
      )}
    />
  );
});

export default CalendarTransform;
