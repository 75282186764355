import React, { useEffect, useState } from "react";
import Loading from "src/components/Loading/Loading";
import { DropdownOptions } from "src/v2/components/molecules/DropdownOptions/DropdownOptions";
import { transformDataToTableData } from "../adapters/table.adapter";
import DynamicForm from "../components/organisms/DynamicForm/DynamicForm";
import { PropertyInput } from "../components/Input/PropertyInput/PropertyInput";
import BasicModal from "../components/molecules/Modal/BasicModal";
import { Table } from "../components/organisms/Table/Table";
import useColumns from "../hooks/useColumns";
import useDynamicViewTagContext from "../hooks/useDynamicViewModelContext";
import { useSidebar } from "../hooks/useSidebar";
import { Section, SectionsMap } from "./IListPeoplePage";
import {
  columnsHiddenByDefault,
  DataDefinitionSchemaFor,
} from "../services/dataDefinitionSchema.service";
import { useGetAllPeopleData } from "../hooks/useFetchs";

export function ListPeoplePage() {
  const { initialize } = useDynamicViewTagContext();
  const [modalSections, setModalSections] = useState<JSX.Element[]>([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const properties = { displayCheckbox: true, hidePagination: false };
  const { setShowSidebar } = useSidebar();
  const columns = useColumns();

  const { data: RawPeopleData, isPending: isFetchingPeopleData } =
    useGetAllPeopleData();

  const peopleTableData = RawPeopleData
    ? transformDataToTableData(RawPeopleData, DataDefinitionSchemaFor)
    : null;

  useEffect(() => {
    if (!peopleTableData) {
      const initializeData = async () => {
        await initialize("table_page_id");
      };
      initializeData();
    }
    setShowSidebar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sections: SectionsMap = {
    options: <DropdownOptions />,
    propertyName: <PropertyInput />,
    profileLocation: <div>Some one</div>,
    propertyDisplay: <div>Disabled</div>,
  };

  // eslint-disable-next-line no-unused-vars,  @typescript-eslint/no-unused-vars
  const handleClick = (title: string, sectionNames: Section[] = []) => {
    const mappedSections = sectionNames.map((sectionName, index) =>
      sectionName === "propertyName" ? (
        <div key={index}>
          {React.cloneElement(sections[sectionName], { label: title })}
        </div>
      ) : (
        <div key={index}>{sections[sectionName]}</div>
      )
    );
    setModalSections(mappedSections);
    handleOpen();
  };

  const handleSubmit = () => {
    // TODO: Implement handleSubmit function
    return true;
  };

  return (
    <div style={{ width: "100%" }}>
      {!peopleTableData || !columns ? (
        <Loading />
      ) : (
        <div>
          <BasicModal open={open} handleClose={handleClose}>
            <DynamicForm handleSubmit={handleSubmit}>
              {modalSections}
            </DynamicForm>
          </BasicModal>
          <Table
            isLoading={isFetchingPeopleData}
            columns={columns}
            rowData={peopleTableData}
            displayCheckbox={properties.displayCheckbox}
            hidePagination={properties.hidePagination}
            pageSize={[10, 25, 50, 100]}
            toolBoxComponents={[]}
            sxTableContainer={{
              maxWidth: "calc(100vw - 140px)",
              width: "fit-content",
              margin: "auto",
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
              columns: {
                columnVisibilityModel: columnsHiddenByDefault,
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
