import tw from "twin.macro";

export const MainContainer = tw.button`
w-full
flex
flex-row
flex-row
text-start
hover:bg-gray-300
pt-2
pb-2
`;
export const TitleContainer = tw.div`
text-2xl
tracking-wider
lg:basis-1/2
flex 
h-20
items-center 
justify-center
`;
export const Wrap = tw.div`
w-full
h-20
md:basis-1/3
lg:basis-1/6
flex 
items-center 
justify-center
text-2xl

`;
export const Arrow = tw.span`
text-ioet-orange
duration-200
ease-linear
border-t-4 border-l-4
border-ioet-orange
border-solid 
`;
export const ArrowLine = tw.span`
absolute 
top-2.5 
w-3 
h-0.5 
bg-gray-300 
inline-block 

`;
export const ImgCandidate = tw.img`
h-20
w-20
rounded-full
overflow-hidden
object-cover
`;
