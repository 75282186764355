import { TruckIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useState } from "react";
import {
  FormAddSectionContainer,
  FormViewContainer,
  FormTitleSectionContainer,
  FormSectionContainer,
} from "../FormComponents";
import { EmergencyFormProps } from "./EmergencyFormProps";
import { BottomSheet } from "../../BottomSheet/BottomSheet";
import { EmergencyContact } from "../../../domain/model/Employee";
import { EmergencyRegistry } from "./EmergencyRegistry/EmergencyRegistry";

const DEFAULT_EMERGENCY_CONTACT: EmergencyContact = {
  name: "",
  relationship: "",
  primary_contact: false,
  work_phone: "",
  work_phone_extension: "",
  mobile_phone: "",
  home_phone: "",
  email: "",
  address: "",
  city: "",
  province: "",
  zip_code: "",
  country: "",
};

interface IEmergencyFormValues {
  items: EmergencyContact[];
}

export function EmergencyForm({ emergencyContacts }: EmergencyFormProps) {
  const [focusInput, setFocusInput] = useState(false);

  const methods = useForm<IEmergencyFormValues>({
    defaultValues: {
      items: emergencyContacts
        ? emergencyContacts.map((emergencyContact) =>
          Object.create(emergencyContact)
        )
        : [DEFAULT_EMERGENCY_CONTACT],
    },
  });

  const {
    control,
    reset,
    formState: { isDirty },
  } = methods;

  const { fields, append } = useFieldArray({
    control,
    name: "items",
  });

  const resetAllFields = () => {
    reset({ items: emergencyContacts ?? [DEFAULT_EMERGENCY_CONTACT] });
    setFocusInput(false);
  };

  return (
    <FormProvider {...methods}>
      <FormViewContainer data-testid='employee-emergency-form'>
        <FormSectionContainer>
          <FormTitleSectionContainer>
            <TruckIcon height={30} color='#FC5630' />
            <h1 className='text-ioet-orange text-3xl font-bold'>
              Emergency Contact
            </h1>
          </FormTitleSectionContainer>
          {fields.map((emergencyContact, index) => (
            <EmergencyRegistry
              emergencyContact={{ ...emergencyContact }}
              key={emergencyContact.id}
              index={index}
              onFocusInput={() => setFocusInput(true)}
            />
          ))}
          <FormAddSectionContainer
            onClick={() => append(DEFAULT_EMERGENCY_CONTACT)}
            data-testid='employee-emergency-add-contact-button'
          >
            <PlusCircleIcon height={30} />
            <h3 className=' font-bold'>Add Contact</h3>
          </FormAddSectionContainer>
        </FormSectionContainer>
      </FormViewContainer>
      {(isDirty || focusInput) && (
        // eslint-disable-next-line no-console
        <BottomSheet handleOnCancel={resetAllFields} handleOnSave={() => {}} />
      )}
    </FormProvider>
  );
}
