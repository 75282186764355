import tw from "twin.macro";

export const UserInformationContainer = tw.div`
    flex
    flex-col
    items-start
    rounded
    mb-2
    `;

export const UserInformationTextContainer = tw.div`
    flex
    lg:flex
    text-gray-700
    `;

export const UserInformationTextElements = tw.div`
    font-semibold
    `;

export const UserInformationImage = tw.img`
    hover:scale-110
    transition 
    duration-300 
    ease-in-out
    w-16
    h-16
    rounded-full
    `;

export const UserInformationTextRow = tw.div`
    flex
    mt-2
    `;

export const UserPopoverContent = tw.div`
  bg-white
  p-6
  shadow-md
  rounded
  text-[#6e6e6e]
`;
