import dayjs from "dayjs";

import DialogContent from "@mui/material/DialogContent";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "src/v2/components/atoms/TextField";
import { DIALOG_BACKGROUND_COLOR } from "src/v2/constants/colors";

import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { modalStyle } from "src/constants/personProfile";
import DatePicker from "src/v2/components/atoms/DatePicker/DatePicker";
import DialogModal from "src/v2/components/organisms/Dialog/Dialog";
import { useCreateOnSubmitNotes } from "src/v2/hooks/useCreateOnSubmit";
import { INote } from "src/v2/models";
import { NoteFormProps } from "./types";

function NoteForm({
  noteBeingEdited,
  user,
  noteSection,
  setAlertInformation,
  handleToggle,
  setSavedData,
  actionButton,
  actionTitle,
  openDialog,
}: NoteFormProps) {
  const { personId } = useParams();
  const defaultValues = useMemo<INote>(() => {
    return {
      id: "",
      date: dayjs(),
      author: user ?? "",
      category: "",
      note: "",
      section: noteSection,
    };
  }, [user, noteSection]);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<INote>({
    shouldUnregister: true,
    defaultValues: noteBeingEdited ?? defaultValues,
  });
  const { mutation: onSubmit, isPending } = useCreateOnSubmitNotes({
    onSuccess: () => {
      setAlertInformation({
        severity: "success",
        message: "Data saved correctly",
      });
      setSavedData(true);
    },
    onError: (error) => {
      setAlertInformation({
        severity: "error",
        message: `Error: ${error.message}`,
      });
      setSavedData(true);
    },
    onSettled: () => {
      handleToggle();
      reset();
    },
    personId,
    newNotes: !noteBeingEdited,
  });
  const handleSubmitForm = handleSubmit(async (formData) => {
    await onSubmit({
      ...formData,
      section: noteSection,
      id: noteBeingEdited?.id,
    });
  });
  useEffect(() => {
    reset(noteBeingEdited ?? defaultValues);
  }, [defaultValues, noteBeingEdited, reset]);
  return (
    <DialogModal
      open={openDialog}
      isSaving={isPending || isSubmitting}
      handleCloseAndSave={handleSubmitForm}
      title={actionTitle}
      buttonTitle={actionButton}
      handleExit={handleToggle}
      isDirty={isDirty}
      form={
        <DialogContent sx={{ background: DIALOG_BACKGROUND_COLOR }}>
          <div style={modalStyle}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    label='Date'
                    value={dayjs(value)}
                    minDate={dayjs(value).add(-5, "year")}
                    maxDate={dayjs(value)}
                    sx={{ width: "20%" }}
                    format='DD-MM-YYYY'
                    onChange={onChange}
                    disableFuture={!false}
                  />
                );
              }}
              name='date'
            />
          </div>
          <div style={modalStyle}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { value } }) => (
                <TextField
                  id='authorId'
                  variant='outlined'
                  label='Author'
                  size='medium'
                  value={value}
                  read={false}
                  sx={{ width: { md: "100%" } }}
                  disabled
                />
              )}
              name='author'
            />
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id='categoryId'
                  variant='outlined'
                  label={`Category ${noteSection}`}
                  helperText={errors.category ? "Category is required" : ""}
                  value={value}
                  size='medium'
                  read={false}
                  sx={{ width: { md: "100%" } }}
                  onChange={onChange}
                />
              )}
              name='category'
            />
          </div>
          <div style={modalStyle}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id='notesId'
                  variant='outlined'
                  label='Notes'
                  helperText={errors.note ? "Note is required" : ""}
                  size='medium'
                  read={false}
                  value={value}
                  multiline
                  maxRows={5}
                  sx={{ width: { md: "100%" } }}
                  onChange={onChange}
                />
              )}
              name='note'
            />
          </div>
        </DialogContent>
      }
    />
  );
}

export default NoteForm;
