import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { getTimeOnJob } from "src/v2/utils/getTimeOnJob";
import dayjs from "dayjs";
import { IIconProps, Tooltip } from "src/v2/components/atoms";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GitHubIcon from "@mui/icons-material/GitHub";
import Typography from "src/v2/components/atoms/Typography/Typography";
import { CopyToClipboard } from "src/v2/components/molecules";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import IconGroup from "src/v2/components/molecules/IconGroup/IconGroup";
import {
  SNACKBAR_BACKGROUND_COLOR,
  SNACKBAR_COLOR,
} from "src/v2/constants/colors";
import { Dict } from "src/v2/models";
import { capitalizeFirstLetter } from "src/v2/utils/parsers";

export interface ISideBarProfileProps {
  personInformation: Dict;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}

export function SideBarProfile(props: ISideBarProfileProps) {
  const { personInformation: personData, setOpen, open } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const columnGap = "5%";
  const socialMediaIcons: { [key: string]: IIconProps } = {
    facebook: { icon: <FacebookOutlinedIcon fontSize='large' /> },
    githubId: { icon: <GitHubIcon fontSize='large' /> },
    linkedIn: { icon: "linkedin", size: 27 },
    twitterFeed: { icon: "twitter", size: 35 },
  };
  const socialMedia: { key: string; url: string }[] = [
    { key: "facebook", url: personData.facebook },
    {
      key: "githubId",
      url: personData.githubId
        ? `https://github.com/${personData.githubId}`
        : undefined,
    },
    { key: "linkedIn", url: personData.linkedIn },
    { key: "twitterFeed", url: personData.twitterFeed },
  ];
  const groupIcons: (IIconProps & { url: string })[] = socialMedia
    .filter((item) => {
      return !!item.url;
    })
    .map((icon) => {
      return { ...socialMediaIcons[icon.key], url: icon.url };
    });
  const content = (
    <Stack
      component='aside'
      spacing={1.5}
      divider={
        <hr
          style={{
            backgroundColor: SNACKBAR_BACKGROUND_COLOR,
            height: 5,
          }}
        />
      }
      data-testid='side-bar-profile-container'
      sx={{
        p: 3,
        height: "100%",
        width: { xs: 300, lg: "auto" },
        backgroundColor: SNACKBAR_COLOR,
      }}
    >
      <Box
        data-testid='ioet-id-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
      >
        <Typography
          variant='body1'
          sx={{ fontWeight: "bold", color: SNACKBAR_BACKGROUND_COLOR }}
        >
          ioet Id
        </Typography>
        <CopyToClipboard text={personData.ioetId ?? "Not found"}>
          <Tooltip title='Copy to clipboard' arrow>
            <Typography variant='body1'>
              {personData.ioetId ?? "Not found"}
            </Typography>
          </Tooltip>
        </CopyToClipboard>
      </Box>
      <Box
        data-testid='staff-id-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
      >
        <Typography
          variant='body1'
          sx={{ fontWeight: "bold", color: SNACKBAR_BACKGROUND_COLOR }}
        >
          Staffing status
        </Typography>
        <Typography variant='body1'>
          {personData.status?.name
            ? capitalizeFirstLetter(personData.status?.name?.slice(-1)[0])
            : "Not found"}
        </Typography>
      </Box>
      <Box
        data-testid='hire-date-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
      >
        <Typography variant='body1' sx={{ fontWeight: "bold" }}>
          Hire Date
        </Typography>
        <Typography variant='body1'>
          {dayjs(personData.startDate).format("DD-MM-YYYY")}
        </Typography>
        <Typography variant='body1' sx={{ fontWeight: "bold" }}>
          Time at ioet
        </Typography>
        <Typography variant='body1'>
          {getTimeOnJob(personData.startDate, new Date())}
        </Typography>
      </Box>

      <Box
        data-testid='location-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}
      >
        <Typography
          variant='body1'
          sx={{ fontWeight: "bold", color: SNACKBAR_BACKGROUND_COLOR }}
        >
          Contact
        </Typography>
        <DataIcon
          iconName={{ icon: <EmailIcon /> }}
          text={{
            children: (
              <CopyToClipboard text={personData.ioetEmail}>
                {personData.ioetEmail}
              </CopyToClipboard>
            ),
            variant: "body3",
          }}
          columnGap={columnGap}
        />
        <DataIcon
          iconName={{ icon: "phone" }}
          text={{
            children: (
              <CopyToClipboard text={personData.mobilePhone}>
                {personData.mobilePhone}
              </CopyToClipboard>
            ),
            variant: "body3",
          }}
          columnGap={columnGap}
        />
        <DataIcon
          iconName={{ icon: <LocationOnIcon /> }}
          text={{ children: personData.country, variant: "body3" }}
          columnGap={columnGap}
        />
        <IconGroup icons={groupIcons} />
      </Box>
    </Stack>
  );

  return isMobile ? (
    <Drawer open={open} onClose={() => setOpen?.(!open)}>
      {content}
    </Drawer>
  ) : (
    content
  );
}
