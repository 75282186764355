import { Typography } from "@mui/material";
import List from "@mui/material/List";
import { UserNotification } from "src/v2/models";
import { Tooltip, UserTimeNotification } from "src/v2/components/atoms";

function ReviewListNotification({
  userNotifications,
}: {
  userNotifications: UserNotification[];
}) {
  return (
    <List role='list'>
      {userNotifications.length > 0 ? (
        <>
          {userNotifications.map((notification, index) => (
            <div key={`${notification.id.toString() + index}`}>
              <Tooltip title='Click to show pending reviews' arrow>
                <UserTimeNotification notification={notification} />
              </Tooltip>
            </div>
          ))}
        </>
      ) : (
        <Typography
          textAlign='center'
          variant='body1'
          sx={{ p: 2 }}
          title='resumeText'
        >
          No new notifications
        </Typography>
      )}
    </List>
  );
}
export default ReviewListNotification;
