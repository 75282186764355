import { Box } from "@mui/system";
import {
  COUNTRY_OPTIONS,
  DEFAULT_NO_SELECTED_OPTION,
} from "src/components/forms/PersonalInfoForm/utils/InputOptions";
import Select from "src/v2/components/atoms/Select/Select";
import TextField from "src/v2/components/atoms/TextField/TextField";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";
import { buildSelectOptions } from "src/v2/utils/filterUtils";

export function EmergencyContactSection(personInformation: Dict) {
  const { personInformation: personEmergencyContactData } = personInformation;
  const dataIconColumnGap = "1%";

  return (
    <Box
      data-testid='emergency-contact-container'
      sx={{
        width: { lg: "750px", md: "100%" },
        marginBottom: "3%",
      }}
    >
      <div style={{ paddingTop: "2%", paddingBottom: "3%" }}>
        <DataIcon
          iconName={{ icon: "emergencyContact" }}
          text={{
            children: "Emergency Contact",
            variant: "h6",
            sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
          }}
          columnGap={dataIconColumnGap}
        />
      </div>
      {personEmergencyContactData?.map((item: Dict, index: number) => (
        <div key={`emergencyConctactData-${index}`}>
          <Box
            sx={{
              width: { md: "65%" },
              marginBottom: { md: "1%", sm: "2%" },
              columnGap: "5%",
              display: { md: "flex", sm: "block" },
            }}
            data-testid='emergency-contact-container-nameRelation'
          >
            <TextField
              disabled
              id='nameEmergencyId'
              variant='outlined'
              label='Name'
              value={item.name ?? ""}
              size='small'
              onChange={() => {}}
              sx={{
                marginBottom: { sm: "2%" },
              }}
              className='BambooClass'
            />
            <Box
              sx={{
                width: { md: "40%" },
              }}
            >
              <Select
                label='Relation'
                value={item.relationship}
                disabled
                options={[
                  { label: "Family", value: "Family" },
                  { label: "Friend", value: "Friend" },
                  { label: "Other", value: "Other" },
                  { label: "Spouse", value: "Spouse" },
                ]}
                size='small'
                fullWidth
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Box>
          </Box>
          <div
            style={{ marginBottom: "2%" }}
            data-testid='emergency-contact-container-phoneEmergency'
          >
            <TextField
              disabled
              id='phoneEmergencyId'
              variant='outlined'
              label='Mobile phone'
              value={item.mobilePhone ?? ""}
              size='small'
              sx={{ width: { md: "40%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
          <div
            style={{ marginBottom: "2%" }}
            data-testid='emergency-contact-container-phoneEmergency2'
          >
            <TextField
              disabled
              id='secondPhoneEmergencyId'
              variant='outlined'
              label='2nd Phone number'
              value={item.homePhone ?? ""}
              size='small'
              sx={{ width: { md: "40%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
          <div
            style={{ marginBottom: "2%" }}
            data-testid='emergency-contact-container-email'
          >
            <TextField
              disabled
              id='emailEmergencyId'
              variant='outlined'
              label='email'
              value={item.email ?? ""}
              size='small'
              sx={{ width: { md: "80%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
          <div
            style={{ marginBottom: "2%" }}
            data-testid='emergency-contact-container-address'
          >
            <TextField
              disabled
              id='addressEmergencyId'
              variant='outlined'
              label='Address'
              value={item.address1 ?? ""}
              multiline
              onChange={() => {}}
              size='small'
              className='BambooClass'
            />
          </div>
          <Box
            sx={{
              width: { md: "100%" },
              marginBottom: "2%",
              columnGap: "4%",
              display: { md: "flex", sm: "block", xs: "block" },
            }}
            data-testid='emergency-contact-container-country'
          >
            <Box
              sx={{
                width: { md: "33%" },
                marginBottom: { sm: "2%" },
              }}
            >
              <Select
                label='Country'
                value={item.country ?? DEFAULT_NO_SELECTED_OPTION}
                disabled
                options={buildSelectOptions(item.country, COUNTRY_OPTIONS)}
                size='small'
                fullWidth
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Box>
            <TextField
              disabled
              id='cityEmergencyId'
              variant='outlined'
              label='City'
              value={item.city ?? ""}
              size='small'
              sx={{
                width: { md: "33%" },
                marginBottom: { sm: "2%" },
              }}
              onChange={() => {}}
              className='BambooClass'
            />
            <TextField
              disabled
              id='zipCodeEmergencyId'
              variant='outlined'
              label='ZIP code'
              value={item.zipCode ?? ""}
              size='small'
              sx={{
                width: { md: "33%" },
                marginBottom: { sm: "2%" },
              }}
              onChange={() => {}}
              className='BambooClass'
            />
          </Box>
        </div>
      ))}
    </Box>
  );
}
