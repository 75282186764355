import { Dialog, DialogActions, DialogTitle, Grid } from "@mui/material";
import { Button } from "src/v2/components/atoms/Button";
import {
  DIALOG_BACKGROUND_COLOR,
  PRIMARY_COLOR,
} from "src/v2/constants/colors";
import { IConfirmDialog } from "./types";

function ConfirmDialog({
  confirmType,
  dialogTitle,
  dialogText,
  openCancelButton,
  handleStay,
  handleLeave,
}: IConfirmDialog) {
  return (
    <Dialog
      data-testid='dialog-confirmation'
      open={openCancelButton}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-confirmation'
      fullWidth
      maxWidth='xs'
    >
      <div style={{ backgroundColor: DIALOG_BACKGROUND_COLOR }}>
        <DialogTitle
          id='alert-dialog-title'
          bgcolor={PRIMARY_COLOR}
          color='white'
        >
          {dialogTitle}
        </DialogTitle>
        {dialogText !== undefined && (
          <Grid
            alignSelf='center'
            justifySelf='center'
            padding='1.5rem 1rem 1rem 2rem'
          >
            {dialogText}
          </Grid>
        )}
        <DialogActions
          sx={{
            height: "120px",
            display: "flex",
            justifyContent: "flex-end",
            padding: "5%",
            gap: "10px",
          }}
        >
          <Button
            onClick={handleLeave}
            variant='contained'
            color='inherit'
            text='Leave'
          />
          {confirmType ? (
            <Button
              onClick={handleStay}
              variant='contained'
              color='primary'
              text='Got it'
            />
          ) : (
            <Button
              onClick={handleStay}
              variant='contained'
              color='primary'
              text='Stay'
            />
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;
