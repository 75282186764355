import { Dict } from "src/v2/models";
import { ReviewRecord } from "./ReviewRecord/ReviewRecord";

interface ICareerTabProps {
  personInformation: Dict;
  key: string;
}

export function CareerTab({ personInformation }: ICareerTabProps) {
  const compensationInformation: any[] = personInformation.compensation ?? [];
  const lastEffectiveDateIndex =
    compensationInformation.length === 0
      ? 0
      : compensationInformation.length - 1;
  return (
    <div
      style={{ paddingTop: "2%", paddingLeft: "5%" }}
      data-testid='Career-tab-container'
    >
      <ReviewRecord
        lastEffectiveDate={
          compensationInformation[lastEffectiveDateIndex]?.effective_date
        }
        currentValues={{
          currentSalary:
            compensationInformation[lastEffectiveDateIndex]?.payRate ?? 0,
          currentJobTitle: personInformation?.jobTitle ?? "Job Title Not Found",
        }}
      />
    </div>
  );
}
