/* eslint-disable react/function-component-definition */
import { ChevronLeft, Menu } from "@mui/icons-material";
import { IconButton, Stack, Toolbar } from "@mui/material";
import peopleLogo from "src/assets/logo_white.png";
import { SearchBar } from "src/v2/components/molecules";
import useAuth from "src/hooks/useAuth";
import { ADMIN_ROLE_NAME } from "src/v2/config/constants";
import Notification from "src/v2/components/organisms/Notifications/Notification";
import { UserInformationPopover } from "../UserInformationPopover";
import {
  LogoAndButtonsContainer,
  NavBarContainer,
  NavBarInnerContainer,
  NavBarLogoContainer,
} from "./NavBarStyleComponents";
import { INavBarProps } from "./types";

const NavBar: React.FC<INavBarProps> = ({
  open = true,
  setOpen = () => {},
}) => {
  const handleDrawer = () => {
    setOpen(!open);
  };
  const { user } = useAuth();
  return (
    <NavBarContainer data-testid='nav-bar-container'>
      <NavBarInnerContainer>
        <LogoAndButtonsContainer>
          <Toolbar variant='regular'>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawer}
              sx={{
                ...(open && { display: "none" }),
              }}
            >
              <Menu fontSize='large' sx={{ color: "white" }} />
            </IconButton>
            {open && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawer}
              >
                <ChevronLeft fontSize='large' sx={{ color: "white" }} />
              </IconButton>
            )}
          </Toolbar>
          <NavBarLogoContainer>
            <a href='/'>
              <img src={peopleLogo} alt='Logo' className='max-h-16' />
            </a>
          </NavBarLogoContainer>
        </LogoAndButtonsContainer>
        <Stack direction='row' spacing={2} alignItems='center'>
          {user?.roles.includes(ADMIN_ROLE_NAME) && <Notification />}
          <SearchBar />
          <UserInformationPopover />
        </Stack>
      </NavBarInnerContainer>
    </NavBarContainer>
  );
};

export default NavBar;
