import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/system";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import {
  CONTRACT_CHOICES,
  OFFICE_CHOICES,
  STAFFING_STATUS_CHOICES,
  EMPLOYMENT_STATUS_OPTIONS,
  WORKING_TITLES_CHOICES,
} from "src/components/forms/PersonalInfoForm/utils/InputOptions";
import { AlertColor, Container, Grid, Stack } from "@mui/material";
import {
  Button,
  ChipsInTextBox,
  CustomAlert,
  DataIcon,
  LoadingButton,
  Select,
  TextField,
} from "src/v2/components";
import { workInformationSchema } from "src/schemas/workInformationSchema";
import { IWorkInformation } from "src/v2/models";
import { Controller, useForm } from "react-hook-form";
import { useCreateOnSubmitWorkInformation } from "src/v2/hooks/useCreateOnSubmit";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { buildWorkInformationQuery } from "src/v2/adapters/workInformation.adapter";

export function WorkInformationSection({
  workInformation,
}: {
  workInformation: IWorkInformation;
}) {
  const workData: IWorkInformation = workInformation;
  const { personId } = useParams();

  const [alertInformation, setAlertInformation] = useState({
    severity: "success",
    message: "Work Information saved correctly",
  });
  const [showAlert, setShowAlert] = useState(false);
  const {
    reset,
    handleSubmit,
    getValues,
    control,
    formState: { errors, isDirty },
  } = useForm<IWorkInformation>({
    defaultValues: workData,
    resolver: yupResolver(workInformationSchema),
  });
  const { mutation: onSubmit, isPending } = useCreateOnSubmitWorkInformation({
    onSuccess: () => {
      setAlertInformation({
        severity: "success",
        message: "Data saved correctly",
      });
      setShowAlert(true);
    },
    onError: () => {
      setAlertInformation({
        severity: "error",
        message: "Error data was not saved",
      });
      setShowAlert(true);
    },
    onSettled: (_data, error) => {
      if (!error) {
        const newDefaultWorkInformationValues = getValues();
        reset(newDefaultWorkInformationValues);
      }
    },
  });
  const handleSave = handleSubmit(async (formData: IWorkInformation) => {
    await onSubmit(buildWorkInformationQuery(formData, personId!));
  });
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  return (
    <Container
      data-testid='work-information-container'
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 0,
        marginY: 3,
      }}
      maxWidth={false}
    >
      <CustomAlert
        sx={{ marginLeft: "50px" }}
        text={alertInformation.message}
        severity={alertInformation.severity as AlertColor}
        open={showAlert}
        onClose={handleCloseAlert}
      />
      <Box sx={{ paddingBottom: "4%" }}>
        <DataIcon
          iconName={{ icon: "userInformation" }}
          text={{
            children: "Work Information",
            variant: "h6",
            sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
          }}
          columnGap='1%'
        />
      </Box>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={6}
        display='flex'
        alignItems='baseline'
        columns={{ xs: 3, sm: 6, md: 8, lg: 12 }}
      >
        <Grid item xs={6} md={6}>
          <TextField
            disabled
            id='jobTitle'
            variant='outlined'
            label='Job Title'
            value={workData.jobTitle}
            size='small'
            fullWidth
            sx={{ marginBottom: "2%" }}
            className='BambooClass'
            title='Job title must be changed from Bamboo'
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <Controller
            control={control}
            name='employmentStatus'
            render={({ field: { onChange, value } }) => (
              <Select
                id='employmentStatus'
                label='Employment Status'
                value={value}
                options={EMPLOYMENT_STATUS_OPTIONS}
                size='small'
                fullWidth
                error={errors.employmentStatus?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='contractType'
            render={({ field: { onChange, value } }) => (
              <Select
                id='contractType'
                label='Contract Type'
                value={value}
                options={CONTRACT_CHOICES}
                size='small'
                sx={{ width: { md: "100%" }, marginBottom: "2%" }}
                fullWidth
                onChange={onChange}
                error={errors.contractType?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='office'
            render={({ field: { onChange, value } }) => (
              <Select
                id='office'
                label='Office'
                value={value}
                options={OFFICE_CHOICES}
                size='small'
                sx={{ width: { md: "35%" }, marginBottom: "2%" }}
                fullWidth
                onChange={onChange}
                error={errors.office?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='staffingStatus'
            render={({ field: { onChange, value } }) => (
              <ChipsInTextBox
                id='staffingStatus'
                value={value}
                availableTags={STAFFING_STATUS_CHOICES}
                label='Staffing Status'
                sx={{ width: { md: "50%" }, marginBottom: "2%" }}
                error={errors.staffingStatus?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='workingTitles'
            render={({ field: { onChange, value } }) => (
              <ChipsInTextBox
                id='workingTitles'
                value={value}
                availableTags={WORKING_TITLES_CHOICES}
                label='Working Titles'
                sx={{ width: { md: "50%" }, marginBottom: "2%" }}
                error={errors.workingTitles?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={6}
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        columns={{ xs: 3, sm: 12 }}
      >
        <Grid item sm={9} justifyContent='flex-end'>
          <Stack
            direction='row'
            marginTop={5}
            spacing={3}
            justifyContent='flex-end'
          >
            <Button
              text='Cancel'
              color='inherit'
              size='large'
              onClick={() => {
                if (isDirty) {
                  reset();
                }
              }}
              disabled={!isDirty || isPending}
              sx={{ width: "5rem", color: "#5F5C5C" }}
            />
            <LoadingButton
              variant='contained'
              color='primary'
              loading={isPending}
              onClick={handleSave}
              text='Save'
              disabled={!isDirty || isPending}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
