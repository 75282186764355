import { User } from "src/types";
import httpClient from "../config/httpClient";

export async function fetchUserData() {
  try {
    const res = await httpClient.peopleBff.get("/user_information/");
    if (res.status === 200) return res.data as User;
    return undefined;
  } catch (error) {
    return undefined;
  }
}
