import { Box, Snackbar } from "@mui/material";
import {
  DataGrid,
  GridCell,
  GridCellModes,
  GridCellModesModel,
  GridColumnHeaders,
  GridEventListener,
  GridRow,
  GridRowId,
  useGridApiRef,
} from "@mui/x-data-grid";
import React, { memo, useEffect, useState } from "react";
import { CustomSelectedCellProps } from "src/v2/components/molecules/CustomToolBar/types";
import { CustomToolBar } from "src/v2/components/molecules/CustomToolBar";
import { EmptyRows } from "src/v2/components/atoms";
import { updateOnePersonData } from "src/v2/services/peopleData.service";
import { Alert, IAlertProps } from "../../atoms/Alert";
import { generateIncrementingNumbers } from "../../../../data/utils/generators";
import {
  TableToolBoxContainer,
  TableToolBoxContainerGroup,
  defaultStyles,
} from "./TableStyleComponents";
import { TableProps } from "./types";

interface AlertProps {
  open: boolean;
  data: IAlertProps;
}

const INIT_ALERT_DATA = {
  open: false,
  data: {
    textAlert: "",
  },
};

const memoizedRow = memo(GridRow);
const memoizedCell = memo(GridCell);
const MemoizedColumnHeaders = memo(GridColumnHeaders);

export function Table<T>(props: TableProps<T>) {
  const {
    columns,
    disableColumnMenu,
    rowData,
    toolBoxComponents,
    isLoading,
    isCustomToolBar = true,
    hideFooter = false,
    hidePagination = true,
    displayCheckbox = false,
    pageSize = [25, 50, 100],
    initialState = {
      sorting: {
        sortModel: [{ field: "date", sort: "desc" }],
      },
    },
    sxTableContainer = { maxWidth: "90%", margin: "auto" },
  } = props;
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const apiRef = useGridApiRef();
  const [rowsData, setRowsData] = useState<T[]>(rowData);
  const rowsDataMemo = React.useMemo(() => rowsData, [rowsData]);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [selectedCell, setSelectedCell] =
    useState<CustomSelectedCellProps | null>(null);
  const [alertData, setAlertData] = React.useState<AlertProps>(INIT_ALERT_DATA);

  const isCellEditingMemo = React.useMemo(() => {
    return (
      Object.keys(cellModesModel).length !== 0 &&
      selectedCell &&
      cellModesModel[selectedCell.id]?.[selectedCell.field].mode ===
        GridCellModes.Edit
    );
  }, [selectedCell, cellModesModel]);

  const handleVariables = (id: GridRowId, field: string) => {
    setCellModesModel({
      [id]: {
        [field]: {
          mode: GridCellModes.Edit,
        },
      },
    });
    setSelectedCell({ id, field });
  };
  useEffect(() => {
    setRowsData(rowData);
  }, [rowData]);

  // eslint-disable-next-line no-unused-vars,  @typescript-eslint/no-unused-vars
  const handleOnCellEditStart = React.useCallback<
    GridEventListener<"cellEditStart">
  >(
    (params, event) => {
      if (isCellEditingMemo) {
        // eslint-disable-next-line no-alert
        alert(
          "There is a cell being edited, please save changes before continuing"
        );
        apiRef.current.setCellFocus(selectedCell!.id, selectedCell!.field);
        apiRef.current.setRowSelectionModel([selectedCell!.id]);
      } else {
        handleVariables(params.id, params.field);
      }
      event.defaultMuiPrevented = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCellEditingMemo]
  );

  const handleProcessRowUpdate = async (updateData: any) => {
    const { id, field } = selectedCell ?? { id: "", field: "" };
    try {
      const requestData = {
        id: updateData["id"],
        ioetId: updateData["ioetId"],
        ioetEmail: updateData["ioetEmail"],
        [field]: updateData[field],
        ...(field === "name" && { lastName: updateData["lastName"] }),
        ...(field === "lastName" && { name: updateData["name"] }),
      };
      await updateOnePersonData(requestData);
      setAlertData({
        ...alertData,
        open: true,
        data: {
          textAlertTitle: "Success",
          textAlert: "Data was successfully updated.",
          severity: "success",
        },
      });
    } catch (error) {
      apiRef.current.setCellFocus(id, field);
      apiRef.current.setRowSelectionModel([id]);
      handleVariables(id, field);
      setAlertData({
        ...alertData,
        open: true,
        data: {
          textAlertTitle: "Failure",
          textAlert: "Failure to update data.",
          severity: "error",
        },
      });
    }
    return updateData;
  };

  const handleCloseAlert = () => {
    setAlertData({ ...alertData, open: false });
  };

  const handleBeforeUnload = React.useCallback(
    (event: any) => {
      if (isCellEditingMemo) {
        event.returnValue = "Are you sure you want to leave?";
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cellModesModel]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
  const toolbar = () => {
    if (isCustomToolBar) {
      return CustomToolBar;
    }
    return undefined;
  };

  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert {...alertData.data} onClose={handleCloseAlert} />
      </Snackbar>

      <TableToolBoxContainer>
        <TableToolBoxContainerGroup>
          {toolBoxComponents}
        </TableToolBoxContainerGroup>
      </TableToolBoxContainer>

      <Box
        sx={{
          ...sxTableContainer,
          height: isLoading || !(rowsDataMemo?.length > 0) ? "20em" : "auto",
        }}
      >
        <DataGrid
          loading={isLoading}
          disableColumnMenu={disableColumnMenu}
          disableRowSelectionOnClick
          sx={defaultStyles}
          apiRef={apiRef}
          processRowUpdate={handleProcessRowUpdate}
          cellModesModel={cellModesModel}
          // TODO onCellEditStart={handleOnCellEditStart} Disabled onCellEditStart because edits no longer update notion. The logic could be refactored into another service.
          {...toolBoxComponents}
          getRowHeight={() => "auto"}
          getRowId={(row) =>
            row?.id ?? generateIncrementingNumbers().next().value
          }
          rows={rowsData}
          columns={columns}
          slots={{
            toolbar: toolbar(),
            row: memoizedRow,
            cell: memoizedCell,
            columnHeaders: MemoizedColumnHeaders,
            noRowsOverlay: EmptyRows,
          }}
          checkboxSelection={displayCheckbox}
          slotProps={{
            toolbar: {
              columns,
              hiddenColumns,
              currentRows: rowData,
              filteredRows: rowsData,
              setFilterRow: setRowsData,
              setCellModesModel,
              selectedCell,
              isCellEditingMemo,
            },
          }}
          onColumnVisibilityModelChange={(newHiddenColumns) => {
            setHiddenColumns(
              Object.entries(newHiddenColumns)
                .filter(([, value]) => !value)
                .map(([key]) => key)
            );
          }}
          pageSizeOptions={pageSize}
          hideFooterPagination={hidePagination}
          hideFooter={hideFooter}
          initialState={initialState}
        />
      </Box>
    </>
  );
}
