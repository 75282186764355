import { Avatar as MuiAvatar } from "@mui/material";
import Icon from "src/v2/components/atoms/Icon/Icon";
import stringAvatar from "src/v2/utils/stringAvatar";
import Typography from "../Typography/Typography";
import { IAvatarProps } from "./types";

// TODO: Add support for images in library
function Avatar({
  alt,
  src,
  height = "auto",
  width = "auto",
  srcImage,
  name,
  fontSize,
  randomColor,
}: IAvatarProps) {
  if (name) {
    const { color, text } = stringAvatar(name);
    const sx: {
      height: { height: string | number };
      width: { width: string | number };
      bgcolor?: string;
    } = {
      height: { height },
      width: { width },
    };
    if (randomColor) {
      sx.bgcolor = color;
    }
    return (
      <MuiAvatar alt={alt} sx={sx}>
        <Typography sx={{ fontSize }}>{text}</Typography>
      </MuiAvatar>
    );
  }
  return src ? (
    <MuiAvatar alt={alt} sx={{ height: { height }, width: { width } }}>
      <Icon icon={src} />
    </MuiAvatar>
  ) : (
    <MuiAvatar
      alt={alt}
      sx={{ height: { height }, width: { width } }}
      src={srcImage}
    />
  );
}

export default Avatar;
