import { Box } from "@mui/material";
import { useContext, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import { ILoadingContextState } from "src/contexts/IContexts";
import LoadingContext from "src/contexts/LoadingStateContext";
import { joinNameLastName } from "src/utils/stringJoins";
import { Avatar } from "src/v2";
import * as comingSoonAnimation from "src/v2/assets/animations/coming_soon_animation.json";
import * as pcAnimation from "src/v2/assets/animations/pc_animation.json";
import { SideBarProfile } from "src/v2/components/organisms/SideBarProfile/sideBarProfile";
import NavTabs from "src/v2/components/organisms/NavTabs/NavTabs";
import { SNACKBAR_BACKGROUND_COLOR } from "src/v2/constants/colors";
import { usefetchPersonData } from "src/v2/hooks/useFetchs";
import { CompensationTab } from "src/v2/pages/PersonProfile/PersonProfileTabs/CompensationTab/CompensationTab";
import { pxToRem } from "src/v2/theme/typography";
import { Header } from "src/v2/components/molecules/Header/header";
import Loading from "src/v2/components/molecules/Loading/Loading";
import useAuth from "../../../hooks/useAuth";
import { CareerTab } from "./PersonProfileTabs/CareerTab/CareerTab";
import { NotesTab } from "./PersonProfileTabs/NotesTab/NotesTab";
import { PersonalTab } from "./PersonProfileTabs/PersonalTab/PersonalTab";
import { JobTab } from "./PersonProfileTabs/JobTab/JobTab";

const DEFAULT_TABS = [
  "Personal",
  "Job",
  "Career",
  "Compensation",
  "ioet University",
  "Notes",
];

export function PersonProfile() {
  const { personId, tabIndex } = useParams();
  const { loading } = useContext(LoadingContext) as ILoadingContextState;
  const { user } = useAuth();
  const { data: personInformation, isPending } = usefetchPersonData(personId!);
  const name = !isPending
    ? joinNameLastName(personInformation.name, personInformation.lastName)
    : "Person Name";
  const [open, setOpen] = useState(false);

  const pcOptions = {
    loop: true,
    autoplay: true,
    animationData: pcAnimation,
  };
  const comingSoonOptions = {
    loop: true,
    autoplay: true,
    animationData: comingSoonAnimation,
  };

  if (isPending || loading || !user) {
    return <Loading />;
  }
  return (
    <Box
      data-testid='person-profile'
      style={{
        display: "grid",
        gridTemplateColumns: "17rem minmax(0, 1fr)",
        gridTemplateRows: "8rem 3rem auto",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <Box
        data-testid='avatar-container'
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gridColumnStart: 1,
          gridRowStart: 1,
          gridRowEnd: { xs: 1, lg: 3 },
          background: SNACKBAR_BACKGROUND_COLOR,
          p: 2,
        }}
      >
        <Avatar
          alt={name}
          name={name}
          width={pxToRem(120)}
          height={pxToRem(120)}
          fontSize='3rem'
        />
      </Box>

      <Box
        sx={{
          gridColumnStart: 1,
          gridRowStart: 3,
          backgroundColor: SNACKBAR_BACKGROUND_COLOR,
          display: { xs: "none", lg: "block" },
        }}
      >
        <SideBarProfile
          data-testid='side-bar-profile-container'
          personInformation={personInformation}
          setOpen={setOpen}
          open={open}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          gridColumnStart: { xs: 1, lg: 2 },
          gridColumnEnd: 3,
          gridRowStart: 1,
          gridRowEnd: { xs: 0.5, lg: 2 },
        }}
      >
        <Header
          data-testid='header-container'
          personInformation={personInformation}
          setOpen={setOpen}
          open={open}
        />
      </Box>
      <Box
        data-testid='nav-tabs-container'
        sx={{
          width: "100%",
          gridColumnStart: { xs: 1, lg: 2 },
          gridColumnEnd: 3,
          gridRowStart: 2,
          gridRowEnd: { xs: 1, lg: 4 },
        }}
      >
        <NavTabs
          initialTab={tabIndex}
          labels={DEFAULT_TABS}
          tabs={[
            <PersonalTab
              personInformation={personInformation}
              key='personal-tab'
            />,
            <JobTab personInformation={personInformation} key='job-tab' />,
            <CareerTab
              personInformation={personInformation}
              key='career-tab'
            />,
            <CompensationTab
              personInformation={personInformation}
              key='compensation-tab'
            />,
            <Box
              key='WIP'
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              position='relative'
              marginTop={15}
            >
              <Lottie
                options={pcOptions}
                height={350}
                width={350}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  marginTop: 5,
                }}
              />
              <Lottie
                options={comingSoonOptions}
                height={350}
                width={350}
                style={{ marginTop: 175 }}
              />
            </Box>,
            <NotesTab key='notes-tab' user={user?.name} />,
          ]}
          icons={[
            { icon: "clipboard" },
            { icon: "briefcase" },
            { icon: "job" },
            { icon: "compensation" },
            { icon: "graduation" },
            { icon: "notes" },
          ]}
        />
      </Box>
    </Box>
  );
}
