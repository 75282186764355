import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { IconButton } from "src/v2/components/atoms/IconButton";
import dayjs from "dayjs";

export interface IProps {
  handleEditClick: (noteId: string) => void;
}

const styleNoteDataTable = (canEdit: boolean) => ({
  color: canEdit ? "none" : "#999",
  backgroundColor: "none",
  cursor: canEdit ? "pointer" : "not-allowed",
  opacity: canEdit ? 1 : 0.5,
  fontSize: 20,
});

const styleHoverButton = {
  "boxShadow": "none",
  ":hover": { backgroundColor: "transparent" },
  "backgroundColor": "transparent",
};

export const NotesDataTable = (
  handleEditClick: any,
  currentUser: string | undefined
) => {
  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Date",
      type: "Date",
      sortable: false,
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      field: "author",
      headerName: "Author",
      width: 175,
      sortable: false,
    },
    {
      field: "category",
      headerName: "Category",
      width: 125,
      type: "string",
      sortable: false,
    },
    {
      field: "note",
      headerName: "Notes",
      flex: 1,
      minWidth: 200,
      renderCell(e) {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              maxWidth: "100%",
              minWidth: "200px",
              maxHeight: "200px",
              overflowY: "auto",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {e.row.note}
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell(e) {
        if (e.row.author !== undefined) {
          const canEdit = e.row.author === currentUser;
          return (
            <IconButton
              icon={<EditIcon sx={styleNoteDataTable(canEdit)} />}
              title={canEdit ? "Edit" : "Only author can edit"}
              style={styleHoverButton}
              onClick={
                canEdit
                  ? () => {
                    handleEditClick(e.row.id);
                  }
                  : () => {}
              }
              tooltipPlacement='right'
              dataTest='editIcon'
            />
          );
        }
        return <Box sx={{ height: "50px" }} />;
      },
    },
  ];
  return columns;
};
export const notesDataTableClient = (
  handleEditClick: any,
  currentUser: string | undefined
) => {
  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Date",
      type: "Date",
      sortable: false,
    },
    {
      field: "author",
      headerName: "Author",
      width: 175,
      sortable: false,
    },
    {
      field: "category",
      headerName: "Client",
      width: 125,
      type: "string",
      sortable: false,
    },
    {
      field: "note",
      headerName: "Notes",
      flex: 1,
      type: "string",
      sortable: false,
      renderCell: (e) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              maxWidth: "100%",
              minWidth: "200px",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {e.row.note}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell(e) {
        if (e.row.author !== undefined) {
          const canEdit = e.row.author === currentUser;
          return (
            <IconButton
              icon={<EditIcon sx={styleNoteDataTable(canEdit)} />}
              title={canEdit ? "Edit" : "Only author can edit"}
              style={styleHoverButton}
              onClick={
                canEdit
                  ? () => {
                    handleEditClick(e.row.id);
                  }
                  : () => {}
              }
              tooltipPlacement='right'
              dataTest='editIcon'
            />
          );
        }

        return <Box sx={{ height: "50px" }} />;
      },
    },
  ];
  return columns;
};

export const notionDataTable = () => {
  const columns: GridColDef[] = [
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 200,
      sortable: false,
    },
    {
      field: "employmentStatus",
      headerName: "EMPLOYMENT STATUS",
      width: 200,
      sortable: false,
    },
    {
      field: "contractType",
      headerName: "CONTRACT TYPE",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "ioetLevel",
      headerName: "IOET Level",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "ioetRole",
      headerName: "IOET Role",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "staffingStatus",
      headerName: "STAFFFING STATUS",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "internalApp",
      headerName: "INTERNAL APP",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "internalRole",
      headerName: "INTERNAL ROLE",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      renderCell() {
        return (
          <IconButton
            icon={<EditIcon sx={{ backgroundColor: "none", fontSize: 40 }} />}
            title='Edit'
            style={{
              "boxShadow": "none",
              ":hover": { backgroundColor: "transparent" },
              "backgroundColor": "transparent",
            }}
            onClick={() => {}}
            tooltipPlacement='right'
            dataTest='editIcon'
          />
        );
      },
    },
  ];
  return columns;
};

export const bambooDataTable = () => {
  const columns: GridColDef[] = [
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "location",
      headerName: "LOCATION",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "department",
      headerName: "DEPARTMENT",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "jobTitle",
      headerName: "JOB TITLE",
      width: 200,
      type: "string",
      sortable: false,
    },
    {
      field: "reportsTo",
      headerName: "REPORTS TO",
      width: 200,
      type: "string",
      sortable: false,
    },
  ];
  return columns;
};
