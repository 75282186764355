import { Button } from "src/v2";
import { LOGIN_URL } from "src/v2/config/constants";
import people_app_logo_color from "../../assets/people_app_logo_color.png";
import "./LoginStatusContainer.css";
import {
  LoginCard,
  LoginPageContainer,
  LogoImage,
} from "./LoginStatusContainerStyleComponents";

function LoginStatusContainer() {
  return (
    <LoginPageContainer id='animation'>
      <LoginCard>
        <LogoImage src={people_app_logo_color} alt='logo' />
        <Button
          color='secondary'
          text='Login'
          onClick={() => {
            window.location.href = LOGIN_URL;
          }}
        />
      </LoginCard>
    </LoginPageContainer>
  );
}

export default LoginStatusContainer;
