import { createTheme } from "@mui/material/styles";

import {
  PRIMARY_COLOR,
  TEXTFIELD_BACKGROUND_COLOR,
} from "src/v2/constants/colors";
import ioetTheme from "../main";

export const initialTheme = createTheme({
  ...ioetTheme,
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: "#7A197A",
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "#fbe3d6",
          color: "#000",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "background": TEXTFIELD_BACKGROUND_COLOR,
          "WebkitTextFillColor": "#00000099",
          ".BambooClass": {
            ".Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: TEXTFIELD_BACKGROUND_COLOR,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "addButton" },
          style: {
            color: "#FF8B4B",
            textTransform: "none",
            background: "#FFFFFF",
            borderRadius: 8,
            width: "8rem",
            height: "3rem",
            boxShadow: "0px 2px 3px rgba(0,0,0,0.4)",
            padding: 0,
          },
        },
      ],
      styleOverrides: {
        outlinedPrimary: {
          "color": "#5F5C5C",
          "fontWeight": "bold",
          "& .MuiButton-label": {
            fontWeight: "bold",
          },
          "boxShadow":
            "0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001F",
          "borderColor": PRIMARY_COLOR,
        },
      },
    },
  },
});

const themePeopleApp = createTheme(initialTheme, {
  palette: {
    buttonGrey: initialTheme.palette.augmentColor({
      color: {
        main: "#9DA3A7",
      },
      name: "buttonGrey",
    }),
    darkGrey: initialTheme.palette.augmentColor({
      color: {
        main: "#8B7F78",
      },
      name: "darkGrey",
    }),
  },
});
export default themePeopleApp;
