import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";
import { BankInformationSection } from "./BankInformationSection/BankInformationSection";
import { BasicInformationSection } from "./BasicInformationSection/BasicInformationSection";
import { EducationInformationSection } from "./EducationInformationSection/EducationInformationSection";
import { EmergencyContactSection } from "./EmergencyContactSection/EmergencyContactSection";

export function PersonalTab(personInformation: Dict, key: string) {
  const { personInformation: personData } = personInformation;
  const hrStyle = {
    backgroundColor: PRIMARY_COLOR,
    height: 5,
    marginRight: "10%",
  };
  return (
    <div
      style={{ paddingLeft: "5%" }}
      key={key}
      data-testid='personal-tab-container'
    >
      <BasicInformationSection personInformation={personData} />
      <hr style={hrStyle} />
      <BankInformationSection bankAccountInformation={personData.bankAccount} />
      <hr style={hrStyle} />
      <EducationInformationSection
        educationInformation={personData.education}
      />
      <hr style={hrStyle} />
      <EmergencyContactSection
        personInformation={personData.emergencyContact}
      />
    </div>
  );
}
