import dayjs from "dayjs";

export enum NoteSection {
  HR = "HR",
  Client = "Client",
  Career = "Career",
}
export interface INote {
  id?: string;
  createdAt?: dayjs.Dayjs;
  editedAt?: dayjs.Dayjs;
  date: string | dayjs.Dayjs;
  author: string;
  note: string;
  category: string;
  section: NoteSection;
}

export interface NoteRequest {
  person_id: string;
  note: INote;
}
