import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { IOptions } from "src/pages/FormEmployee/IEmployee";
import { IChipsInTextBox } from "./types";
import { Chip } from "../../atoms/Chip";

function ChipsInTextBox({
  availableTags,
  label,
  placeholder,
  sx,
  error,
  id,
  onChange,
  value,
}: IChipsInTextBox) {
  return (
    <Autocomplete
      id={id ?? "autocomplete-id"}
      multiple
      sx={sx}
      options={availableTags}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      renderTags={(tags: IOptions[], getTagProps) =>
        tags.map((option, index) => (
          <Chip label={option.label} {...getTagProps({ index })} key={index} />
        ))
      }
      isOptionEqualToValue={(option: IOptions, tag: IOptions) =>
        option.value === tag.value
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label={label}
          placeholder={placeholder}
          error={error}
        />
      )}
    />
  );
}

export default ChipsInTextBox;
