import dayjs from "dayjs";
import { useGetReviewsDashboard } from "src/v2/hooks/useFetchs";
import { IReviewDashboard } from "src/v2/models/review.model";
import { reviewsDashboardTable } from "src/v2/utils/reviewsDashboardTable";
import { Table } from "../Table";

function ReviewDashboardTable() {
  const todayDate: string = dayjs().startOf("month").format("YYYY-MM-DD");

  const { data, isPending } = useGetReviewsDashboard(todayDate);
  const lastReviewLists: IReviewDashboard[] =
    data?.filter((review) => !!review.status) || [];
  const columns = reviewsDashboardTable();
  return (
    <Table
      columns={columns}
      rowData={lastReviewLists}
      hideFooter
      isLoading={isPending}
      initialState={{
        sorting: {
          sortModel: [{ field: "status", sort: "desc" }],
        },
      }}
    />
  );
}
export default ReviewDashboardTable;
