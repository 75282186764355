/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "@tanstack/react-query";
import {
  fetchNextReviewDate,
  fetchPersonData,
  getAllNotificationsByUser,
  getAllPeopleData,
  getNotesByID,
  listAllPeople,
  listAllReviewsByPersonId,
  listAllReviewsPerDateDashboard,
} from "src/v2/utils/callServiceFunctions";
import { fetchUserData } from "../services/userData.service";

export function usefetchPersonData(personId: string) {
  const { isPending, error, data } = useQuery({
    queryKey: ["fetchPersonData", personId],
    queryFn: () => fetchPersonData(personId),
  });
  return { isPending, error, data };
}
export function uselistAllPeople() {
  const { isPending, error, data } = useQuery({
    queryKey: ["listAllPeople"],
    queryFn: () => listAllPeople(),
  });
  return { isPending, error, data };
}

export function useListAllReviewsByPersonId(personId: string) {
  const { isPending, error, data, refetch, isRefetching } = useQuery({
    queryKey: ["listAllReviewsByPersonId", personId],
    queryFn: () => listAllReviewsByPersonId(personId),
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const isLoading = isPending || isRefetching;
  return { isLoading, error, data, refetch };
}

export function useFetchNextReviewDateByPersonId(personId: string) {
  const { isPending, error, data, isRefetching } = useQuery({
    queryKey: ["fetchNextReviewDate", personId],
    queryFn: () => fetchNextReviewDate(personId),
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const isLoading = isPending || isRefetching;
  return { isLoading, error, data };
}

export function useGetReviewsDashboard(date: string) {
  const { isPending, error, data } = useQuery({
    queryKey: ["getReviewsDashboard", date],
    queryFn: () => listAllReviewsPerDateDashboard(date),
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  return { isPending, error, data };
}

export function useGetAllPeopleData() {
  const { isPending, error, data } = useQuery({
    queryKey: ["getAllPeopleData"],
    queryFn: () => getAllPeopleData(),
  });
  return { isPending, error, data };
}

export function useGetAllNotificationsByUser() {
  const { isPending, error, data, refetch, isSuccess } = useQuery({
    queryKey: ["getAllNotificationsByUserMail"],
    queryFn: () => getAllNotificationsByUser(),
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const isLoading = isPending;
  return { isLoading, isSuccess, error, data, refetch };
}

export function useGetUserInformation() {
  const { isPending, error, data } = useQuery({
    queryKey: ["fetchUserData"],
    queryFn: () => fetchUserData(),
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });
  const isLoading = isPending;

  return { isLoading, error, data };
}

export function useGetNotesByID(personId: string) {
  const { isPending, error, data } = useQuery({
    queryKey: ["getNotesByID", personId],
    queryFn: () => getNotesByID(personId),
  });
  return { isPending, error, data };
}
