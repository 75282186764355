import { IDataDefinitionSchema } from "../models/dataDefinition.model";
import { ViewTag } from "../models/dynamicViewTag.model";

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const DataDefinitionSchemaFor: IDataDefinitionSchema = {
  fields: {
    name: {
      label: "name",
      view_tag: ViewTag.simple_string,
      frozen: false,
      nullable: true,
    },
    lastName: {
      label: "lastName",
      view_tag: ViewTag.simple_string,
      frozen: false,
      nullable: true,
    },
    ioetId: {
      label: "ioetId",
      view_tag: ViewTag.simple_string,
      frozen: false,
      nullable: true,
    },
    ioetEmail: {
      label: "ioetEmail",
      view_tag: ViewTag.email,
      frozen: false,
      nullable: true,
    },
    startDate: {
      label: "startDate",
      view_tag: ViewTag.date,
      frozen: false,
      nullable: true,
    },
    endDate: {
      label: "endDate",
      view_tag: ViewTag.date,
      frozen: false,
      nullable: true,
    },
    office: {
      label: "office",
      view_tag: ViewTag.simple_string,
      frozen: false,
      nullable: true,
    },
  },
} as const;
export const columnsHiddenByDefault = {};
