import * as yup from "yup";

export const workInformationSchema = yup.object({
  id: yup.string(),
  employmentStatus: yup.string().required("This field is required"),
  contractType: yup.string().required("This field is required"),
  office: yup.string().required("This field is required"),
  staffingStatus: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required("This field is required"),
  workingTitles: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required("This field is required"),
});
