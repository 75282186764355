import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { NotesDataTable } from "src/v2/utils/personProfileDataTable";
import CustomAlert from "src/v2/components/molecules/Alert/CustomAlert";
import NoteForm from "src/v2/components/molecules/NoteForm/NoteForm";
import { UserInformation } from "src/v2/components/organisms/UserInformationTables/UserInformation";
import { INote, NoteSection } from "src/v2/models";

export function CareerPathSection({
  personInformationNoteCareer,
  user,
  isLoading,
}: {
  isLoading?: boolean;
  personInformationNoteCareer?: INote[];
  user: string | undefined;
}) {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);
  const [actionTitle, setActionTitle] = useState("");

  const [noteBeingEdited, setNoteBeingEdited] = useState<INote | undefined>(
    undefined
  );
  const [informationNoteCareer, setInformationNoteCareer] = useState<INote[]>(
    []
  );

  useEffect(() => {
    setInformationNoteCareer(
      personInformationNoteCareer
        ? Object.values(personInformationNoteCareer).filter(
          (object) => object.section === NoteSection.Career
        )
        : []
    );
  }, [personInformationNoteCareer]);

  const [alertInformation, setAlertInformation] = useState({
    severity: "success",
    message: "Data saved correctly",
  });
  const [savedData, setSavedData] = useState(false);

  const properties = {
    displayCheckbox: false,
    hidePagination: true,
    hideFooter: true,
    customToolBar: false,
    tableContainer: { maxWidth: "100%" },
  };

  const handleEditClick = (noteId: string) => {
    setNoteBeingEdited(
      informationNoteCareer.filter((note) => noteId === note.id)[0]
    );
    handleToggle();
    setActionTitle("Editing Career Path Note");
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSavedData(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <UserInformation
        isPending={isLoading}
        title='Career Path'
        onClick={() => {
          setNoteBeingEdited(undefined);
          handleToggle();
          setActionTitle("Career Path");
        }}
        columns={NotesDataTable(handleEditClick, user)}
        rows={informationNoteCareer}
        properties={properties}
      />
      <Box data-testid='modal-dialog'>
        <NoteForm
          noteBeingEdited={noteBeingEdited}
          user={user}
          noteSection={NoteSection.Career}
          setAlertInformation={setAlertInformation}
          handleToggle={handleToggle}
          actionButton='SAVE'
          setSavedData={setSavedData}
          actionTitle={actionTitle}
          openDialog={open}
        />
      </Box>
      <CustomAlert
        sx={{ marginLeft: "50px" }}
        text={alertInformation.message}
        severity={
          alertInformation.severity as "error" | "success" | "info" | "warning"
        }
        open={savedData}
        onClose={handleClose}
      />
    </Box>
  );
}
