/* eslint-disable react/function-component-definition */
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { IItemButtonProps } from "./types";

const ItemButton = (props: IItemButtonProps) => {
  const { open, onClick, style, selected, icon, title } = props;
  return (
    <ListItemButton onClick={onClick} sx={style} selected={selected}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : "auto",
          justifyContent: "center",
          color: `${selected ? "#568cf8" : "#000"}`,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
    </ListItemButton>
  );
};

export default ItemButton;
