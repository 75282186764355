import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ADMIN_ROLE_NAME, USER_ROLE_NAME } from "src/v2/config/constants";
import { useGetUserInformation } from "src/v2/hooks/useFetchs";
import { PEOPLE_LOGIN_URL } from "../../constants/urlConstants";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading/Loading";

function RequiredUser() {
  const location = useLocation();
  const { setUser } = useAuth();

  const { data: userData, isLoading } = useGetUserInformation();
  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData, setUser]);
  if (USER_ROLE_NAME === undefined) {
    throw new Error("User role name should be defined");
  }

  if (isLoading) {
    return <Loading />;
  }
  return userData?.roles.includes(USER_ROLE_NAME) ||
    userData?.roles.includes(ADMIN_ROLE_NAME) ? (
      <Outlet />
    ) : (
      <Navigate to={PEOPLE_LOGIN_URL} state={{ from: location }} replace />
    );
}

export default RequiredUser;
