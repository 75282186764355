import { Employee } from "../../domain/model/Employee";
import { EmployeeRepository } from "../../domain/repository/employeeRepository";
import { EmployeeGetByIdInputPort } from "../../domain/useCase/EmployeeGetByIdInputPort";

export class EmployeeProfileViewModel implements EmployeeGetByIdInputPort {
  employeeRepository: EmployeeRepository;

  constructor(employeeRepository: EmployeeRepository) {
    this.employeeRepository = employeeRepository;
  }

  async getEmployeeByIdUseCase(employeeId: string): Promise<Employee> {
    const response = await this.employeeRepository.getEmployeeById(employeeId);

    const employee = new Employee();

    employee.first_name = response.first_name;
    employee.last_name = response.last_name;
    employee.ioet_id = response.ioet_id;
    employee.ioet_email = response.ioet_email;
    employee.uuid = response.uuid;
    employee.is_new_hire = response.is_new_hire;
    employee.gender = response.gender;
    employee.date_of_birth = response.date_of_birth;
    employee.age = response.age;
    employee.ssn = response.ssn;
    employee.cedula = response.cedula;
    employee.ruc = response.ruc;
    employee.address = response.address;
    employee.province = response.province;
    employee.zip_code = response.zip_code;
    employee.city = response.city;
    employee.github_id = response.github_id;
    employee.linkedin = response.linkedin;
    employee.twitter_feed = response.twitter_feed;
    employee.facebook = response.facebook;
    employee.t_shirt_size = response.t_shirt_size;
    employee.start_date = response.start_date;
    employee.end_date = response.end_date;
    employee.promised_review_date = response.promised_review_date;
    employee.country = response.country;
    employee.mobile_phone = response.mobile_phone;
    employee.home_email = response.home_email;
    employee.english_level = response.english_level;
    employee.ready_for_project = response.ready_for_project;
    employee.contract_type = response.contract_type;
    employee.status = response.status;
    employee.level = response.level;
    employee.work_titles = response.work_titles;
    employee.internal_app = response.internal_app;
    employee.bank_account = response.bank_account;
    employee.education = response.education;
    employee.job_information = response.job_information;
    employee.employment_status = response.employment_status;
    employee.compensation = response.compensation;
    employee.emergency_contact = response.emergency_contact;
    employee.pto = response.pto;
    employee.reviews = response.reviews;
    employee.time_on_the_job = response.time_on_the_job;

    return employee;
  }
}
