/* eslint-disable react/function-component-definition */
import { SpeedDialAction as MUISpeedDialAction } from "@mui/material";
import React from "react";
import { IIconButton } from "./types";

const IconButton: React.FC<IIconButton> = ({
  icon,
  title = "Tooltip",
  open = true,
  tooltipPlacement = "left",
  style,
  onClick,
  dataTest,
}) => {
  return (
    <MUISpeedDialAction
      icon={icon}
      tooltipTitle={title}
      open={open}
      tooltipPlacement={tooltipPlacement}
      sx={style}
      onClick={onClick}
      data-testid={dataTest}
    />
  );
};

export default IconButton;
