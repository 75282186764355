import { Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { useGetNotesByID } from "src/v2/hooks/useFetchs";
import { CareerPathSection } from "./CareerPathSection/CareerPathSection";
import { ClientProjectSection } from "./ClientProjectSection/ClientProjectSection";
import { HumanResourcesSection } from "./HumanResourcesSection/HumanResourcesSection";

interface INotesTabProps {
  key: string;
  user: string | undefined;
}

export function NotesTab({ user }: INotesTabProps) {
  const { personId } = useParams();
  const { data: notes, isPending: isPendingNotes } = useGetNotesByID(personId!);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 5, maxWidth: "xl" }}
    >
      <HumanResourcesSection
        isLoading={isPendingNotes}
        personInformationNoteHR={notes}
        user={user}
      />
      <Divider sx={{ borderWidth: "medium" }} color={PRIMARY_COLOR} />
      <ClientProjectSection
        isLoading={isPendingNotes}
        personInformationNoteClient={notes}
        user={user}
      />
      <Divider sx={{ borderWidth: "medium" }} color={PRIMARY_COLOR} />
      <CareerPathSection
        isLoading={isPendingNotes}
        personInformationNoteCareer={notes}
        user={user}
      />
    </Box>
  );
}
