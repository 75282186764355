/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import { TransformInfo } from "../TransformInfo/TransformInfo";
import { TransformFormContainer } from "../../organisms/TransformFormContainer/TransformFormContainer";
import { CandidateProps } from "./CandidateProps";

export function TransformForm({ candidate, index }: CandidateProps) {
  const [showForm, setShowForm] = useState(false);
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div>
      <TransformInfo
        first_name={candidate.first_name}
        last_name={candidate.last_name}
        img={candidate.img}
        onClickPass={() => toggleForm()}
        index={index}
      />
      {showForm && <TransformFormContainer candidate={candidate} />}
    </div>
  );
}
