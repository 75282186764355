import { SxProps } from "@mui/system";

export const sidebarStyle: Record<string, SxProps> = {
  listItemStyles: {
    "cursor": "pointer",
    "& :hover": {
      "backgroundColor": "transparent",
      "borderRadius": "1rem",
      "&:hover": {
        backgroundColor: "burlywood",
      },
    },
  },
  renderListStyles: {
    width: 450,
    bgcolor: "background.paper",
    margin: "0 auto",
  },
  IconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  Drawer: {
    "& .MuiDrawer-paper": {
      display: "flex",
      position: "relative",
      height: "80vh",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "1rem",
      top: "10%",
      width: "70vw",
      margin: "0 auto",
      boxShadow: "0 0 3px rgba(0,0,0,0.5)",
    },
  },
};
