/* eslint-disable react/function-component-definition */
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import { List, ListItem } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ItemButton } from "src/v2/components/atoms/IteamButton";
import { Drawer } from "src/v2/components/molecules/Drawer";
import { useSidebar } from "../../../hooks/useSidebar";
import { ISidebarProps } from "./types";

const Sidebar: React.FC<ISidebarProps> = ({ open }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { personId: paramPersonId } = useParams();
  if (paramPersonId) localStorage.setItem("lastPersonId", paramPersonId);
  const lastPersonId = localStorage.getItem("lastPersonId");

  const items = useMemo(() => {
    const sideItems = [
      {
        icon: <DashboardIcon sx={{ color: "#999" }} />,
        title: "Dashboard",
        url: "/reviews_dashboard",
      },
      {
        icon: <PeopleAltIcon sx={{ color: "#999999" }} />,
        title: "People",
        url: "/",
      },
    ];
    if (lastPersonId) {
      sideItems.push({
        icon: <PersonIcon sx={{ color: "#999999" }} />,
        title: "Profile",
        url: `/person/${lastPersonId}`,
      });
    }
    return sideItems;
  }, [lastPersonId]);

  const selectionDefault = items[1].title;
  const [selected, setSelected] = useState<string>(selectionDefault);
  const { showSidebar } = useSidebar();

  const handleSideBardSelection = (url: string, title: string) => {
    setSelected(title);
    navigate(url);
  };

  useEffect(() => {
    const currentItem = items.find((item) =>
      matchPath(`${item.url}/*`, location.pathname)
    );

    if (currentItem) {
      setSelected(currentItem.title);
    }
  }, [items, location.pathname]);

  return !showSidebar ? null : (
    <Drawer
      open={open}
      PaperProps={{
        style: {
          marginTop: "80px",
          height: "calc(100vh - 90px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          zIndex: "0",
          marginRight: "10px",
        },
      }}
    >
      <List disablePadding>
        {items.map((item) => {
          return (
            <ListItem key={item.title} disablePadding sx={{ display: "block" }}>
              <ItemButton
                open={open}
                title={item.title}
                icon={item.icon}
                onClick={() => handleSideBardSelection(item.url, item.title)}
                style={{
                  justifyContent: open ? "initial" : "center",
                  backgroundColor: `${
                    selected === item.title ? "#e8effe" : "#fff"
                  }`,
                  color: `${selected === item.title ? "#568cf8" : "#000"}`,
                }}
                selected={selected === item.title}
              />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
