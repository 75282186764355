import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  mutateNote,
  mutateReview,
  updateNextReviewDateData,
  updateReadNotificationData,
} from "src/v2/utils/callServiceFunctions";
import { INote, IReview, UserNotification } from "../models";
import { updateOnePersonData } from "../services/peopleData.service";
import { IWorkInformationQuery } from "../models/workInformation.model";

export function useMutateReview() {
  return useMutation({
    mutationKey: ["lastUpdatedReview"],
    mutationFn: (data: {
      review: IReview;
      personId: string;
      newReview: boolean;
    }) => mutateReview(data.review, data.personId, data.newReview),
  });
}

export function useMutateNote() {
  return useMutation({
    mutationKey: ["newNote"],
    mutationFn: (data: { note: INote; newNote: boolean; personId: string }) =>
      mutateNote(data.note, data.newNote, data.personId),
  });
}

export function useMutateNotification() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateReadNotificationData"],
    mutationFn: (notificationId: string) =>
      updateReadNotificationData(notificationId),
    onSuccess: (_, notificationId) => {
      queryClient.setQueryData(
        ["getAllNotificationsByUserMail"],
        (oldData: UserNotification[]) => {
          return oldData.map((notification: UserNotification) => {
            if (notification.id === notificationId) {
              return { ...notification, read: true };
            }
            return notification;
          });
        }
      );
    },
  });
}

export function useMutateNextReviewDate() {
  return useMutation({
    mutationKey: ["nextReviewDate"],
    mutationFn: ({
      personId,
      nextReviewDate,
    }: {
      personId: string;
      nextReviewDate: Date;
    }) => updateNextReviewDateData(personId, nextReviewDate),
  });
}

export function useMutateWorkInformation() {
  return useMutation({
    mutationKey: ["mutateWorkInformation"],
    mutationFn: (data: { workInformation: IWorkInformationQuery }) =>
      updateOnePersonData(data.workInformation),
  });
}
