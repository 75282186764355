import React, { useState } from "react";
import PopoverComponent from "src/v2/components/molecules/Popover/Popover";
import { useGetAllNotificationsByUser } from "src/v2/hooks/useFetchs";
import { IconButton, Skeleton, Stack } from "@mui/material";
import { bellStyles } from "src/v2/components/organisms/NavBar/NavBarStyleComponents";
import { ReviewListNotification } from "src/v2/components/molecules/ReviewListNotification";
import { NotificationPanel } from "src/v2/components/molecules/NotificationContainer/NotificationPanel";
import { NotificationBadge } from "src/v2/components/atoms/NotificationsBadge/NotificationsBadge";

function Notification() {
  const { data: notificationData, isLoading } = useGetAllNotificationsByUser();
  const { bellIcon } = bellStyles;
  const [openPopover, setOpenPopover] = useState(false);
  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <PopoverComponent
      isOpen={openPopover}
      mainComponent={
        <IconButton sx={bellIcon}>
          {notificationData ? (
            <NotificationBadge
              number={
                notificationData.filter((notification) => {
                  return !notification.read;
                }).length
              }
            />
          ) : (
            <NotificationBadge number={0} />
          )}
        </IconButton>
      }
      positions={["bottom"]}
      withWidth={false}
      onClick={(newState) => setOpenPopover(newState)}
    >
      <NotificationPanel handleClose={handleClosePopover}>
        {isLoading ? (
          <Stack spacing={2} margin={2}>
            <Skeleton variant='rectangular' height={50} />
            <Skeleton variant='rectangular' height={50} />
          </Stack>
        ) : (
          <ReviewListNotification userNotifications={notificationData ?? []} />
        )}
      </NotificationPanel>
    </PopoverComponent>
  );
}

export default Notification;
