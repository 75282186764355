import { useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import { PopoverComponentProps } from "./IPopover";
import { StylePopoverContainer } from "./PopoverContainerStyleComponents";

function PopoverComponent({
  isOpen,
  onClick,
  children,
  mainComponent,
  positions = ["bottom"],
  align = "end",
  withWidth = true,
}: PopoverComponentProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  useEffect(() => {
    if (isOpen === false) {
      setIsPopoverOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={positions}
      align={align}
      onClickOutside={() => {
        setIsPopoverOpen(false);
      }}
      content={
        withWidth
          ? // eslint-disable-next-line react/no-unstable-nested-components
          ({ childRect }) => (
            <StylePopoverContainer width={childRect.width}>
              {children}
            </StylePopoverContainer>
          )
          : children
      }
      containerStyle={{ zIndex: "5" }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsPopoverOpen(!isPopoverOpen);
          if (onClick) {
            onClick(!isPopoverOpen);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            setIsPopoverOpen(!isPopoverOpen);
            if (onClick) {
              onClick(!isPopoverOpen);
            }
          }
        }}
        role='button'
        tabIndex={0}
      >
        {mainComponent}
      </div>
    </Popover>
  );
}

export default PopoverComponent;
