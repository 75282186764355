import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useGetUserInformation } from "src/v2/hooks/useFetchs";
import {
  PEOPLE_BASE_URL,
  PEOPLE_LOGIN_URL,
} from "../../constants/urlConstants";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_NAME, USER_ROLE_NAME } from "../../v2/config/constants";
import Loading from "../Loading/Loading";

function RequiredAdmin() {
  const location = useLocation();
  const { setUser } = useAuth();

  const { data: userData, isLoading } = useGetUserInformation();
  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData, setUser, isLoading]);

  if (ADMIN_ROLE_NAME === undefined) {
    throw new Error("Admin role name should be defined");
  }

  if (isLoading) {
    return <Loading />;
  }
  if (userData?.roles.includes(ADMIN_ROLE_NAME)) {
    return <Outlet />;
  }
  if (userData?.roles.includes(USER_ROLE_NAME)) {
    return <Navigate to={PEOPLE_BASE_URL} state={{ from: location }} replace />;
  }
  return <Navigate to={PEOPLE_LOGIN_URL} state={{ from: location }} replace />;
}

export default RequiredAdmin;
