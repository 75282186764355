import { Box, Chip, MenuItem, Select, SelectChangeEvent } from "@mui/material";

export interface IMultiSelectData {
  name: string;
}

interface IMultipleSelect {
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  defaultValues: IMultiSelectData[];
  multiSelectOptions: IMultiSelectData[];
}

export function MultipleSelect({
  handleChange,
  defaultValues,
  multiSelectOptions,
}: IMultipleSelect) {
  return (
    <Box sx={{ width: "100%" }}>
      <Select
        sx={{ display: "flex" }}
        multiple
        onChange={handleChange}
        defaultValue={defaultValues.map((item: IMultiSelectData) => item.name)}
        renderValue={(selected: string[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value: string) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {multiSelectOptions.map((item: any, index) => (
          <MenuItem key={index} value={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
