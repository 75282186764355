import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Badge } from "@mui/material";
import { notificationBadgeStyle } from "./NotificationsBadgeStyle";

export function NotificationBadge({ number }: { number: number }) {
  const { notificationBadge } = notificationBadgeStyle;

  return (
    <Badge
      data-testid='notification-badge'
      sx={{ notificationBadge }}
      badgeContent={number}
      color='primary'
      showZero
    >
      <NotificationsNoneOutlinedIcon fontSize='large' />
    </Badge>
  );
}
