import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Box, IconButton } from "@mui/material";
import { joinNameLastName } from "src/utils/stringJoins";
import PersonHeaderData from "src/v2/components/organisms/PersonHeaderData/PersonHeaderData";
import { Dict } from "src/v2/models";
import { capitalizeOnlyFirstLetterOfEveryWord } from "src/v2/utils/stringUtils";

export interface IHeaderProps {
  personInformation: Dict;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}

export function Header(props: IHeaderProps) {
  const { personInformation: personData, setOpen, open } = props;

  const personFullName = joinNameLastName(personData.name, personData.lastName);

  const personJobTitle = personData.jobTitle ?? "";

  return (
    <header
      data-testid='header-container'
      style={{
        display: "flex",
        background: "linear-gradient(to right, #FC5138, #FE395E)",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Box
        data-testid='typography group'
        sx={{
          width: "80%",
          paddingTop: "1%",
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <PersonHeaderData
          name={capitalizeOnlyFirstLetterOfEveryWord(personFullName)}
          jobData={personJobTitle}
        />

        <IconButton
          sx={{ display: { lg: "none" } }}
          onClick={() => setOpen?.(!open)}
        >
          <MenuOpenIcon sx={{ color: "white" }} />
        </IconButton>
      </Box>
    </header>
  );
}
