import { ADMIN_ROLE_NAME, USER_ROLE_NAME } from "src/v2/config/constants";
import { stringToTitleCase } from "src/v2/utils/parsers";
import { UserInformationRow } from "src/v2/components/atoms/index";
import { UserInformationProps, UserRowInformation } from "./types";
import { UserInformationContainer } from "./UserInformationStyleComponents";

function UserInformation({
  user,
  showEmail,
  showAdminRole,
  showName,
}: UserInformationProps) {
  if (!ADMIN_ROLE_NAME) {
    throw new Error("Admin role name should be defined");
  }

  const roles = [];

  if (user?.roles.includes(ADMIN_ROLE_NAME)) {
    roles.push(stringToTitleCase(ADMIN_ROLE_NAME));
  }

  if (user?.roles.includes(USER_ROLE_NAME)) {
    roles.push(stringToTitleCase(USER_ROLE_NAME));
  }

  const stylizedRoles = roles.join(", ").toString();

  const textRows: UserRowInformation[] = [
    {
      rowCondition: showName,
      rowIcon: "user",
      rowText: user?.name,
    },
    {
      rowCondition: showAdminRole,
      rowIcon: "users",
      rowText: stylizedRoles,
      rowIconColor: "#999999",
      rowtextWeight: "light",
    },
    {
      rowCondition: showEmail,
      rowIcon: "mail",
      rowText: user?.email,
      rowIconColor: "#999999",
      rowtextWeight: "light",
    },
  ];

  return (
    <UserInformationContainer>
      {textRows
        .filter((row) => row.rowCondition)
        .map((row, key) => (
          <div key={key}>
            <UserInformationRow
              icon={row.rowIcon}
              text={row.rowText}
              iconColor={row.rowIconColor}
              textWeight={row.rowtextWeight}
            />
          </div>
        ))}
    </UserInformationContainer>
  );
}

export default UserInformation;
