/* eslint-disable react/function-component-definition */
import { Box } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { NavBar, Sidebar } from "src/v2/components/organisms";
import useAuth from "src/hooks/useAuth";

const Layout = () => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  return (
    <>
      {user && <NavBar open={open} setOpen={setOpen} />}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          mt: "80px",
        }}
      >
        <Sidebar open={open} />
        <Outlet />
      </Box>
    </>
  );
};

export default Layout;
