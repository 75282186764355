import { useState } from "react";
import { AcademicCapIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { BottomSheet } from "../../BottomSheet/BottomSheet";
import { EducationRegistry } from "./EducationRegistry/EducationRegistry";
import {
  FormAddSectionContainer,
  FormSectionContainer,
  FormTitleSectionContainer,
  FormViewContainer,
} from "../FormComponents";
import { EducationFormProps } from "./EducationFormProps";
import { Education } from "../../../domain/model/Employee";

const DEFAULT_EDUCATION: Education = {
  degree: "",
  institution: "",
  gpa: 0,
  end_date: "",
  start_date: "",
};

interface IEducationFormValues {
  items: Education[];
}

export function EducationForm({ educationList }: EducationFormProps) {
  const [focusInput, setFocusInput] = useState(false);

  const methods = useForm<IEducationFormValues>({
    defaultValues: {
      items: educationList
        ? educationList.map((education) => Object.create({ ...education }))
        : [DEFAULT_EDUCATION],
    },
  });
  const {
    control,
    reset,
    formState: { isDirty },
  } = methods;

  const { fields, append } = useFieldArray({
    control,
    name: "items",
  });

  const resetAllFields = () => {
    reset({ items: educationList });
    setFocusInput(false);
  };

  return (
    <FormProvider {...methods}>
      <FormViewContainer data-testid='employee-education-form'>
        <FormSectionContainer>
          <FormTitleSectionContainer>
            <AcademicCapIcon height={30} color='#FC5630' />
            <h1 className='text-ioet-orange text-3xl font-bold'>Education</h1>
          </FormTitleSectionContainer>
          {fields.map((education, index) => (
            <EducationRegistry
              education={{ ...education }}
              key={education.id}
              index={index}
              onFocusInput={() => setFocusInput(true)}
            />
          ))}
          <FormAddSectionContainer
            onClick={() => append(DEFAULT_EDUCATION)}
            data-testid='add-education-form'
          >
            <PlusCircleIcon height={30} />
            <h3 className=' font-bold'>Add Education</h3>
          </FormAddSectionContainer>
        </FormSectionContainer>
      </FormViewContainer>
      {(isDirty || focusInput) && (
        <BottomSheet handleOnCancel={resetAllFields} handleOnSave={() => {}} />
      )}
    </FormProvider>
  );
}
