import { IAlert } from "./IAlert";
import { AlertStyleDiv } from "./AlertStyleComponents";

export function Alert({ body, color, title }: IAlert) {
  return (
    <AlertStyleDiv role='alert' className={color}>
      <div>
        <p className='font-bold'>{title}</p>
        <p className='text-sm'>{body}</p>
      </div>
    </AlertStyleDiv>
  );
}
