import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { Button } from "src/v2";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { Table } from "src/v2/components/organisms/Table/Table";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { IUserInformation } from "./types";

export function UserInformation<T>(props: IUserInformation<T>) {
  const {
    addEntry = true,
    columns,
    rows,
    properties,
    onClick,
    title,
    isPending,
  } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {addEntry && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <DataIcon
            text={{
              children: `${title}`,
              variant: "h6",
              sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
            }}
          />
          <Button
            variant='addButton'
            onClick={onClick}
            data-testid='add-entry-button'
            text='Add Note'
            icon={<AddIcon />}
            iconPosition='left'
          />
        </Box>
      )}

      <Table
        isLoading={isPending}
        data-testid='user-information-table'
        columns={columns}
        rowData={rows}
        displayCheckbox={properties.displayCheckbox}
        hidePagination={properties.hidePagination}
        isCustomToolBar={properties.customToolBar}
        hideFooter={properties.hideFooter}
        sxTableContainer={{ maxWidth: "100%" }}
        initialState={{}}
      />
    </Box>
  );
}
