import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import resolverManager from "../constants/resolverManager";
import { CandidateFormInputPort } from "../domain/useCase/CandidateFormInputPort";
import { CandidateFormViewModel } from "../pages/CandidatePage/candidateFormViewModel";
import { CandidateRepositoryImpl } from "../data/repository/candidateRepositoryImpl";
import { GeneralErrorResponse } from "../domain/model/GeneralErrorResponse";
import { ALERT_STATE } from "../components/Alert/IAlert";
import { useAlert } from "./alert/useAlert";
import { ICandidate } from "../pages/CandidatePage/ICandidate";
import { IOptions } from "../pages/FormEmployee/IEmployee";
import LoadingContext from "../contexts/LoadingStateContext";
import { ILoadingContextState } from "../contexts/IContexts";
import { DEFAULT_CANDIDATE } from "../constants/defaultCandidate";

export const useFormCandidateActions = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext) as ILoadingContextState;
  const { alertState, updateAlertState } = useAlert();
  const candidateUseCases: CandidateFormInputPort = new CandidateFormViewModel(
    new CandidateRepositoryImpl()
  );

  const methods = useForm<ICandidate>({
    resolver: resolverManager.candidateInformationResolver,
    defaultValues: DEFAULT_CANDIDATE,
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const [resetSelectInputFlag, setResetSelectInputFlag] = useState(false);

  const resetAllFields = () => {
    reset(DEFAULT_CANDIDATE);
    setResetSelectInputFlag(!resetSelectInputFlag);
  };

  async function getCandidateId(candidateData: ICandidate) {
    try {
      const candidate: ICandidate = {
        first_name: candidateData.first_name,
        last_name: candidateData.last_name,
        ioet_id: candidateData.ioet_id,
        employment_status: candidateData.employment_status,
      };
      const candidateId = await candidateUseCases.getCandidateIdUseCase(
        candidate
      );
      return candidateId;
    } catch (error) {
      if (error instanceof GeneralErrorResponse) {
        updateAlertState({
          show: true,
          data: {
            body: error.message,
            color: ALERT_STATE.error,
            title: "ID Candidate Error",
          },
        });
      }
    }
    return "";
  }

  const onSubmit = async (candidateForm: ICandidate) => {
    setLoading(true);
    try {
      const candidate: ICandidate = {
        first_name: candidateForm.first_name,
        last_name: candidateForm.last_name,
        ioet_id: candidateForm.ioet_id,
        employment_status: (candidateForm.employment_status as IOptions).value,
      };
      await candidateUseCases.createCandidateUseCase(candidate);
    } catch (error) {
      if (error instanceof GeneralErrorResponse) {
        updateAlertState({
          show: true,
          data: {
            body: error.message,
            color: ALERT_STATE.error,
            title: "Create Candidate error",
          },
        });
      }
    } finally {
      resetAllFields();
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    register,
    onSubmit,
    resetAllFields,
    resetSelectInputFlag,
    alertState,
    methods,
    navigate,
    getCandidateId,
    watch,
    setValue,
    reset,
    errors,
  };
};
