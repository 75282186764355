import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Divider, IconButton, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "src/v2/components/atoms";
import INotificationPanelProps from "./INotificationPanel";

export function NotificationPanel({
  children,
  handleClose,
}: INotificationPanelProps) {
  return (
    <Paper sx={{ maxWidth: "30rem", minWidth: "20rem" }}>
      <Stack>
        <Grid container>
          <Grid
            xs={10}
            paddingLeft={1.5}
            justifyContent='flex-start'
            flexDirection='row'
            alignItems='center'
            display='flex'
            gap={1}
          >
            <NotificationsNoneOutlinedIcon fontSize='large' />
            <Typography variant='h5' bold color='inherit'>
              Notifications
            </Typography>
          </Grid>
          <Grid xs={1}>
            <IconButton onClick={handleClose} size='large'>
              <CloseOutlinedIcon fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
        <Divider variant='middle' />
        <Stack>{children}</Stack>
      </Stack>
    </Paper>
  );
}
