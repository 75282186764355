import * as yup from "yup";

export const reviewInformationSchema = yup.object({
  id: yup.string(),
  currentSalary: yup
    .number()
    .min(1, "Please insert a salary")
    .required("This field is required"),
  proposedSalary: yup
    .number()
    .test(
      "is-zero-or-greater-than-current-salary",
      "Proposed salary shouldn't be less than current salary",
      function isTheProposedSalaryZeroOrGreaterThanCurrentSalary(value) {
        if (value === undefined) return false;
        return value === 0 || value >= this.parent.currentSalary;
      }
    ),
  currentJob: yup.string().required("This field is required"),
  proposedJob: yup.string(),
  reviewDate: yup.date().required("This field is required"),
  status: yup.string().required("This field is required"),
  result: yup.string(),
  comments: yup.object({
    comment: yup.string().required("This field is required"),
  }),
  effectiveDate: yup.date().required("This field is required"),
});
