import { ThemeOptions } from "@mui/material";
import palette from "./palette";
import typography from "./typography";

const ioetTheme: ThemeOptions = {
  palette: palette.light,
  typography,
};

export default ioetTheme;
