import { Container } from "@mui/material";
import { Typography } from "src/v2/components/atoms";
import { ReviewDashboardTable } from "src/v2/components/organisms/ReviewDashboardTable";

export function ReviewsDashboardPage() {
  return (
    <Container maxWidth='xl' component='section' fixed disableGutters>
      <Typography
        align='center'
        variant='h4'
        sx={{ margin: 5, color: "#F23C0A" }}
      >
        Reviews Dashboard
      </Typography>
      <ReviewDashboardTable />
    </Container>
  );
}
