import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import cn from "classnames";
import styles from "./Detail.module.css";

type TypographyProps<T> = {
  children?: React.ReactNode;
  className?: string;
  large?: boolean;
  bold?: boolean;
  style?: React.CSSProperties;
} & React.HTMLAttributes<T>;

const Detail: ForwardRefExoticComponent<TypographyProps<HTMLSpanElement> & RefAttributes<HTMLSpanElement>> = forwardRef(
  ({ children, className, large, bold, style, ...props }, forwardedRef) => {
    const classes: string = cn(styles["detail"], className, {
      [styles["size--large"]]: large,
      [styles["bold"]]: bold,
    });

    return (
      <span ref={forwardedRef} className={classes} style={style} {...props}>
        {children}
      </span>
    );
  }
);

export default Detail;
