export const IoetIcons = {
  default: import("./assets/default.svg"),
  clipboard: import("./assets/clipboard.svg"),
  list: import("./assets/list.svg"),
  award: import("./assets/award.svg"),
  dollarSign: import("./assets/dollar-sign.svg"),
  bookOpen: import("./assets/book-open.svg"),
  smartphone: import("./assets/smartphone.svg"),
  mail: import("./assets/mail.svg"),
  facebook: import("./assets/facebook.svg"),
  twitter: import("./assets/twitter.svg"),
  linkedin: import("./assets/linkedin.svg"),
  mapPin: import("./assets/map-pin.svg"),
  users: import("./assets/users.svg"),
  edit: import("./assets/edit.svg"),
  userCheck: import("./assets/user-check.svg"),
  x: import("./assets/x.svg"),
  hexagons: import("./assets/hexagons.svg"),
  employmentStatus: import("./assets/employmentStatus.svg"),
  job: import("./assets/job.svg"),
  compensation: import("./assets/compensation.svg"),
  graduation: import("./assets/graduation.svg"),
  notes: import("./assets/notes.svg"),
  docSign: import("./assets/docSign.svg"),
  education: import("./assets/education.svg"),
  userInformation: import("./assets/userInformation.svg"),
  bank: import("./assets/bank.svg"),
  emergencyContact: import("./assets/emergencyContact.svg"),
  redirect: import("./assets/redirect.svg"),
  logout: import("./assets/logout.svg"),
  user: import("./assets/user.svg"),
  pencil: import("./assets/pencil.svg"),
  jobInformation: import("./assets/jobInformation.svg"),
  triangleWarning: import("./assets/triangleWarning.svg"),
  calendar: import("./assets/calendar.svg"),
  briefcase: import("./assets/briefcase.svg"),
  money: import("./assets/money.svg"),
  warning: import("./assets/warning.svg"),
  alert: import("./assets/alert.svg"),
  phone: import("./assets/phone.svg"),
};

export type IoetIcon = keyof typeof IoetIcons;
