import { IFilterAttributes } from "@components/organisms/Filter/types";
import dayjs from "dayjs";
import { DEFAULT_NO_SELECTED_OPTION } from "src/components/forms/PersonalInfoForm/utils/InputOptions";
import { IOptions } from "src/pages/FormEmployee/IEmployee";

export function getDynamicValue(object: any) {
  if (object instanceof Date) {
    return dayjs(object).format("DD-MM-YYYY");
  }
  if (typeof object === "object") {
    return object["name"] ?? object["payRate"] ?? "";
  }
  return object;
}

export class DynamicPersonAdapter {
  static adapt(person: any): any {
    const adaptedPerson: any = {};

    Object.keys(person).forEach((key) => {
      if (person[key]) {
        const value = person[key];
        if (Array.isArray(value) && value.length > 0) {
          adaptedPerson[key] = value
            .reduce(
              (accumulator, currentValue) =>
                `${accumulator + getDynamicValue(currentValue)} `,
              ""
            )
            .trimEnd();
        } else if (typeof value === "object" && value !== null) {
          adaptedPerson[key] = getDynamicValue(value);
        } else {
          adaptedPerson[key] = value;
        }
      }
    });

    return adaptedPerson;
  }
}

export const filterRows = (
  rows: any,
  keys: { key: string; headerName: string }[],
  filterAttributes: IFilterAttributes,
  currentRows: any
) => {
  const map = rows.map((row: any, idx: number) => {
    const matchingRows = keys.every(({ key, headerName }) => {
      let meetCondition = false;
      const value = filterAttributes[headerName]?.value ?? "";

      if (value === "" || !value) return true;
      if (!row[key]) return meetCondition;
      if (filterAttributes[headerName].filterOperator === "contains") {
        meetCondition = row[key].toLowerCase().includes(value.toLowerCase());
      }
      if (filterAttributes[headerName].filterOperator === "equals") {
        meetCondition = row[key].toLowerCase() === value.toLowerCase();
      }
      if (filterAttributes[headerName].filterOperator === "is not") {
        meetCondition = !row[key].toLowerCase().includes(value.toLowerCase());
      }

      return meetCondition;
    });

    if (matchingRows) {
      return currentRows[idx];
    }
    return null;
  });
  return map.filter((row: any) => row !== null);
};

export const updateColumns = (
  columns: any,
  filterHeaders: any,
  setColumnsToFilter: any
) => {
  const newColumns = columns.filter((column: any) => {
    const field = filterHeaders.includes(column.headerName);
    return !field;
  });
  setColumnsToFilter(newColumns);
};

export const OPERATOR_OPTIONS: { label: string; value: string }[] = [
  { label: "contains", value: "contains" },
  { label: "equals", value: "equals" },
  { label: "is not", value: "is not" },
];

export const buildSelectOptions = (
  value: string,
  defaultOptions: IOptions[]
): IOptions[] => {
  return defaultOptions.some((option) => option.value === value)
    ? defaultOptions
    : [
      ...defaultOptions,
      {
        label: value ?? DEFAULT_NO_SELECTED_OPTION,
        value: value ?? DEFAULT_NO_SELECTED_OPTION,
      },
    ];
};
