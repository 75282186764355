import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import SortIcon from "@mui/icons-material/Sort";
import { Button, Divider, IconButton, Stack } from "@mui/material";
import {
  GridCellModes,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Filter } from "src/v2/components/organisms";
import { CustomToolBarProps } from "./types";

function CustomToolBar<T>({
  setCellModesModel,
  selectedCell,
  isCellEditingMemo,
  columns,
  isSorting = false,
  currentRows,
  setFilterRow,
  hiddenColumns,
}: CustomToolBarProps<T>) {
  const handleSaveClick = () => {
    setCellModesModel({
      [selectedCell!.id]: {
        [selectedCell!.field]: {
          mode: GridCellModes.View,
        },
      },
    });
  };

  const handleCancelClick = () => {
    setCellModesModel({
      [selectedCell!.id]: {
        [selectedCell!.field]: {
          mode: GridCellModes.View,
          ignoreModifications: true,
        },
      },
    });
  };

  return (
    <GridToolbarContainer
      data-testid='custom-tool-bar'
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Stack direction='row' alignItems='center'>
        <GridToolbarColumnsButton
          color='darkGrey'
          sx={{ height: "fit-content" }}
        />
        <Filter
          columns={columns}
          hiddenColumns={hiddenColumns}
          currentRows={currentRows}
          setFilterRow={setFilterRow}
        />
        {/* TODO: here we can create a component for sort button */}
        {isSorting && (
          <Button size='small' startIcon={<SortIcon />} onClick={() => {}}>
            Sort
          </Button>
        )}
        {isCellEditingMemo && (
          <Stack sx={{ ml: "10px" }} direction='row' spacing={1}>
            <Divider
              sx={{ borderColor: "#00000033" }}
              orientation='vertical'
              flexItem
            />
            <IconButton
              data-testid='save-button'
              aria-label='save'
              size='small'
              onClick={handleSaveClick}
            >
              <SaveIcon style={{ color: "RoyalBlue" }} fontSize='small' />
            </IconButton>
            <IconButton
              data-testid='cancel-button'
              aria-label='delete'
              size='small'
              onClick={handleCancelClick}
            >
              <ClearIcon style={{ color: "RoyalBlue" }} fontSize='small' />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <GridToolbarQuickFilter
        data-testid='search-bar'
        sx={{ background: "transparent" }}
      />
    </GridToolbarContainer>
  );
}

export default CustomToolBar;
