import { IReview } from "src/v2/models";

export const organizeReviewsByYears = (objects: IReview[]) => {
  return objects.reduce((acc: { [year: string]: IReview[] }, obj) => {
    const year = new Date(obj.reviewDate).getFullYear().toString();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(obj);
    return acc;
  }, {});
};
