import { Avatar, CustomAlert } from "src/v2";
import PopoverComponent from "src/v2/components/molecules/Popover/Popover";
import { useNavigate } from "react-router-dom";
import { logOut } from "src/v2/services/peopleData.service";
import { useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import { Button } from "../../atoms/Button";
import { UserInformation } from "../../molecules/UserInformation";
import { UserInformationMenu } from "./UserInformationStyleComponents";

function UserInformationPopover() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [savedData, setSavedData] = useState(false);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      setSavedData(true);
    }
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSavedData(false);
  };

  return (
    <>
      <PopoverComponent
        mainComponent={
          <Avatar alt='User Avatar' width='4rem' srcImage={user?.picture} />
        }
        withWidth={false}
      >
        <UserInformationMenu aria-label='usermenu' role='menu'>
          <UserInformation user={user} showName showAdminRole showEmail />
          <Button
            variant='text'
            color='secondary'
            onClick={() => {
              handleLogout();
            }}
            icon='logout'
            text='Logout'
            iconPosition='left'
          />
        </UserInformationMenu>
      </PopoverComponent>

      <CustomAlert
        sx={{ marginLeft: "50px" }}
        text='Failed to log out'
        severity='error'
        open={savedData}
        onClose={handleClose}
      />
    </>
  );
}

export default UserInformationPopover;
