import tw from "twin.macro";

export const bellStyles = {
  bellIcon: {
    color: "#fbe3d6",
    position: "relative",
  },
};
export const NavBarContainer = tw.nav`
    bg-ioet-purple
    bg-gradient-orange-to-red
    w-full 
    h-20
    top-0 
    z-10
    pr-3.5
    fixed
`;

export const NavBarInnerContainer = tw.div`
    mx-2
    flex
    h-20
    space-x-4
    items-center
    justify-between
`;

export const NavBarLogoContainer = tw.div`
    hidden    
    md:flex
    min-w-fit
    items-center
`;

export const TabButtonNavBarContainer = tw.div`
    hidden
    lg:flex
    h-full
    w-11/12
`;

export const UserInformationPopoverContainer = tw.div`
    flex
    justify-end
`;

export const LogoAndButtonsContainer = tw.div`
    h-full
    flex
`;
