import { AlertColor } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "src/v2/components/molecules/Alert/CustomAlert";
import useCopyToClipboard from "src/v2/hooks/useCopyToClipboard";
import { Tooltip } from "../../atoms/Tooltip";

function CopyToClipboard({
  children,
  text,
}: {
  children: React.ReactElement;
  text: string;
}) {
  const { isCopied, error, copyToClipboard, handleClose } =
    useCopyToClipboard();
  const [alertInformation, setAlertInformation] = useState({
    severity: "success",
    message: `${text} copied to clipboard`,
  });
  useEffect(() => {
    if (error) {
      setAlertInformation({
        severity: "error",
        message: error,
      });
    }
  }, [error]);
  return (
    <>
      <button
        type='button'
        onClick={() => copyToClipboard(text)}
        style={{ display: "flex" }}
      >
        <Tooltip title='Copy to clipboard' arrow>
          {children}
        </Tooltip>
      </button>
      <CustomAlert
        sx={{ marginLeft: "50px" }}
        text={alertInformation.message}
        severity={alertInformation.severity as AlertColor}
        open={isCopied || !!error}
        onClose={handleClose}
      />
    </>
  );
}
export default CopyToClipboard;
