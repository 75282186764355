import tw from "twin.macro";

export const ModalContainer = tw.div`
absolute
z-50
h-full
w-full
lg:max-h-[30vh]
lg:max-w-[50vh]
md:max-h-[30vh]
md:max-w-[50vh]
max-w-[40vh]
max-h-[50vh]
top-1/2 
left-1/2 
transform 
-translate-x-1/2 
-translate-y-1/2 
bg-gray-900
text-white
rounded-sm
shadow-md
flex
flex-col
justify-center
items-center
space-y-5
`;

export const Title = tw.h1`
pl-10
mb-10
pr-10
`;
export const Background = tw.div`
fixed 
top-0 
left-0 
w-full 
h-full 
bg-black 
bg-opacity-50 
flex 
items-center 
justify-center 
z-40
`;
