import { sanitizeArray } from "src/v2/utils/parsers";

export const joinNameLastName = (name: string, lastName: string) => {
  return `${name} ${lastName}`;
};

export const objectArrayToString = (data: { name: string }[]) => {
  return sanitizeArray(data).length === 0
    ? ""
    : data.map((item) => item.name).join(" ");
};

export const uperCaseConverter = (str: string) => {
  return str
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
};
