import {
  PhoneIcon,
  UserGroupIcon,
  EnvelopeIcon,
  DocumentTextIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { LateralBarFormsProps } from "./LateralBarFormsProps";
import {
  InformationBlock,
  LateralBarContainer,
  TitleAndContentBlock,
  LateralBarTitles,
  ManagerImage,
} from "./LateralBarFormsStyleComponents";

export function LateralBarForms({
  contractType,
  departament,
  email,
  location,
  phone,
  startDate,
  supervisorName,
  timeOnTheJob,
}: LateralBarFormsProps) {
  return (
    <LateralBarContainer data-testid='employee-main-info'>
      <InformationBlock>
        <PhoneIcon height={25} color='#FC5630' /> <h2>{phone}</h2>
      </InformationBlock>
      <InformationBlock>
        <EnvelopeIcon height={25} color='#FC5630' /> <h2>{email}</h2>
      </InformationBlock>
      <InformationBlock>
        <UserGroupIcon height={25} color='#FC5630' /> <h2>{departament}</h2>
      </InformationBlock>
      <InformationBlock>
        <MapPinIcon height={25} color='#FC5630' /> <h2>{location}</h2>
      </InformationBlock>
      <InformationBlock>
        <DocumentTextIcon height={25} color='#FC5630' /> <h2>{contractType}</h2>
      </InformationBlock>

      <TitleAndContentBlock>
        <InformationBlock>
          <LateralBarTitles>Hire Date</LateralBarTitles>
        </InformationBlock>
        <InformationBlock>
          <h2>{startDate?.toString()}</h2>
        </InformationBlock>
        <InformationBlock>
          <h2>{timeOnTheJob}</h2>
        </InformationBlock>
      </TitleAndContentBlock>

      <TitleAndContentBlock>
        <LateralBarTitles>Reports to</LateralBarTitles>
        <InformationBlock>
          <ManagerImage src='' alt='' />
          <h2>{supervisorName}</h2>
        </InformationBlock>
      </TitleAndContentBlock>
    </LateralBarContainer>
  );
}
