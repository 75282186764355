import { createContext, useMemo, useState } from "react";
import User from "../types/userType";
import { IAuthContext } from "./IContexts";

const AuthContext = createContext<IAuthContext | undefined>({
  setUser: () => {},
  user: undefined,
});

export function AuthProvider({ children }: { children: JSX.Element }) {
  const [user, setUser] = useState<User | undefined>(undefined);
  const contextValue = useMemo(() => ({ setUser, user }), [user]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;
