import tw from "twin.macro";

export const InputContainer = tw.div`
mt-2
mb-2
flex flex-col md:flex-row
text-start
`;
export const LabelForm = tw.label`
lg:w-40
md:w-1/6
text-gray-800
flex
items-center
`;
export const ErrorMessage = tw.p`
text-red-500
text-lg
`;
