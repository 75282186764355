function stringToColor(string: string) {
  // This function is used to generate a color based on a string for the avatar
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  // This function is used to generate the initial letters of the full name for the avatar
  return {
    color: stringToColor(name),
    text: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
export default stringAvatar;
