export const getTimeOnJob = (dateString: string, currentDate: Date): string => {
  const inputDate = new Date(`${dateString}T00:00:00`);

  const yearsDiff = currentDate.getFullYear() - inputDate.getFullYear();
  const monthsDiff = currentDate.getMonth() - inputDate.getMonth();
  const daysDiff = currentDate.getDate() - inputDate.getDate();

  let years = yearsDiff;
  let months = monthsDiff;
  let days = daysDiff;

  if (days < 0) {
    months--;
    days += new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    ).getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  return `${years}y - ${months}m - ${days}d`;
};
