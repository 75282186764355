import tw from "twin.macro";

export const MainContainer = tw.div`
text-2xl
mt-5
ml-2
divide-y
divide-gray-300
`;
export const TitleContainer = tw.div`
text-ioet-orange
text-4xl
font-medium
tracking-wider
mb-10
`;
