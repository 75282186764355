import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "src/v2/components/atoms/Typography";
import { ILoadingButtonProps } from "./types";

function LoadingButtons({
  loading,
  loadingPosition,
  variant,
  text,
  color,
  sx,
  onClick,
  startIcon,
  disabled = false,
}: ILoadingButtonProps) {
  return (
    <LoadingButton
      loading={loading}
      loadingPosition={loadingPosition}
      startIcon={startIcon}
      variant={variant}
      onClick={onClick}
      sx={{ textTransform: "capitalize", ...sx }}
      color={color}
      disabled={disabled ?? false}
    >
      <Typography bold>{text}</Typography>
    </LoadingButton>
  );
}
export default LoadingButtons;
