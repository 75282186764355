import { IOptions } from "src/pages/FormEmployee/IEmployee";
import {
  STAFFING_STATUS_CHOICES,
  WORKING_TITLES_CHOICES,
} from "src/components/forms/PersonalInfoForm/utils/InputOptions";
import { Dict, IWorkInformation, IWorkInformationQuery } from "../models";
import { capitalizeOnlyFirstLetterOfEveryWord } from "../utils/stringUtils";

const buildIOptions = (value: string, options: IOptions[]): IOptions => ({
  value,
  label:
    options.find((option: IOptions) => option.value === value)?.label ?? "",
});

export const getWorkInformation = (personData: Dict): IWorkInformation => {
  const workInformation: IWorkInformation = {
    jobTitle: personData.jobTitle ?? "Not Found",
    employmentStatus:
      capitalizeOnlyFirstLetterOfEveryWord(
        personData.employmentStatus?.at(0)?.statusCategory
      ) ?? "",
    contractType:
      personData.contractType?.toLowerCase().replaceAll("_", " ") ?? "",
    office: personData.office ?? "",
    staffingStatus:
      personData.status?.name?.map((status: string) =>
        buildIOptions(
          capitalizeOnlyFirstLetterOfEveryWord(status),
          STAFFING_STATUS_CHOICES
        )
      ) ?? [],
    workingTitles:
      personData.workTitles?.map((title: Dict) =>
        buildIOptions(title.name.toLowerCase(), WORKING_TITLES_CHOICES)
      ) ?? [],
  };
  return workInformation;
};

export const buildWorkInformationQuery = (
  workInformation: IWorkInformation,
  personId: string
): IWorkInformationQuery => {
  const workInformationQuery: IWorkInformationQuery = {
    person_id: personId!,
    custom_fields: {
      employmentStatus: [
        {
          id: "1",
          statusDate: "2024-01-15",
          statusCategory: workInformation.employmentStatus
            .toUpperCase()
            .replaceAll(" ", "_"),
          comment: "No Comment",
          originalHireDate: "2024-01-14",
          payGroup: "null",
        },
      ],
      contractType: {
        name: workInformation.contractType.toUpperCase().replaceAll(" ", "_"),
      },
      office: { name: workInformation.office },
      status: {
        name: workInformation.staffingStatus.map((status: IOptions) =>
          status.value.toLowerCase()
        ),
      },
      workTitles: workInformation.workingTitles.map((title: IOptions) => ({
        name: title.value === "qa engineer" ? "QA engineer" : title.value,
      })),
    },
  };
  return workInformationQuery;
};
