import tw from "twin.macro";

export const modalStyle = tw`
    flex
    [flex-direction: column]
    gap-2
    justify-center
    align-middle
    absolute
    top-1/2
    left-1/2
    -translate-x-1/2
    -translate-y-1/2
    w-96
    rounded
    p-4
  `;
