export function capitalizeOnlyFirstLetterOfEveryWord(
  str: string | null
): string {
  if (str === null || str === undefined) {
    return "";
  }
  str = str.replaceAll("_", " ");
  const words: string[] = str.split(" ");
  const capitalizedWords: string[] = words.map((word: string) => {
    word = word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(" ");
}

export const transformColumnLabel = (column_label: string) => {
  const regex = /[A-Za-z][a-z]*|[A-Z][a-z]*/g;
  const words = column_label.match(regex);

  if (!words) {
    return column_label;
  }

  const titleCaseWords = words.map((word) => {
    if (word.toLowerCase().includes("ioet")) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return titleCaseWords.join(" ");
};
