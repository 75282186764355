import React from "react";
import { createPortal } from "react-dom";
import { Button } from "../Button/Button";
import { BottomSheetContainer } from "./BottomSheetStyles";

interface IBottomSheetProps {
  handleOnSave: () => void;
  handleOnCancel: () => void;
}

export const BottomSheet: React.FC<IBottomSheetProps> = ({
  handleOnCancel,
  handleOnSave,
}) => {
  function BottomSheetBody() {
    return (
      <BottomSheetContainer data-testid='bottom-sheet-section'>
        <Button text='Save' callback={handleOnSave} />
        <Button text='Cancel' callback={handleOnCancel} />
      </BottomSheetContainer>
    );
  }

  return createPortal(BottomSheetBody(), document.body);
};
