/* eslint-disable react/function-component-definition */
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";

type TabsContextType = {
  notes: {
    NotesUpdate: boolean;
    setNotesUpdate: Dispatch<SetStateAction<boolean>>;
  };
  reviews: {
    ReviewsUpdate: boolean;
    setReviewsUpdate: Dispatch<SetStateAction<boolean>>;
  };
};

interface ITabsUpdateProviderProps {
  children: React.ReactNode;
}

export const TabsUpdateContext = createContext<TabsContextType | undefined>(
  undefined
);

export const TabsUpdateProvider = ({ children }: ITabsUpdateProviderProps) => {
  const [NotesUpdate, setNotesUpdate] = useState(false);
  const [ReviewsUpdate, setReviewsUpdate] = useState(false);
  const contextValue: TabsContextType = useMemo(() => {
    return {
      notes: { NotesUpdate, setNotesUpdate },
      reviews: { ReviewsUpdate, setReviewsUpdate },
    };
  }, [NotesUpdate, setNotesUpdate, ReviewsUpdate, setReviewsUpdate]);

  return (
    <TabsUpdateContext.Provider value={contextValue}>
      {children}
    </TabsUpdateContext.Provider>
  );
};
