import AddIcon from "@mui/icons-material/Add";
import { AlertColor, Box, Skeleton, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReviewStatus } from "src/constants/reviewsConstants";
import { CustomAlert, ReviewForm } from "src/v2/components";
import {
  Button,
  DatePicker,
  EmptyRows,
  FeatureFlag,
  Typography,
} from "src/v2/components/atoms";
import DatePickerCustomButtons from "src/v2/components/molecules/DatePickerCustomButtons/DatePickerCustomButtons";
import { ReviewItem, ReviewList } from "src/v2/components/organisms";
import DialogModal from "src/v2/components/organisms/Dialog/Dialog";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import {
  useFetchNextReviewDateByPersonId,
  useListAllReviewsByPersonId,
} from "src/v2/hooks/useFetchs";
import { useMutateNextReviewDate } from "src/v2/hooks/useMutations";
import { CurrentValues } from "src/v2/models/review.model";

export function ReviewRecord({
  lastEffectiveDate,
  currentValues,
}: {
  lastEffectiveDate: string;
  currentValues: CurrentValues;
}) {
  const { personId } = useParams();
  const { data: nextReviewDate, isLoading: isLoadingNextReviewDate } =
    useFetchNextReviewDateByPersonId(personId!);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dateValue, setDateValue] = useState<Dayjs | null>(
    nextReviewDate ? dayjs(nextReviewDate) : null
  );
  const [modalPastReview, setModalPastReview] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [savedData, setSavedData] = useState(false);
  const [alertInformation, setAlertInformation] = useState({
    severity: "success",
    message: "Review saved correctly",
  });
  const { mutate: updateNextReviewDate } = useMutateNextReviewDate();
  const mutation = async (data: { personId: string; nextReviewDate: Date }) => {
    const previousDateValue = dateValue;
    setDateValue(dayjs(data.nextReviewDate));
    updateNextReviewDate(data, {
      onSuccess: () => {
        setAlertInformation({
          message: "Next Review date was successfully updated.",
          severity: "success",
        });
        setSavedData(true);
      },
      onError: () => {
        setAlertInformation({
          message: "Failure to update Next review date.",
          severity: "error",
        });
        setDateValue(previousDateValue);
        setSavedData(true);
      },
    });
  };

  const {
    data: reviews,
    error,
    isLoading: isLoadingReviews,
  } = useListAllReviewsByPersonId(personId!);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSavedData(false);
  };
  useEffect(() => {
    setDateValue(nextReviewDate ? dayjs(nextReviewDate) : null);
  }, [nextReviewDate]);

  const handleNextReviewDateUpdate = () => {
    const dateUpdate = new Date(dayjs(dateValue).format("YYYY-MM-DD"));
    if (personId) {
      mutation({ personId, nextReviewDate: dateUpdate });
    }
  };
  const startYear = dayjs(lastEffectiveDate);
  const endYear = dayjs(lastEffectiveDate).add(5, "year");

  return (
    <Box data-testid='reviews-record'>
      <DialogModal
        open={modalPastReview}
        title='Past Review'
        buttonTitle=''
        handleExit={() => setModalPastReview(false)}
        form={
          <ReviewForm
            isPastReview
            setAlertInformation={setAlertInformation}
            setSavedData={setSavedData}
            handleAccordionClose={() => setModalPastReview(!modalPastReview)}
          />
        }
      />

      <CustomAlert
        sx={{ marginLeft: "50px" }}
        text={alertInformation.message}
        severity={alertInformation.severity as AlertColor}
        open={savedData}
        onClose={handleClose}
      />
      <div>
        <Typography
          variant='h6'
          sx={{ color: PRIMARY_COLOR, fontWeight: "bold" }}
        >
          Reviews Record
        </Typography>
        <Stack
          direction='row'
          justifyContent='space-between'
          display='flex'
          alignItems='center'
          marginY={7}
        >
          <Stack display='flex' alignItems='center' direction='row' spacing={3}>
            <Typography sx={{ fontWeight: 900 }}>Next review date</Typography>
            {!isLoadingNextReviewDate ? (
              <DatePicker
                label=''
                onOpen={() => setOpenDatePicker(true)}
                open={openDatePicker}
                disabled={
                  reviews?.[0]?.status &&
                  reviews[0].status !== ReviewStatus.done
                }
                minDate={startYear}
                maxDate={endYear}
                closeOnSelect={false}
                value={dateValue}
                onChange={(date) =>
                  setDateValue(dayjs(date?.format("YYYY-MM-DD")))
                }
                sx={[{ maxWidth: "250px", background: "none" }]}
                views={["month", "year"]}
                slots={{ actionBar: DatePickerCustomButtons }}
                slotProps={{
                  actionBar: {
                    actions: ["cancel", "accept"],
                    setOpenDatePicker,
                    handleNextReviewDateUpdate,
                    customAcceptLabel: "Confirm new review date",
                  },
                }}
              />
            ) : (
              <Skeleton width={250} height={80} />
            )}
          </Stack>
          <Stack spacing={2} direction='row-reverse'>
            <Button
              onClick={() => setShowReview(true)}
              text='Review'
              variant='addButton'
              icon={<AddIcon />}
              iconPosition='left'
            />
            <FeatureFlag featureFlagName='past_reviews'>
              <Button
                onClick={() => setModalPastReview(true)}
                text='Past Review'
                variant='addButton'
                icon={<AddIcon />}
                iconPosition='left'
              />
            </FeatureFlag>
          </Stack>
        </Stack>
        {isLoadingReviews && (
          <Stack spacing={0}>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        )}
        {(error && !showReview) ||
          (reviews?.length === 0 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <EmptyRows />
            </div>
          ))}
        {showReview && (
          <ReviewItem
            handlerCloseNewReview={() => setShowReview(false)}
            setAlertInformation={setAlertInformation}
            setSavedData={setSavedData}
            currentValues={currentValues}
            nextReviewDate={dateValue}
          />
        )}
      </div>

      {!isLoadingReviews && (
        <ReviewList
          setAlertInformation={setAlertInformation}
          setSavedData={setSavedData}
          reviews={reviews ?? []}
        />
      )}
    </Box>
  );
}
