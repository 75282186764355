import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  capitalizeFirstLetter,
  convertToLowerCase,
} from "src/v2/utils/parsers";
import { Link } from "react-router-dom";
import { PEOPLE_BFF_PERSON } from "src/constants/urlConstants";
import { Tooltip } from "src/v2/components";
import { transformColumnLabel } from "src/v2/utils/stringUtils";
import useDynamicViewTagContext from "./useDynamicViewModelContext"; /* change this import with the correct hook columns */

import { ViewTag } from "../models/dynamicViewTag.model";
import { ChipEditViewTagComponent } from "../components/editViewTagsComponents/simpleEditViewTags";

const useColumns = () => {
  const context = useDynamicViewTagContext();
  if (context.DataDefinitionSchema == null) {
    return null;
  }

  return Object.keys(context.DataDefinitionSchema.fields).map((columnField) => {
    const {
      label,
      view_tag: viewTag,
      valueOptions,
      multiSelectOptions,
    } = context.DataDefinitionSchema!.fields[columnField];

    const SelectedComponent = context.renderDynamicModelField(columnField);

    const columnLabel = transformColumnLabel(label);

    const additionalProperties = {
      ...(viewTag === ViewTag.chip
        ? {
          renderEditCell: (params: GridRenderCellParams) => {
            return ChipEditViewTagComponent({
              params,
              multiSelectOptions: multiSelectOptions!,
            });
          },
        }
        : {}),
      ...(viewTag === ViewTag.date ? { type: "date" } : {}),
      ...(viewTag === ViewTag.select
        ? { type: "singleSelect", valueOptions }
        : {}),
    };

    const column: GridColDef = {
      field: columnField,
      headerName: columnLabel,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      editable: true,
      renderCell: (params) => {
        const fieldsToCapitalize = ["GENDER", "OFFICE"];
        const fieldsToLowerCase = ["CONTRACT TYPE"];

        const conditions = [
          {
            check: () => fieldsToCapitalize.includes(columnLabel),
            transform: capitalizeFirstLetter,
          },
          {
            check: () => fieldsToLowerCase.includes(columnLabel),
            transform: convertToLowerCase,
          },
        ];
        const conditionMatch = conditions.find((condition) =>
          condition.check()
        );

        const rowsValues = conditionMatch
          ? conditionMatch.transform(params?.value)
          : params?.value;
        const ioetId = params.row.ioetId;
        return (
          <div
            style={{
              whiteSpace: "normal",
              wordBreak: "break-all",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {columnLabel === "Name" || columnLabel === "Last Name" ? (
              <Tooltip title='Open person profile' arrow>
                <Link
                  to={PEOPLE_BFF_PERSON + ioetId}
                  target='_self'
                  rel='noopener noreferrer'
                >
                  {SelectedComponent && SelectedComponent(rowsValues)}
                </Link>
              </Tooltip>
            ) : (
              SelectedComponent && SelectedComponent(rowsValues)
            )}
          </div>
        );
      },
      ...additionalProperties,
    };

    return column;
  });
};

export default useColumns;
