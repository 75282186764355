import { Dict } from "src/v2/models";
import { IWorkInformation } from "src/v2/models/workInformation.model";
import { getWorkInformation } from "src/v2/adapters/workInformation.adapter";
import { WorkInformationSection } from "./WorkInformationSection/WorkInformationSection";

export function JobTab(personInformation: Dict, key: string) {
  const { personInformation: personData } = personInformation;
  const workInformation: IWorkInformation = getWorkInformation(personData);

  return (
    <div
      style={{ paddingLeft: "5%" }}
      key={key}
      data-testid='Job-tab-container'
    >
      <WorkInformationSection workInformation={workInformation} />
    </div>
  );
}
