import { Stack } from "@mui/material";
import { Typography } from "src/v2/components/atoms";
import { organizeReviewsByYears } from "src/v2/utils/organizeReviewsByYears";
import { ReviewItem } from "../ReviewItem";
import { IReviewListProps } from "./types";

function ReviewList({
  reviews,
  setAlertInformation,
  setSavedData,
}: IReviewListProps) {
  const organizedReviews = organizeReviewsByYears(reviews);
  return (
    <Stack data-testid='review-list' spacing={4}>
      {Object.keys(organizedReviews)
        .sort()
        .reverse()
        .map((year) => (
          <Stack key={year} spacing={2}>
            <Typography variant='h5' sx={{ fontWeight: "bold" }}>
              {year}
            </Typography>
            <Stack spacing={4}>
              {organizedReviews[year].map((review, index) => (
                <div key={index}>
                  <ReviewItem
                    review={review}
                    setAlertInformation={setAlertInformation}
                    setSavedData={setSavedData}
                  />
                </div>
              ))}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
}

export default ReviewList;
