import { UserNotification } from "src/v2/models";
import {
  PEOPLE_BFF_NOTE,
  PEOPLE_BFF_PERSON,
  USER_NOTIFICATIONS,
} from "../config/constants";
import httpClient from "../config/httpClient";
import { DynamicModel } from "../models/dynamicModel.model";
import { NoteRequest } from "../models/note.model";

// Obs: The backend handles the data filtering based on role, but the frontend always need to tell it in what view the user is
export async function fetchAllPeopleData(): Promise<DynamicModel[]> {
  const response = await httpClient.peopleBff.get(PEOPLE_BFF_PERSON);
  const people: DynamicModel[] = response.data.response.map((item: Object) => {
    const person: DynamicModel = { values: item };
    return person;
  });
  return people;
}

export async function updateOnePersonData(newData: any) {
  const response = await httpClient.peopleBff.put(PEOPLE_BFF_PERSON, newData);
  return response;
}

export async function getPersonInformation(ioetId: string) {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON}${ioetId}`
  );
  return response.data.response;
}

export async function fetchAllNotifications(): Promise<UserNotification[]> {
  const response = await httpClient.peopleBff.get(`${USER_NOTIFICATIONS}`);
  const notifications: UserNotification[] = response.data.notifications;
  return notifications;
}

export async function updateNote(newData: NoteRequest) {
  const response = await httpClient.peopleBff.put(PEOPLE_BFF_NOTE, newData);
  return response;
}

export async function createNote(newData: NoteRequest) {
  const response = await httpClient.peopleBff.post(PEOPLE_BFF_NOTE, newData);
  return response;
}

export async function getNotesByPersonId(personId: string) {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON}note/${personId}`
  );
  return response.data;
}

export async function logOut() {
  const response = await httpClient.peopleBff.get("/logout");
  sessionStorage.clear();
  return response;
}
