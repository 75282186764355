import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, MenuItem, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import PopoverComponent from "src/v2/components/molecules/Popover/Popover";
import { Button } from "src/v2/components/atoms";
import { IconButton } from "src/v2/components/atoms/IconButton";
import {
  REVIEW_DASHBOARD_BACKGROUND_COLOR,
  REVIEW_DASHBOARD_COLOR,
} from "src/v2/constants/colors";
import { useFilter } from "src/v2/hooks/useFilter/useFilter";
import PopoverFilter from "../../molecules/PopoverFilter/PopoverFilter";
import { IFilterProps } from "./types";

function Filter<T>({
  columns,
  currentRows,
  setFilterRow,
  hiddenColumns,
}: IFilterProps<T>) {
  const {
    open,
    filterAttributes,
    operator,
    columnsToFilter,
    anchorEl,
    handleColumnSelection,
    handleClose,
    handleOpenData,
    handleOperatorChange,
    handleDeleteFilter,
    handleInput,
    handleResetFilters,
    handlePopover,
    filterHeaders,
  } = useFilter(columns, currentRows, setFilterRow);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <PopoverComponent
        isOpen={open}
        mainComponent={
          <Button
            color='darkGrey'
            sx={{ fontSize: 13, padding: "0.25rem 0.375rem" }}
            iconPosition='left'
            icon={<FilterListIcon />}
            text='FILTERS'
            variant='text'
            bold={false}
          />
        }
        positions={["bottom"]}
        onClick={handlePopover}
      >
        <Paper
          sx={{
            color: REVIEW_DASHBOARD_COLOR,
            minWidth: "10rem",
            maxHeight: "35rem",
            position: "absolute",
            zIndex: 1,
            padding: 1,
            border: "1px solid",
            overflowY: "auto",
          }}
        >
          {columnsToFilter.length > 0 ? (
            <Stack direction='column' spacing={1}>
              {columnsToFilter.map((column, idx) => {
                if (hiddenColumns.includes(column.field)) return null;
                return (
                  <Box
                    sx={{ border: "1px solid", borderRadius: 1.5 }}
                    width='100%'
                    key={`${idx}-${column.field}`}
                  >
                    <MenuItem
                      component='div'
                      sx={{
                        cursor: "pointer",
                        display: "fit-content",
                        width: "100%",
                      }}
                      onClick={() =>
                        handleColumnSelection(column.headerName ?? "")
                      }
                    >
                      <Typography>{column.headerName}</Typography>
                    </MenuItem>
                  </Box>
                );
              })}
            </Stack>
          ) : (
            <p>No items left to filter.</p>
          )}
        </Paper>
      </PopoverComponent>
      {filterHeaders.length > 0 && (
        <Grid
          sx={{ zIndex: 2 }}
          border='1px solid'
          borderRadius={1.5}
          columns={12}
          borderColor={REVIEW_DASHBOARD_COLOR}
          container
          gap={2}
          padding={0.5}
          alignItems='flex-end'
          maxWidth='75rem'
        >
          {filterHeaders.map((filter) => (
            <Grid
              key={`${filter}-popover`}
              sx={{ margin: 0, padding: 0 }}
              xs={3}
              width='fit-content'
            >
              <Stack
                sx={{
                  border: "1px solid",
                  borderRadius: 1.5,
                  backgroundColor: REVIEW_DASHBOARD_BACKGROUND_COLOR,
                  borderColor: REVIEW_DASHBOARD_COLOR,
                }}
                width='fit-content'
                direction='row'
              >
                <Button
                  onClick={(event) => handleOpenData(filter, event)}
                  text={filter}
                  disableElevation
                  variant='text'
                  sx={{
                    padding: "0 0.375rem",
                    minWidth: "fit-content",
                    backgroundColor: REVIEW_DASHBOARD_BACKGROUND_COLOR,
                    color: REVIEW_DASHBOARD_COLOR,
                  }}
                />
                <IconButton
                  onClick={() => handleDeleteFilter(filter)}
                  style={{
                    margin: 0,
                    boxShadow: "none",
                    width: "2rem",
                    backgroundColor: "transparent",
                    borderRadius: 0,
                    borderTopRightRadius: 1.5,
                    borderBottomRightRadius: 1.5,
                    maxHeight: "1.5rem",
                    minHeight: "1.5rem",
                  }}
                  tooltipPlacement='top'
                  title='Remove Filter'
                  icon={<CloseIcon fontSize='small' />}
                />
              </Stack>

              <PopoverFilter
                filter={filter}
                filterAttributes={filterAttributes}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleOperator={handleOperatorChange}
                handleInput={handleInput}
                operator={operator}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {filterHeaders.length > 0 && (
        <IconButton
          onClick={handleResetFilters}
          icon={<DeleteForeverOutlinedIcon />}
          style={{
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
          tooltipPlacement='top'
          title='Clear all filters'
        />
      )}
    </Box>
  );
}
export default Filter;
