export const modalStyle = {
  display: "flex",
  columnGap: "3%",
  marginBottom: "3%",
  maxWidth: "100%",
};

export const employmentStatusOptions = [
  { label: "Employee", value: "Employee" },
  { label: "Former Employee", value: "Former Employee" },
  { label: "Former Candidate", value: "Former Candidate" },
  { label: "Candidate", value: "Candidate" },
];
export const staffingStatusOptions = [
  { label: "Assigned", value: "Assigned" },
  { label: "Proposed", value: "Proposed" },
  { label: "Unassigned", value: "Unassigned" },
  { label: "Training", value: "Training" },
  { label: "Internal role", value: "Internal role" },
  { label: "Reserved", value: "Reserved" },
  { label: "Visible +1", value: "Visible +1" },
  { label: "Freeing up soon", value: "Freeing up soon" },
  { label: "Shadow available", value: "Shadow available" },
  { label: "Shadow unavailable", value: "Shadow unavailable" },
  { label: "Partially assigned", value: "Partially assigned" },
  { label: "Part time +1", value: "Part time +1" },
];
export const ioetLevelOptions = [
  { label: "Mid level 1", value: "Mid level 1" },
  { label: "Junior 1", value: "Junior 1" },
  { label: "Senior", value: "Senior" },
  { label: "Mid level 2", value: "Mid level 2" },
  { label: "Junior 2", value: "Junior 2" },
  { label: "Administrative 1", value: "Administrative 1" },
  { label: "Tech lead", value: "Tech lead" },
  { label: "Intern", value: "Intern" },
];
export const ioetRoleOptions = [
  { label: "Software developer", value: "Software developer" },
  { label: "Administrative", value: "Administrative" },
  { label: "Devops engineer", value: "Devops engineer" },
  { label: "QA engineer", value: "QA engineer" },
  { label: "Business analyst", value: "Business analyst" },
  { label: "Project manager", value: "Project manager" },
  { label: "Marketing", value: "Marketing" },
  { label: "Finance", value: "Finance" },
  { label: "Human talent", value: "Human talent" },
  { label: "Emerging talent", value: "Emerging talent" },
];
export const contractTypeOptions = [
  { label: "Indefinite", value: "Indefinite" },
  { label: "Definite", value: "Definite" },
  { label: "Internship", value: "Internship" },
  { label: "Temporary", value: "Temporary" },
];
export const internalAppOptions = [];
export const internalRoleOptions = [];
